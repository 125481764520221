import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { PatientDiagnosisDetails } from 'src/modules/patients/overview/PatientDiagnosis';
import { MedicalQnACategory } from 'src/types/qna';

enum Category {
  Allergies = 'Allergies',
  CurrentMedications = 'Current Medications',
  SurgicalHistory = 'Surgical History'
}

type PatientAnswersMap = {
  [key in Category]: string[];
};

const getPatientAnswersMap = (
  patientAnswers: MedicalQnACategory[]
): PatientAnswersMap => {
  const patientAnswersMap: Partial<PatientAnswersMap> = {};

  patientAnswers.forEach((answer) => {
    const { category, questions } = answer;

    if (Object.values(Category).includes(category as Category)) {
      const answers = questions.flatMap((question) => question.answer);

      if (patientAnswersMap[category as Category]) {
        patientAnswersMap[category as Category] = [
          ...patientAnswersMap[category as Category],
          ...answers
        ];
      } else {
        patientAnswersMap[category as Category] = answers;
      }
    }
  });

  return patientAnswersMap as PatientAnswersMap;
};

export const HistoryTab: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientOverview } = usePatientsApi();

  const {
    data: patientOverview,
    isLoading: isLoadingPatientOverview,
    isFetching: isFetchingPatientOverview
  } = getPatientOverview(patientId);

  const { patientAnswers } = patientOverview || {};

  const patientAnswersMap = getPatientAnswersMap(patientAnswers);

  const categories: { title: string; category: string }[] = [
    {
      title: t('ALLERGIES').toLocaleUpperCase(),
      category: Category.Allergies
    },
    {
      title: t('CURRENT_MEDICATIONS').toLocaleUpperCase(),
      category: Category.CurrentMedications
    },
    {
      title: t('SURGICAL_HISTORY').toLocaleUpperCase(),
      category: Category.SurgicalHistory
    }
  ];

  const isLoading = isLoadingPatientOverview || isFetchingPatientOverview;

  return (
    <Flex flexDirection="column" gap={spacings.xlarge}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PatientDiagnosisDetails patientId={patientId} />
          <Flex flexDirection="column" gap={spacings.xlarge}>
            {categories.map(({ title, category }, categoryIndex) => (
              <Flex
                flexDirection="column"
                gap={spacings.small}
                key={`${category}-${categoryIndex}`}
              >
                <Typography variant="h4">{title}</Typography>
                <Flex flexDirection="column" gap={spacings.xsmall}>
                  {patientAnswersMap[category].length === 0 ? (
                    <Typography>{t('NONE')}</Typography>
                  ) : (
                    patientAnswersMap[category]
                      .filter(
                        (history: string | Record<string, string>) =>
                          typeof history === 'string'
                      )
                      .map((history: string, index: number) => (
                        <Typography key={`${category}-answer-${index}`}>
                          {history}
                        </Typography>
                      ))
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};
