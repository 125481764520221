import { FC, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Helmet } from 'react-helmet-async';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/material';
import Box from 'src/components/layout/Box/Box';
import { radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import IconButton from 'src/components/display/IconButton/IconButton';
import Loader from 'src/components/display/Loader/Loader';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import Card from 'src/components/display/Card/Card';
import { getFullName } from 'src/utils/general';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { getDateFormat, getTimeFormat } from 'src/utils/dateAndTIme';
import { PatientMiniCard } from '../common/PatientMiniCard';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { PatientLabReviewTable } from './PatientLabReviewTable';
import { PatientExaminationsTable } from './PatientExaminationsTable';
import { AppointmentDetails } from '../overview/AppointmentDetails';
import { PatientFlags } from './PatientFlags';
import { AddEditQnAForm } from './AddEditQnAForm';
import {
  DEFAULT_CATEGORY_ORDER,
  FEMALE_ONLY_CATEGORIES,
  MALE_ONLY_CATEGORIES,
  MedicalAnswer,
  MedicalQnACategory,
  MedicalQuestion,
  MedicalQuestionTypes
} from 'src/types/qna';
import useAppointments from 'src/hooks/useAppointments';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { transformQuestionSerializedIdToLabel } from '../utils/conversions';
import { DeleteMedicalAnswerDialog } from '../DeleteMedicalAnswerDialog';
import useQnaApi from 'src/hooks/useQnaApi';

const StyledScheduleContainer = styled(Box)`
  background-color: ${Colors.dawnPink};
  border-radius: ${radii.xlarge};
  padding: ${spacings.large};
  height: 100%;
  align-self: stretch;
  min-width: 450px;
`;

const StyledSpecsKey = styled(Typography)`
  font: ${fonts.pill};
`;

const StyledSpecsValue = styled(StyledSpecsKey)`
  font-weight: ${fontWeights.extraBold};
`;

const StyledSpecsValueMuted = styled(StyledSpecsKey)`
  color: ${Colors.mutedGray};
`;

const compareCategories = (
  cat1: MedicalQnACategory,
  cat2: MedicalQnACategory
): number => {
  return (
    DEFAULT_CATEGORY_ORDER[cat1.categoryId] -
    DEFAULT_CATEGORY_ORDER[cat2.categoryId]
  );
};

const sortMedicalCategories = (
  categories: MedicalQnACategory[]
): MedicalQnACategory[] => {
  return categories.sort(compareCategories);
};

const filterMedicalCategories = (
  sex: string,
  categories: MedicalQnACategory[]
): MedicalQnACategory[] => {
  switch (sex) {
    case 'Male':
      return categories.filter(
        (category) => !FEMALE_ONLY_CATEGORIES.includes(category.categoryId)
      );
    case 'Female':
      return categories.filter(
        (category) => !MALE_ONLY_CATEGORIES.includes(category.categoryId)
      );
    default:
      return categories;
  }
};

const formatAnswer = ({
  answer,
  noDataLabel,
  emptyLabel,
  isNested,
  onEditRepeatedAnswer,
  onDeleteRepeatedAnswer
}: {
  answer: MedicalAnswer;
  noDataLabel?: ReactNode;
  emptyLabel?: ReactNode;
  isNested?: boolean;
  onEditRepeatedAnswer?: (answerKey: string) => void;
  onDeleteRepeatedAnswer?: (answerKey: string) => void;
}) => {
  const isAnswerObject = typeof answer === 'object' && !Array.isArray(answer);
  if (answer === undefined || answer === null) return noDataLabel;

  if (isAnswerObject) {
    const answerKeys = Object.keys(answer);

    return (
      <Flex
        gap={spacings.large}
        justifyContent="space-evenly"
        flexDirection="column"
        width="100%"
      >
        {answerKeys.map((key, idx) => {
          const value = answer[key];
          const isValueObject = typeof value === 'object';
          const isLast = idx === answerKeys.length - 1;
          const shouldAddBorder = isValueObject && !isLast;

          return (
            <Flex
              key={`${key}-${idx}`}
              width="100%"
              gap={spacings.large}
              justifyContent="space-between"
              paddingBottom={shouldAddBorder ? spacings.large : 'none'}
              borderBottom={
                shouldAddBorder ? `1px solid ${Colors.vistaWhite}` : 'none'
              }
            >
              <Flex flex={isValueObject ? 1 : 3} textAlign="left">
                <StyledSpecsKey>{key}</StyledSpecsKey>
              </Flex>
              <Flex
                flex={isValueObject ? 3 : 1}
                justifyContent="flex-end"
                textAlign="right"
                flexDirection="column"
              >
                {formatAnswer({
                  answer: value,
                  noDataLabel,
                  emptyLabel,
                  isNested: true
                })}
                {!isNested && (
                  <Flex gap={spacings.large}>
                    <MiniIconButton
                      icon={<Edit />}
                      onClick={() => onEditRepeatedAnswer?.(key)}
                    />
                    <MiniIconButton
                      icon={<Delete />}
                      onClick={() => onDeleteRepeatedAnswer?.(key)}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  if (Array.isArray(answer)) {
    return (
      <Flex flex={1}>
        <StyledSpecsValue>
          {answer.length === 0 ? emptyLabel : answer.join(', ')}
        </StyledSpecsValue>
      </Flex>
    );
  }

  // is date only if starts with YYYY-MM-DD and dayjs can parse it
  const isDate = /^\d{4}-\d{2}-\d{2}.*/.test(answer) && dayjs(answer).isValid();

  return answer ? (
    <StyledSpecsValue>{`${
      isDate ? dayjs(answer).format(getDateFormat()) : answer
    }`}</StyledSpecsValue>
  ) : (
    noDataLabel
  );
};

const MedicalHistoryCard: FC<{
  patientId: string;
  primaryPatientId?: string;
  categories: MedicalQnACategory[];
  medicalQuestions: Record<string, MedicalQuestion[]>;
  sex: string;
  hasFemalePartner: boolean;
}> = ({
  patientId,
  primaryPatientId,
  categories: categoriesProp,
  medicalQuestions,
  sex,
  hasFemalePartner
}) => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const categories: MedicalQnACategory[] = categoriesProp.map((category) => {
    const { category: categoryName, categoryId, questions } = category;
    const defaultCategoryQuestions = medicalQuestions?.[categoryName] ?? [];

    const transformedQuestions = defaultCategoryQuestions.map(
      ({ serializedId }) => {
        return {
          question: defaultCategoryQuestions.find(
            ({ serializedId: medicalQuestionSerializedId }) =>
              medicalQuestionSerializedId === serializedId
          ),
          answer: questions?.find(
            ({ question: { serializedId: currSerializedId } }) =>
              currSerializedId === serializedId
          )?.answer
        };
      }
    );

    return {
      category: categoryName,
      questions: transformedQuestions,
      categoryId
    };
  });

  const sortedAndFilteredCategories = useMemo(() => {
    return sortMedicalCategories(filterMedicalCategories(sex, categories));
  }, [categories, sex]);

  return (
    <Card
      shadow
      marginBottom={
        hasFemalePartner && sex === 'Male' ? spacings.x3large : spacings.x2large
      }
      padding={spacings.xlarge}
    >
      {categories.length === 0 ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: fontWeights.extraBold,
              marginBottom: spacings.medium
            }}
          >
            {t('NO_DATA')}
          </Typography>
        </Flex>
      ) : (
        sortedAndFilteredCategories.map((category, index) => {
          const { category: categoryName, questions = [] } = category;
          const sortedQuestions = questions.sort(
            (a, b) => a.question.categorySerial - b.question.categorySerial
          );

          return (
            <Accordion key={`${categoryName}-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${categoryName}-${index}-content`}
                id={`panel-${categoryName}-${index}-header`}
                sx={{
                  minHeight: 'unset !important',
                  height: '30px;'
                }}
              >
                <Flex width="100%">
                  <Typography sx={{ fontWeight: fontWeights.extraBold }}>
                    {categoryName}
                  </Typography>
                </Flex>
              </AccordionSummary>
              <Box
                borderBottom={`1px solid ${Colors.alto2}`}
                width="100%"
                marginY={spacings.medium}
              />
              <AccordionDetails id={`panel-${categoryName}-${index}-content`}>
                <Flex justifyContent="space-between">
                  <Flex
                    flexDirection="column"
                    flexGrow={1}
                    gap={spacings.xlarge}
                  >
                    {sortedQuestions.length === 0 ? (
                      <Typography muted>{t('NO_DATA')}</Typography>
                    ) : (
                      sortedQuestions.map(({ question, answer }, index) => {
                        const transformedId =
                          transformQuestionSerializedIdToLabel(
                            question.serializedId
                          );

                        const isAnswer =
                          answer === undefined || answer === null;

                        const isRepeatType = [
                          MedicalQuestionTypes.REPEAT_TEMPLATE,
                          MedicalQuestionTypes.REPEAT_NUMBER
                        ].includes(question.type);

                        return (
                          <Flex
                            key={`${question.serializedId}-${index}`}
                            marginBottom={spacings.small}
                            gap={spacings.large}
                            paddingBottom={spacings.large}
                            borderBottom={`1px solid ${Colors.vistaWhite}`}
                          >
                            <Flex width="33%">
                              <Typography sx={{ mr: 6 }}>
                                {transformedId}
                              </Typography>
                            </Flex>
                            <Flex width="67%">
                              {formatAnswer({
                                answer,
                                noDataLabel: (
                                  <StyledSpecsValueMuted>
                                    {t('NO_DATA')}
                                  </StyledSpecsValueMuted>
                                ),
                                emptyLabel: t('NONE'),
                                onEditRepeatedAnswer: (answerKey: string) => {
                                  openDialog({
                                    header: categoryName,
                                    children: (
                                      <AddEditQnAForm
                                        patientId={patientId}
                                        primaryPatientId={primaryPatientId}
                                        category={category}
                                        question={question}
                                        answerKey={answerKey}
                                      />
                                    ),
                                    maxWidth: 'xl'
                                  });
                                },
                                onDeleteRepeatedAnswer: (answerKey: string) => {
                                  openDialog({
                                    header: t('DELETE_MEDICAL_ANSWER'),
                                    children: (
                                      <DeleteMedicalAnswerDialog
                                        patientId={patientId}
                                        question={question}
                                        answer={answer}
                                        answerKey={answerKey}
                                      />
                                    ),
                                    maxWidth: 'xl'
                                  });
                                }
                              })}
                            </Flex>
                            <Box>
                              <MiniIconButton
                                icon={
                                  isRepeatType || isAnswer ? <Add /> : <Edit />
                                }
                                onClick={() =>
                                  openDialog({
                                    header: categoryName,
                                    children: (
                                      <AddEditQnAForm
                                        patientId={patientId}
                                        primaryPatientId={primaryPatientId}
                                        category={category}
                                        question={question}
                                      />
                                    ),
                                    maxWidth: 'xl'
                                  })
                                }
                              />
                            </Box>
                            {!isRepeatType && !isAnswer && (
                              <Box>
                                <MiniIconButton
                                  icon={<Delete />}
                                  onClick={() => {
                                    openDialog({
                                      header: t('DELETE_MEDICAL_ANSWER'),
                                      children: (
                                        <DeleteMedicalAnswerDialog
                                          patientId={patientId}
                                          question={question}
                                          answer={answer}
                                        />
                                      ),
                                      maxWidth: 'xl'
                                    });
                                  }}
                                />
                              </Box>
                            )}
                          </Flex>
                        );
                      })
                    )}
                  </Flex>
                </Flex>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </Card>
  );
};

export const PatientMedicalHistory: FC<{}> = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const { openDialog } = useDialog();
  const { getAllMedicalQuestions } = useQnaApi();

  const {
    data: medicalQuestions,
    isLoading: isLoadingQuestions,
    isFetching: _isFetchingQuestions // Explicitly unused
  } = getAllMedicalQuestions(patientId);
  const { getPatientOverview } = usePatientsApi();
  const { getPatientAppointments } = useAppointments();

  const {
    data: patientOverview,
    isLoading: isLoadingPatientOverview,
    isFetching: _isFetchingPatientOverview // Explicitly unused
  } = getPatientOverview(patientId);

  const {
    data: appointments,
    isLoading: isLoadingAppointments,
    isFetching: _isFetchingAppointments // Explicitly unused
  } = getPatientAppointments(patientId, true); // Also get deleted appointments

  const isLoading =
    isLoadingPatientOverview || isLoadingAppointments || isLoadingQuestions;

  if (isLoading) return <Loader />;

  const pastAppointments = appointments?.filter(({ endTime }) => {
    const appointmentDate = dayjs(endTime);
    return appointmentDate.isBefore(dayjs().toDate());
  });

  const { patientBasicInfo, patientAnswers, partnerAnswers, partnerInfo } =
    patientOverview || {};

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_MEDICAL_HISTORY_PAGE_TITLE')}</title>
      </Helmet>
      <Box width="100%">
        <Box marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>

        <Flex
          flexDirection={{
            xs: 'column',
            lg: 'row'
          }}
          gap={spacings.large}
          marginBottom={spacings.large}
        >
          <Flex gap={spacings.large} flex={3}>
            <Box flex={1}>
              <PatientMiniCard
                marginBottom={spacings.large}
                basicInfo={patientOverview?.patientBasicInfo}
                properties={patientOverview?.patientProperties}
                patientId={patientId}
                patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
                header={t('PATIENT_MEDICAL_HISTORY')}
              />
              <MedicalHistoryCard
                patientId={patientId}
                categories={patientAnswers ?? []}
                medicalQuestions={medicalQuestions}
                sex={patientBasicInfo.sex}
                hasFemalePartner={partnerInfo?.basicInfo.sex === 'Female'}
              />
              <PatientLabReviewTable patientId={patientId} />
              <PatientExaminationsTable patientId={patientId} />
            </Box>

            {partnerInfo?.id && (
              <Box flex={1}>
                <PatientMiniCard
                  isPartner
                  marginBottom={spacings.large}
                  basicInfo={patientOverview?.partnerInfo?.basicInfo}
                  properties={patientOverview?.partnerInfo?.properties}
                  patientDisplayId={
                    patientOverview?.partnerInfo?.basicInfo?.displayId
                  }
                  patientId={partnerInfo.id}
                  header={t('PARTNER_MEDICAL_HISTORY')}
                />
                <MedicalHistoryCard
                  patientId={partnerInfo.id}
                  primaryPatientId={patientId}
                  categories={partnerAnswers ?? []}
                  medicalQuestions={medicalQuestions}
                  sex={partnerInfo.basicInfo.sex}
                  hasFemalePartner={patientBasicInfo.sex === 'Female'}
                />
                <PatientLabReviewTable patientId={partnerInfo.id} isPartner />
                <PatientExaminationsTable
                  patientId={partnerInfo.id}
                  isPartner
                />
              </Box>
            )}
          </Flex>

          <StyledScheduleContainer flex={1}>
            <Typography variant="h1">{t('PAST_VISITS')}</Typography>
            <Flex
              flexDirection="column"
              gap={spacings.medium}
              marginTop={spacings.large}
            >
              {pastAppointments?.length === 0 ? (
                <Typography variant="body1" color={Colors.emperor}>
                  {t('NO_PAST_VISITS')}
                </Typography>
              ) : (
                pastAppointments?.map(
                  (
                    { date, appointmentType, id, status, cancellationReason },
                    index
                  ) => {
                    return (
                      <Card
                        key={index}
                        shadow
                        borderRadius={radii.large}
                        padding={spacings.xlarge}
                        onClick={() =>
                          openDialog({
                            header: ' ',
                            fullWidth: true,
                            maxWidth: 'sm',
                            removeDefaultPadding: true,
                            hideCloseButton: true,
                            children: <AppointmentDetails appointmentId={id} />
                          })
                        }
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Flex gap={spacings.small} flexDirection="column">
                            <StyledSpecsValue
                              variant="h4"
                              color={Colors.emperor}
                            >
                              {dayjs(date).format(
                                `dddd, ${getDateFormat()} ${getTimeFormat({
                                  isShort: true
                                })}`
                              )}
                            </StyledSpecsValue>
                            <StyledSpecsValue
                              variant="h4"
                              color={Colors.emperor}
                            >
                              {getFullName(patientBasicInfo)}
                            </StyledSpecsValue>
                            <StyledSpecsKey variant="h4" color={Colors.emperor}>
                              {t(appointmentType)}
                            </StyledSpecsKey>
                            <StyledSpecsKey variant="h4" color={Colors.emperor}>
                              {t('STATUS')}: {t(status)}
                              {cancellationReason && `, ${cancellationReason}`}
                            </StyledSpecsKey>
                          </Flex>
                          <IconButton
                            icon={
                              <ArrowForwardIcon
                                sx={{ color: Colors.emperor }}
                              />
                            }
                            iconSize="large"
                            bgColor="cupid"
                          />
                        </Flex>
                      </Card>
                    );
                  }
                )
              )}
            </Flex>
          </StyledScheduleContainer>
        </Flex>
      </Box>
    </>
  );
};
