import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { LabResult } from 'src/types/appointment';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import dayjs from 'dayjs';
import { getDateFormat } from 'src/utils/dateAndTIme';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import EditPatientLabResult from './EditPatientLabResults';
import useLabResults from 'src/hooks/useLabResults';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    padding-inline: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiPaginationItem-root {
    margin-right: 20px;
  }
`;

export const PatientLabReviewTable: FC<{
  patientId: string;
  isPartner?: boolean;
}> = ({ patientId, isPartner }) => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { getPatientLabResults } = useLabResults();

  const {
    data: labResults,
    isLoading: isLabResultsLoading,
    isFetching: isLabResultsFetching,
    refetch: handleRefetchLabResults
  } = getPatientLabResults(patientId);

  const isLoading = isLabResultsLoading || isLabResultsFetching;

  const getLabResultById = (labResultGroupdId: string) => {
    return labResults?.filter((result) => result.groupId === labResultGroupdId);
  };

  const columns: GridColDef<LabResult>[] = [
    {
      field: 'edit',
      headerName: t('EDIT'),
      renderHeader: () => null,
      flex: 1,
      align: 'center',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: (params) => (
        <MiniIconButton
          icon={<EditIcon style={{ fill: 'black' }} />}
          onClick={() =>
            openDialog({
              header: t('EDIT_LAB_RESULT'),
              fullWidth: true,
              maxWidth: 'lg',
              children: (
                <EditPatientLabResult
                  labResults={getLabResultById(params.row.groupId) || []}
                  patientId={patientId}
                  onDelete={handleRefetchLabResults}
                />
              )
            })
          }
        />
      )
    },
    {
      field: 'date',
      flex: 3,
      headerName: t('DATE'),
      type: 'date' as const,
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ row: { date } }) => (
        <Typography>
          {dayjs(date).format(getDateFormat({ isShort: true }))}
        </Typography>
      )
    },
    {
      field: 'test',
      flex: 7,
      headerName: t('TEST'),
      valueGetter: (_, row) => row.labTest.name.toLowerCase(),
      renderCell: ({ row: { labTest } }) => t(labTest.name)
    },
    {
      field: 'results',
      flex: 7,
      headerName: t('RESULTS'),
      valueGetter: (_, row) => row.value.toLowerCase(),
      renderCell: ({ row: { value, measurementType } }) =>
        `${value} ${measurementType || ''}` || '-'
    },
    {
      field: 'comment',
      flex: 7,
      headerName: t('COMMENT'),
      valueGetter: (_, row) => row.comment?.toLowerCase(),
      renderCell: ({ row }) => (
        <Typography noWrap fontWeight={fontWeights.extraBold}>
          {row.comment}
        </Typography>
      )
    }
  ];

  return (
    <Card shadow marginBottom={spacings.x2large}>
      <StyledTable
        columns={columns}
        columnHeaderHeight={50}
        paginationModel={{
          pageSize: 10,
          page: 0
        }}
        rows={labResults || []}
        loading={isLoading}
        toolbar={
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Typography
              paddingY={spacings.large}
              fontWeight={fontWeights.extraBold}
            >
              {isPartner ? t('PARTNER_LAB_REVIEW') : t('LAB_REVIEW')}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() =>
                openDialog({
                  header: t('ADD_LAB_RESULT'),
                  fullWidth: true,
                  maxWidth: 'lg',
                  children: <EditPatientLabResult patientId={patientId} />
                })
              }
            />
          </Flex>
        }
        autoHeight
        freeHeight
      />
    </Card>
  );
};
