import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';

import { paginationDefaultParams } from 'src/constants';
import useTasks from 'src/hooks/useTasks';
import useMeApi from 'src/hooks/useMeApi';
import useDebounce from 'src/hooks/useDebounce';
import { TasksTable } from 'src/modules/tasksTable/TasksTable';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box';
import PatientHeader from '../PatientHeader';

interface Props {
  patientId: string;
}
const TaskInboxOverview = ({ patientId }: Props) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And
  });

  const [otherThanTodaysFilterModel, setOtherThanTodaysFilterModel] =
    useState<GridFilterModel>({
      items: [],
      logicOperator: GridLogicOperator.And
    });

  const [otherThanTodaysPaginationModel, setOtherThanTodaysPaginationModel] =
    useState(paginationDefaultParams);

  const [paginationModel, setPaginationModel] = useState(
    paginationDefaultParams
  );

  const { t } = useTranslation();
  const { getTasksByStaffPatientId } = useTasks();
  const { getMe } = useMeApi();
  const debouncedFilterModel = useDebounce(filterModel);
  const debouncedOtherThanTodaysFilterModel = useDebounce(filterModel);

  const { data: me } = getMe();

  const {
    data: tasks,
    isLoading: isTasksLoading,
    isFetching: isTasksFetching
  } = getTasksByStaffPatientId({
    staffId: me?.user?.id,
    patientId,
    todaysTasks: true,
    filters: debouncedFilterModel,
    params: {
      pagination: paginationModel
    },
    options: {
      enabled: !!me?.user?.id
    }
  });

  const {
    data: otherThanTodaysTasks,
    isLoading: isOtherThanTodaysTasksLoading,
    isFetching: isOtherThanTodaysTasksFetching
  } = getTasksByStaffPatientId({
    staffId: me?.user?.id,
    patientId,
    filters: debouncedOtherThanTodaysFilterModel,
    params: {
      pagination: otherThanTodaysPaginationModel
    },
    options: {
      enabled: !!me?.user?.id
    }
  });

  const isTodaysTasksLoading = isTasksLoading || isTasksFetching;
  const isLoading =
    isOtherThanTodaysTasksLoading || isOtherThanTodaysTasksFetching;

  const todaysTotalRowCount = tasks?.count ?? 0;
  const otherThanTodaysTotalRowCount = otherThanTodaysTasks?.count ?? 0;

  const handleTodaysTasksFilterChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel]
  );

  const handleOtherThanTodaysTasksFilterChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setOtherThanTodaysFilterModel(newFilterModel);
    },
    [setOtherThanTodaysFilterModel]
  );

  return (
    <Flex flexDirection="column" flex={1} gap={spacings.small} overflow="auto">
      <Typography variant="h1" color={Colors.emperor}>
        {t('TASKS')}
      </Typography>
      <Flex flexDirection="column" flex={1} gap={spacings.small}>
        <PatientHeader patientId={`${patientId}`} />
        {/* There is a bug when the width is 100%, it's a quick fix for this until finding another solution */}
        <Box width="99%" marginTop={spacings.xlarge}>
          {todaysTotalRowCount > 0 && (
            <Flex flexDirection="column">
              <Typography>{t('TODAY_TASKS')}</Typography>
              <TasksTable
                tasks={tasks.rows || []}
                loading={isTodaysTasksLoading}
                tableHeight="90%"
                pageSize={10}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                filterModel={filterModel}
                filterMode="server"
                onFilterModelChange={handleTodaysTasksFilterChange}
                rowCount={todaysTotalRowCount}
              />
            </Flex>
          )}
          {otherThanTodaysTotalRowCount > 0 && (
            <Flex flexDirection="column">
              <Typography>{t('ADDITIONAL_TASKS')}</Typography>
              <TasksTable
                tasks={otherThanTodaysTasks.rows || []}
                loading={isLoading}
                tableHeight="500px"
                pageSize={15}
                paginationModel={otherThanTodaysPaginationModel}
                paginationMode="server"
                onPaginationModelChange={setOtherThanTodaysPaginationModel}
                filterModel={otherThanTodaysFilterModel}
                filterMode="server"
                onFilterModelChange={handleOtherThanTodaysTasksFilterChange}
                rowCount={otherThanTodaysTotalRowCount}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default TaskInboxOverview;
