import { useTranslation } from 'react-i18next';
import {
  useDialog,
  useDocumentEditor
} from 'src/components/components-api/GlobalProvider/GlobalProvider';
import Button from 'src/components/display/Button';
import Typography from 'src/components/display/Typography';
import Center from 'src/components/layout/Center';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import useDocumentsApi from 'src/hooks/useDocumentsApi';

interface Props {
  documentId: string;
  patientId: string;
}
const DeleteDocumentDialog = ({ documentId, patientId }: Props) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { onDocumentEditorInfoChange } = useDocumentEditor();

  const { deleteDocument } = useDocumentsApi();

  const { mutate: handleDeleteDocument, isLoading } = deleteDocument();

  const handleDelete = () => {
    handleDeleteDocument({ documentId, patientId });
    onDocumentEditorInfoChange({});
    closeDialog();
  };
  return (
    <Flex
      flexDirection="column"
      minWidth="350px"
      maxWidth="650px"
      gap={spacings.medium}
      marginTop={spacings.medium}
    >
      <Typography fontWeight={fontWeights.bold}>
        {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_DOCUMENT')}
      </Typography>
      <Center gap={spacings.xlarge}>
        <Button onClick={closeDialog} disabled={isLoading}>
          {t('CANCEL')}
        </Button>
        <Button onClick={handleDelete} disabled={isLoading}>
          {t('DELETE')}
        </Button>
      </Center>
    </Flex>
  );
};
export default DeleteDocumentDialog;
