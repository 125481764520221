import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { Colors } from 'src/components/styles/colors';
import Card from 'src/components/display/Card/Card';
import { fontWeights } from 'src/components/styles/fonts';
import {
  PurposeOfFreeze,
  SemenAnalysisReportForm,
  VialType
} from 'src/types/exam';

const StyledCryoContainer = styled(Card)`
  background-color: ${Colors.vistaWhite};
  padding-block: ${spacings.xlarge};
  padding-inline: ${spacings.x2large};
`;

export const CryoDetails: FC<{
  control: Control<SemenAnalysisReportForm, any, SemenAnalysisReportForm>;
  errors: FieldErrors<SemenAnalysisReportForm>;
}> = ({ control, errors }) => {
  const { t } = useTranslation();

  return (
    <StyledCryoContainer marginTop={spacings.large}>
      <Typography
        marginBottom={spacings.medium}
        variant="h2"
        fontWeight={fontWeights.extraBold}
      >
        {t('CRYO_DETAILS')}
      </Typography>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="cryoDetails.purposeOfFreeze"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('PURPOSE_OF_FREEZE')}
                inputRef={ref}
                error={!!errors?.['cryoDetails.purposeOfFreeze']}
                helperText={errors?.['cryoDetails.purposeOfFreeze']?.message}
                defaultOption={t('PURPOSE_OF_FREEZE')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={Object.entries(PurposeOfFreeze).map(
                  ([key, value]) => ({
                    label: t(key),
                    value
                  })
                )}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="cryoDetails.freezeDate"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <DatePicker
                {...field}
                label={t('FREEZE_DATE')}
                inputRef={ref}
                error={!!errors?.['cryoDetails.freezeDate']}
                helperText={errors?.['cryoDetails.freezeDate']?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="cryoDetails.consentExpiration"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <DatePicker
                {...field}
                label={t('CONSENT_EXPIRATION')}
                inputRef={ref}
                error={!!errors?.['cryoDetails.consentExpiration']}
                helperText={errors?.['cryoDetails.consentExpiration']?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box flex={1} />
      </Flex>
      <Flex gap={spacings.large}>
        <Box flex={1}>
          <Controller
            name="cryoDetails.numberOfVialsStored"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('NUMBER_OF_VIALS_STORED_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('NUMBER_OF_VIALS_STORED').toUpperCase()}`}
                placeholder={t('NUMBER_OF_VIALS_STORED')}
                error={!!errors?.['cryoDetails.numberOfVialsStored']}
                helperText={
                  errors?.['cryoDetails.numberOfVialsStored']?.message
                }
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="cryoDetails.vialType"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('VIAL_TYPE')}
                inputRef={ref}
                error={!!errors?.['cryoDetails.vialType']}
                helperText={errors?.['cryoDetails.vialType']?.message}
                defaultOption={t('VIAL_TYPE')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={Object.entries(VialType).map(([key, value]) => ({
                  label: t(key),
                  value
                }))}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="cryoDetails.storageLocation"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('STORAGE_LOCATION').toUpperCase()}`}
                placeholder={t('STORAGE_LOCATION')}
                error={!!errors?.['cryoDetails.storageLocation']}
                helperText={errors?.['cryoDetails.storageLocation']?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="cryoDetails.tankNo"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('TANK_NO').toUpperCase()}
                placeholder={t('TANK_NO')}
                error={!!errors?.['cryoDetails.tankNo']}
                helperText={errors?.['cryoDetails.tankNo']?.message}
                fullWidth
                type="number"
              />
            )}
          />
        </Box>
      </Flex>
    </StyledCryoContainer>
  );
};
