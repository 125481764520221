import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings, spacingsPercentage } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import { LabResult } from 'src/types/appointment';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import dayjs from 'dayjs';
import useLabResults from 'src/hooks/useLabResults';
import ImageUpload from 'src/components/data-entry/ImageUpload';
import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import Loader from 'src/components/display/Loader';
import { FlexGridItem } from '../actionMenu/ConfirmPrescriptionForm';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { SvgIcon, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as FlagIcon } from 'src/assets/icons/flag.svg';
import { Colors } from 'src/components/styles/colors';
import { convertLabResultsServerToClient } from 'src/modules/patients/utils/conversions';
import isEqual from 'lodash/isEqual';

export interface LabResultForm {
  id?: string;
  cycleId?: string;
  date: Date;
  testId: string;
  value: string;
  minRange: number;
  maxRange: number;
  measurementType: string;
  comment: string;
  patientName: string;
  patientId: string;
  externalLabImage?: ImageFile[];
  deleteImage?: boolean;
  groupId?: string;
  isFlagged?: boolean;
  realId?: string;
}

export interface LabResultFormArray {
  patientName: string;
  patientId: string;
  date: Date;
  externalLabImage?: ImageFile[];
  deleteImage?: boolean;
  groupId?: string;
  labResultForm: LabResultForm[];
  resultsToDelete?: string[];
}

interface PatientLabResultDialogProps {
  patientId: string;
  labResults?: LabResult[];
  onDelete?: () => void;
}

const EditPatientLabResult: FC<PatientLabResultDialogProps> = ({
  patientId,
  labResults,
  onDelete
}) => {
  const { getLabTests, getPatientById } = usePatientsApi();
  const { createLabResult, updateLabResult, deleteLabResult } = useLabResults();
  const { t } = useTranslation();
  const { closeDialog } = useDialog();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const { data: labTests, isLoading: isLoadingLabTest } = getLabTests();

  const { mutate: handleCreateLabResult } = createLabResult();
  const { mutate: handleUpdateLabResult } = updateLabResult();
  const { mutate: handleDeleteLabResult } = deleteLabResult();

  const translatedLabTests =
    labTests?.map(({ id, name }) => ({
      label: name ? t(name, { defaultValue: name }) : '',
      value: id
    })) || [];

  const getDefaultLabResult = ({
    isNew
  }: {
    isNew?: boolean;
  }): LabResultForm[] => {
    if (!labResults?.length || isNew)
      return [
        {
          testId: '',
          value: '',
          minRange: null,
          maxRange: null,
          measurementType: '',
          comment: '',
          date: undefined,
          patientName: '',
          patientId: '',
          groupId: '',
          isFlagged: false
        }
      ];

    return convertLabResultsServerToClient(labResults);
  };

  const defaultValues: LabResultFormArray = {
    patientName: '',
    patientId,
    date: labResults?.[0]
      ? dayjs(labResults[0].date).toDate()
      : dayjs().toDate(),
    externalLabImage: labResults?.[0].externalLabImage
      ? [labResults[0].externalLabImage]
      : [],
    groupId: labResults?.[0]?.groupId || uuidv4(),
    labResultForm: getDefaultLabResult({ isNew: false }),
    resultsToDelete: []
  };

  const { control, reset, formState, handleSubmit, setValue, getValues } =
    useForm<LabResultFormArray>({
      mode: 'onChange',
      defaultValues
    });

  const {
    fields: defaultLabResults,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'labResultForm'
  });

  const { errors } = formState;

  useEffect(() => {
    if (labResults) {
      const updatedDefaultValues = {
        patientName: '',
        patientId,
        date: labResults[0]
          ? dayjs(labResults[0].date).toDate()
          : dayjs().toDate(),
        externalLabImage: labResults[0]?.externalLabImage
          ? [labResults[0].externalLabImage]
          : [],
        groupId: labResults[0]?.groupId || uuidv4(),
        labResultForm: convertLabResultsServerToClient(labResults),
        resultsToDelete: []
      };

      // Only reset if the values actually differ
      if (!isEqual(getValues(), updatedDefaultValues)) {
        reset(updatedDefaultValues);
      }
    }
  }, [labResults, reset, getValues, patientId]);

  useEffect(() => {
    if (patient) {
      setValue('patientName', getFullName(patient.personalInfo));
    }
  }, [patient]);

  const onSubmit = async (details: LabResultFormArray) => {
    details?.labResultForm?.forEach(async (item: LabResultForm) => {
      const result = {
        ...item,
        date: details.date,
        patientName: details.patientName,
        patientId: details.patientId,
        externalLabImage: details.externalLabImage,
        deleteImage: details.deleteImage,
        groupId: details.groupId
      };

      if (result?.id) {
        handleUpdateLabResult(
          { id: result.id, ...result },
          {
            onSuccess: () => closeDialog()
          }
        );
      } else {
        handleCreateLabResult(result, {
          onSuccess: () => closeDialog()
        });
      }
    });

    details?.resultsToDelete?.forEach(async (id) => {
      handleDeleteLabResult(
        { id },
        {
          onSuccess: () => closeDialog(),
          onSettled: () => {
            onDelete();
          }
        }
      );
    });
  };

  const isLoading = isLoadingPatient || isLoadingLabTest;

  if (isLoading) return <Loader />;

  return (
    <Box marginTop={spacings.xlarge}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex gap={spacings.x2large} marginBottom={spacings.xlarge}>
            <FlexGridItem span={5} columns={14}>
              <Controller
                name="patientName"
                control={control}
                rules={{
                  required: t('PATIENT_NAME_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    disabled
                    label={t('PATIENT_NAME_LABEL')}
                    placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    fullWidth
                  />
                )}
              />
            </FlexGridItem>
            <FlexGridItem span={5} columns={14}>
              <Controller
                name="date"
                control={control}
                rules={{
                  required: t('DATE_TAKEN_REQUIRED'),
                  validate: (value) =>
                    dayjs(value).isBefore(dayjs())
                      ? true
                      : t('DATE_MUST_NOT_BE_IN_PAST')
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    inputRef={ref}
                    label={t('DATE_TAKEN_LABEL')}
                    error={!!errors?.date}
                    helperText={errors?.date?.message}
                    fullWidth
                  />
                )}
              />
            </FlexGridItem>
          </Flex>
          <Flex minHeight={150} flexDirection="column">
            {defaultLabResults.map((labResult, index) => {
              return (
                <Flex key={labResult.id} marginBottom={spacings.xlarge}>
                  <Flex flexDirection="column" alignSelf="flex-start">
                    <Flex
                      gap={spacings.small}
                      marginRight={spacings.medium}
                      flex={1}
                      marginY={spacings.medium}
                      paddingTop={spacings.x2large}
                    >
                      <MiniIconButton
                        icon={<RemoveIcon />}
                        onClick={() => {
                          if (labResult?.realId) {
                            setValue('resultsToDelete', [
                              ...getValues('resultsToDelete'),
                              labResult?.realId
                            ]);
                          }
                          remove(index);
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" gap={spacings.x2large}>
                    <Flex gap={spacings.large}>
                      <FlexGridItem span={2} columns={12}>
                        <Controller
                          name={`labResultForm.${index}.testId`}
                          control={control}
                          rules={{
                            required: t('TEST_NAME_REQUIRED')
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <Select
                              {...field}
                              inputRef={ref}
                              label={t('TEST_NAME_LABEL')}
                              error={!!errors?.labResultForm?.[index]?.testId}
                              helperText={
                                errors?.labResultForm?.[index]?.testId?.message
                              }
                              defaultOption={t('PLEASE_CHOOSE_TEST_NAME')}
                              options={translatedLabTests}
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={1} columns={10}>
                        <Controller
                          name={`labResultForm.${index}.value`}
                          control={control}
                          rules={{
                            required: t('TEST_RESULT_REQUIRED')
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              placeholder={t('RESULTS')}
                              label={t('TEST_RESULT_LABEL')}
                              error={!!errors?.labResultForm?.[index]?.value}
                              helperText={
                                errors?.labResultForm?.[index]?.value?.message
                              }
                              fullWidth
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={1} columns={10}>
                        <Controller
                          name={`labResultForm.${index}.measurementType`}
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              label={t('LAB_RESULT_UNIT_TYPE_LABEL')}
                              placeholder={t(
                                'LAB_RESULT_UNIT_TYPE_PLACEHOLDER'
                              )}
                              error={
                                !!errors?.labResultForm?.[index]
                                  ?.measurementType
                              }
                              helperText={
                                errors?.labResultForm?.[index]?.measurementType
                                  ?.message
                              }
                              fullWidth
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={1} columns={10}>
                        <Controller
                          name={`labResultForm.${index}.minRange`}
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              label={t('LAB_RESULT_MIN_VALUE_LABEL')}
                              placeholder={t(
                                'LAB_RESULT_MIN_VALUE_PLACEHOLDER'
                              )}
                              error={!!errors?.labResultForm?.[index]?.minRange}
                              helperText={
                                errors?.labResultForm?.[index]?.minRange
                                  ?.message
                              }
                              fullWidth
                              inputProps={{
                                inputMode: 'decimal', // Allows decimal input on mobile devices
                                pattern: '[0-9]*[.,]?[0-9]+' // Accepts integers and decimals
                              }}
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={1} columns={10}>
                        <Controller
                          name={`labResultForm.${index}.maxRange`}
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              label={t('LAB_RESULT_MAX_VALUE_LABEL')}
                              placeholder={t(
                                'LAB_RESULT_MAX_VALUE_PLACEHOLDER'
                              )}
                              error={!!errors?.labResultForm?.[index]?.maxRange}
                              helperText={
                                errors?.labResultForm?.[index]?.maxRange
                                  ?.message
                              }
                              fullWidth
                              inputProps={{
                                inputMode: 'decimal', // Allows decimal input on mobile devices
                                pattern: '[0-9]*[.,]?[0-9]+' // Accepts integers and decimals
                              }}
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={4} columns={14}>
                        <Controller
                          name={`labResultForm.${index}.comment`}
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              label={t('LAB_RESULT_COMMENT_LABEL')}
                              placeholder={t('LAB_RESULT_COMMENT_PLACEHOLDER')}
                              error={!!errors?.labResultForm?.[index]?.comment}
                              helperText={
                                errors?.labResultForm?.[index]?.comment?.message
                              }
                              fullWidth
                            />
                          )}
                        />
                      </FlexGridItem>
                      <FlexGridItem span={1} columns={16}>
                        <Typography marginLeft={spacings.large}>
                          {t('IS_FLAGGED_LABEL').toLocaleUpperCase()}
                        </Typography>
                        <Controller
                          name={`labResultForm.${index}.isFlagged`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Flex
                              justifyContent={'center'}
                              alignItems={'center'}
                              height={spacings.x3large}
                            >
                              <SvgIcon
                                onClick={() => onChange(!value)}
                                style={{ cursor: 'pointer' }}
                              >
                                <FlagIcon
                                  color={value ? Colors.red : Colors.emperor}
                                />
                              </SvgIcon>
                            </Flex>
                          )}
                        />
                      </FlexGridItem>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            alignItems="center"
            marginTop={spacings.xlarge}
            gap={spacings.x2large}
          >
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() => {
                append(getDefaultLabResult({ isNew: true }));
              }}
            />
            <Typography variant="body1">{t('ADD_ANOTHER_RESULT')}</Typography>
          </Flex>
          <Flex marginTop={spacings.large} marginBottom={spacings.large}>
            <Box>
              <Controller
                name="externalLabImage"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ImageUpload
                    label={t('EXTERNAL_LAB_RESULT')}
                    limit={1}
                    value={value}
                    accept={{
                      'image/png': ['.png'],
                      'image/jpeg': ['.jpeg', '.jpg'],
                      pdf: ['.pdf']
                    }}
                    onAddNewFiles={(newFiles) => {
                      onChange([...newFiles]);
                    }}
                    onRemoveFile={(imageIndexToRemove) => {
                      onChange(
                        value.filter((_, index) => index !== imageIndexToRemove)
                      );

                      setValue('deleteImage', true);
                    }}
                  />
                )}
              />
            </Box>
          </Flex>
        </Box>

        <Flex
          justifyContent="right"
          mt={spacings.x2large}
          paddingX={spacings.x2large}
        >
          <Button width={spacingsPercentage.medium} type="submit">
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EditPatientLabResult;
