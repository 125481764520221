import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { GridFilterModel } from '@mui/x-data-grid-premium';

import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import { convertTaskClientToServer } from 'src/modules/patients/utils/conversions';
import { AppError } from 'src/types/global';
import {
  AddChecklistTemplateType,
  ChecklistTemplate,
  Task
} from 'src/types/task';
import { TaskServer } from 'src/types/patient-server';
import { PaginatedData, QueryParams } from 'src/types/pagination';
import {
  addChecklistTemplateRequest,
  createTaskRequest,
  deleteTaskRequest,
  getChecklistTemplatesRequest,
  getTasksByStaffId,
  getTasksPaginatedByStaffPatientIdRequest,
  getTasksPaginatedRequest,
  updateTaskRequest
} from 'src/api/tasks.api';
import { queryKeys, querySubKeys } from './queryKeys';

function useTasks() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    deleteTask: () =>
      useMutation<string, AppError, { taskId: string; patientId: string }>(
        ({ taskId, patientId }) => deleteTaskRequest(taskId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_DELETE_TASK')
            }),

          onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.TASKS]);

            openToast({
              title: t('DELETE_TASK_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    createTask: () =>
      useMutation<string, AppError, TaskServer>(createTaskRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_TASK')
          }),

        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.TASKS]);

          openToast({
            title: t('CREATE_TASK_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
        }
      }),
    updateTask: () =>
      useMutation<string, AppError, Partial<Task>>(
        (task) => updateTaskRequest(convertTaskClientToServer(task)),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_TASK')
            }),

          onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.TASKS]);

            openToast({
              title: t('UPDATE_TASK_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    getTasksAssignedToStaff: (
      staffId: string,
      options?: UseQueryOptions<Task[], AppError>
    ) =>
      useQuery<Task[], AppError>(
        [queryKeys.TASKS, staffId],
        () => getTasksByStaffId(staffId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_ASSIGNED_TO_STAFF')
            }),

          ...options
        }
      ),
    getTasksByStaffPatientId: ({
      staffId,
      patientId,
      todaysTasks,
      filters,
      params,
      options
    }: {
      staffId: string;
      patientId: string;
      todaysTasks?: boolean;
      filters?: GridFilterModel;
      params?: QueryParams;
      options?: UseQueryOptions<PaginatedData<Task>, AppError>;
    }) =>
      useQuery<PaginatedData<Task>, AppError>(
        [
          queryKeys.TASKS,
          querySubKeys[queryKeys.TASKS].STAFF,
          staffId,
          querySubKeys[queryKeys.TASKS].PATIENTS,
          patientId,
          { todaysTasks, ...params.pagination, ...filters }
        ],
        () =>
          getTasksPaginatedByStaffPatientIdRequest(
            params,
            staffId,
            patientId,
            todaysTasks,
            filters
          ),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TASKS_BY_PATIENT')
            }),
          ...options
        }
      ),
    getChecklistTemplates: (patientId) =>
      useQuery<ChecklistTemplate[], AppError>(
        [queryKeys.TASKS, patientId],
        () => getChecklistTemplatesRequest(patientId)
      ),
    addChecklistTemplateToPatient: () =>
      useMutation<
        Task[],
        AppError,
        AddChecklistTemplateType & { onSuccess: () => void }
      >((taskTemplateToAdd) => addChecklistTemplateRequest(taskTemplateToAdd), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_ADD_CHECKLIST_TEMPLATE')
          }),
        onSuccess: (_, { onSuccess }) => {
          queryClient.invalidateQueries([queryKeys.TASKS]);

          openToast({
            title: t('ADD_CHECKLIST_TEMPLATE_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          onSuccess();
        }
      }),
    getPatientTasks: ({
      patientId,
      showDone,
      filters,
      params,
      options
    }: {
      patientId?: string;
      showDone?: boolean;
      filters?: GridFilterModel;
      params?: QueryParams;
      options?: UseQueryOptions<PaginatedData<Task>, AppError>;
    }) =>
      useQuery<PaginatedData<Task>, AppError>(
        [
          queryKeys.TASKS,
          { patientId, showDone, ...params.pagination, ...filters }
        ],
        () => {
          return getTasksPaginatedRequest(params, patientId, filters, showDone);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_TASKS')
            }),
          ...options
        }
      )
  };
}

export default useTasks;
