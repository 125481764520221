import React, { FC, useMemo, useState } from 'react';
import { SelectOption as MuiSelectOption } from '@mui/base';
import { MenuItem, SvgIcon, css } from '@mui/material';
import { styled } from '@mui/material';
import { spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { makeShouldForwardProps } from '../../utils';
import InputField, { InputFieldProps } from '../InputField/InputField';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { Option, OptionExtended } from 'src/types/option';
import { isOptionExtended } from 'src/utils/general';

export type SelectOption<T> = Omit<MuiSelectOption<T>, 'ref'> & {
  ref?: React.RefObject<Element>;
};

type SelectVariant = 'select' | 'filter' | 'filled';

interface SelectInternalProps extends Omit<InputFieldProps, 'variant'> {
  defaultOption?: string;
  noBorder?: boolean;
  variant?: SelectVariant;
  initialDisabled?: boolean;
}

type OptionDefault = SelectOption<string>[];

interface SelectPropsDefault {
  shouldSortOptions?: false;
  options: OptionDefault;
}

interface SelectPropsSort {
  shouldSortOptions: true;
  options: OptionExtended[];
}

type SelectProps = SelectInternalProps & (SelectPropsDefault | SelectPropsSort);

const getSelectIconComponent = (variant: SelectVariant) => {
  if (variant === 'filter') {
    return () => (
      <SvgIcon>
        <FilterIcon />
      </SvgIcon>
    );
  } else {
    return undefined;
  }
};

const shouldForwardProp = makeShouldForwardProps(['noBorder']);
const StyledInputField = styled(
  ({ variant: _variant, ...props }: SelectInternalProps) => (
    <InputField {...props} />
  ),
  { shouldForwardProp }
)<SelectInternalProps>`
  .MuiInputBase-root {
    cursor: pointer;
    height: 40px;
    ${({ variant, noBorder }) => {
      switch (variant) {
        case 'filled':
          return css`
            border: none;
            background-color: ${Colors.lightGray};
            border-radius: 0px;
            outline: none;
            padding: 0;

            &:hover {
              box-shadow: none;
            }
          `;
        case 'filter':
          return css`
            border: none;
            padding: ${spacings.small};
          `;
        case 'select':
        default:
          return css`
            border: ${noBorder ? 'none' : css`1px solid ${Colors.gray}`};
            padding: ${spacings.medium} ${spacings.x2large} ${spacings.medium}
              ${spacings.medium};
          `;
      }
    }}
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
      padding-inline: ${spacings.small};
    }
  }
  .MuiFormLabel-root {
    overflow: inherit;
    text-transform: uppercase;
  }
`;

export const SelectMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.MuiButtonBase-root {
    &.Mui-selected,
    &:hover {
      background-color: ${Colors.vistaWhite};
      color: ${Colors.emperor};
    }
  }
`;

const Select: FC<SelectProps> = ({
  options,
  defaultOption,
  value,
  helperText,
  variant,
  disabled = false,
  initialDisabled,
  shouldSortOptions,
  ...otherProps
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const extendedOptions = useMemo(
    () => (options as Option[])?.filter(isOptionExtended),
    [options]
  );

  const sortedOptions = useMemo(
    () =>
      shouldSortOptions
        ? extendedOptions
            .map((item) => item)
            .sort((a, b) => a.labelText.localeCompare(b.labelText))
        : options,
    [extendedOptions, options, shouldSortOptions]
  );

  return (
    <StyledInputField
      {...otherProps}
      disabled={initialDisabled ? value === '' : disabled}
      select
      onClick={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          setIsMenuOpen(!isMenuOpen);
        }
      }}
      helperText={helperText}
      SelectProps={{
        IconComponent: getSelectIconComponent(variant),
        size: 'small',
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          open: isMenuOpen && !disabled
        }
      }}
      variant={variant}
      value={value || value === 0 ? value : 'none'}
    >
      {defaultOption && (
        <SelectMenuItem value="none" disabled>
          {defaultOption}
        </SelectMenuItem>
      )}
      {sortedOptions.map((option, index) => (
        <SelectMenuItem
          key={`${option.value}-${index}`}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </SelectMenuItem>
      ))}
    </StyledInputField>
  );
};

export default Select;
