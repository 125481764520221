import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { NormalOrAbnormal, ValueAndTrend, YesOrNo } from './global';

export enum OtherExaminationTypes {
  FOLLICULAR_SCAN = 'Follicular Scan',
  EGG_RETRIEVAL = 'Egg Retrieval',
  EMBRYOLOGY = 'Embryology',
  EMBRYO_TRANSFER = 'Embryo Transfer',
  IUI = 'IUI',
  PHYSICAL_EXAM_FEMALE = 'Physical exam female',
  SPERM_ANALYSIS = 'Sperm Analysis',
  SPERM_ANALYSIS_IN_CYCLE = 'Semen Analysis - Cycle',
  SPERM_ANALYSIS_DIAGNOSTIC = 'Semen Analysis - Diagnostics'
}

export enum UltrasoundExaminationTypes {
  TRANSVAGINAL_ULTRASONOGRAPHY = 'Transvaginal ultrasonography',
  SALINE_INFUSION_ULTRASONOGRAPHY = 'Saline infusion ultrasonography',
  HYSTEROSALPINGOGRAPHY = 'Hysterosalpingography',
  HYSTEROSCOPY = 'Hysteroscopy',
  HYSTEROSALPINGOGRAM = 'Hysterosalpingogram',
  SONOHYSTEROGRAPHY = 'Sonohysterography',
  HYSTEROSALPINGO_CONTRAST_SONOGRAPHY = 'Hysterosalpingo-contrast sonography',
  LAP = 'Lap',
  ULTRASOUND_FEMALE = 'Ultrasound (female)',
  ULTRASOUND_MALE = 'Ultrasound (male)',
  VASOGRAPHY = 'Vasography'
}

export type ExaminationType =
  | OtherExaminationTypes
  | UltrasoundExaminationTypes;

export enum ExamReason {
  IN_CYCLE = 'In Cycle',
  DIAGNOSTIC = 'Diagnostic',
  PREGNANCY = 'Pregnancy',
  D3 = 'D3'
}

export enum SemenAnalysisExamReason {
  DONOR = 'Donor',
  PRESERVATION = 'Preservation',
  IN_CYCLE = 'In Cycle',
  DIAGNOSTIC = 'Diagnostic'
}

export const semenAnalysisExamReasonEntries = Object.entries(
  SemenAnalysisExamReason
);

export enum Observations {
  POLYPS = 'POLYPS',
  LEIOMYOMAS = 'LEIOMYOMAS',
  ADENOMYOSIS = 'ADENOMYOSIS',
  UNICORNUATE = 'UNICORNUATE',
  SEPTATE = 'SEPTATE',
  BICORNUATE_UTERI = 'BICORNUATE_UTERI',
  SUBMUCOUS_MYOMAS = 'SUBMUCOUS_MYOMAS',
  SYNECHIAE = 'SYNECHIAE',
  ENDOMETRIOSIS = 'ENDOMETRIOSIS',
  CYST = 'CYST',
  VARICOCELE = 'VARICOCELE',
  ENDOMETRIOMA = 'ENDOMETRIOMA',
  TRILAMINAR_ENDOMETRIAL = 'TRILAMINAR_ENDOMETRIAL',
  FLUID_IN_UTERINE_CAVITY = 'FLUID_IN_UTERINE_CAVITY'
}

export enum CollectionMethod {
  EJACULATED = 'Ejaculated',
  EPIDIDYMAL = 'Epididymal',
  TESTIS = 'Testis',
  ELECTROEJACULATION = 'Electroejaculation',
  RETROGRADE_URINE = 'Retrograde urine',
  CONDOM = 'Condom',
  TESA = 'TESA',
  TESE = 'TESE',
  PESA = 'PESA',
  UNKNOWN = 'Unknown'
}

export const collectionMethodEntries = Object.entries(CollectionMethod);

export enum BladderStatus {
  SUFFICIENTLY_FULL = 'Sufficiently Full',
  INSTRUCTED_TO_DRINK_MORE = 'Instructed to drink more',
  'N/A' = 'N/A'
}

export enum OneTwoThree {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE'
}

export enum Catheter {
  WALLACE = 'WALLACE'
}

export enum Difficulty {
  EASY = 'EASY',
  DIFFICULT = 'DIFFICULT'
}

export interface ExaminationFindings {
  id: number;
  name: Observations;
  examinationId: string;
}

interface ExaminationMedications {
  id: string;
  examinationId: string;
  medicationId: string;
}

export interface Examination {
  id: string;
  patientId?: string;
  orderDate?: Date;
  examDate?: Date;
  requestedPhysician?: string;
  performedBy?: string;
  signedOffBy?: string;
  witnessedBy?: string;
  cycleId?: string;
  type?: ExaminationType;
  medicationsAdministered?: ExaminationMedications[];
  eggAndEmbryoTransferred?: string[];
  reason?: ExamReason;
  generalRemarks?: string[];
  findings?: ExaminationFindings[];
  notes?: string;
  fetalHeartbeat?: number;
  ctrl?: number;
  folliclesSizeRight?: number[];
  afcRight?: number;
  folliclesSizeLeft?: number[];
  afcLeft?: number;
  endometrialThickness?: number;
  correctSwabCount?: YesOrNo;
  procedureUncompleted?: YesOrNo;
  eggsInsimated?: number;
  eggsMature?: number;
  eggsRetrieved?: number;
  dateOfRetrieval?: Date;
  bladderStatus?: BladderStatus;
  catheter?: Catheter;
  easeOfTransfer?: Difficulty;
  flashSeen?: YesOrNo;
  noOfEmbryosTransferred?: OneTwoThree;
  positionOfUterus?: PositionOfUterus;
  taScan?: YesOrNo;
  cervixQuality?: string;
  easeOfIUI?: Difficulty;
  images?: ExaminationImage[];
  semenAnalysis?: SemenAnalysis;
  physicalExamFemale?: PhysicalExamFemale;
  isNonCompliantET?: boolean;
  nonCompliantETReason?: string;
}

export enum SkinExamValues {
  HIRSUTISM = 'HIRSUTISM',
  ACNE = 'ACNE',
  NO_ACNE = 'NO_ACNE',
  ANDROGENIC_ALOPECIA = 'ANDROGENIC_ALOPECIA',
  ACANTHOSIS_NIGRICANS = 'ACANTHOSIS_NIGRICANS',
  NO_HIRSUTISM = 'NO_HIRSUTISM',
  NO_ANDROGENIC_ALOPECIA = 'NO_ANDROGENIC_ALOPECIA',
  NO_ACANTHOSIS_NIGRICANS = 'NO_ACANTHOSIS_NIGRICANS',
  ABNORMAL_SKIN_EXAM = 'ABNORMAL_SKIN_EXAM',
  NORMAL_SKIN_EXAM = 'NORMAL_SKIN_EXAM'
}

export enum ThyroidExamValues {
  ABNORMAL_THYROID_TEXTURE_AND_SIZE = 'ABNORMAL_THYROID_TEXTURE_AND_SIZE',
  NORMAL_THYROID_TEXTURE_AND_SIZE = 'NORMAL_THYROID_TEXTURE_AND_SIZE',
  PRESENCE_OF_NODULARITY = 'PRESENCE_OF_NODULARITY',
  NO_PRESENCE_OF_NODULARITY = 'NO_PRESENCE_OF_NODULARITY',
  TENDERNESS = 'TENDERNESS',
  NO_TENDERNESS = 'NO_TENDERNESS',
  CERVICAL_ADENOPATHY = 'CERVICAL_ADENOPATHY',
  NO_CERVICAL_ADENOPATHY = 'NO_CERVICAL_ADENOPATHY',
  NORMAL_THYROID_EXAM = 'NORMAL_THYROID_EXAM',
  ABNORMAL_THYROID_EXAM = 'ABNORMAL_THYROID_EXAM'
}

export enum BreastExamValues {
  PALPABLE_TENDERNESS = 'PALPABLE_TENDERNESS',
  NO_PALPABLE_TENDERNESS = 'NO_PALPABLE_TENDERNESS',
  MASSES = 'MASSES',
  NO_MASSES = 'NO_MASSES',
  SKIN_CHANGES = 'SKIN_CHANGES',
  NO_SKIN_CHANGES = 'NO_SKIN_CHANGES',
  EXPRESSED_OR_SPONTANEOUS = 'EXPRESSED_OR_SPONTANEOUS',
  NO_EXPRESSED_OR_SPONTANEOUS = 'NO_EXPRESSED_OR_SPONTANEOUS',
  NIPPLE_DISCHARGE = 'NIPPLE_DISCHARGE',
  NO_NIPPLE_DISCHARGE = 'NO_NIPPLE_DISCHARGE',
  NORMAL_BREAST_EXAM = 'NORMAL_BREAST_EXAM',
  ABNORMAL_BREAST_EXAM = 'ABNORMAL_BREAST_EXAM'
}

interface PhysicalExamFemale {
  id?: string;
  generalAppearance: NormalOrAbnormal;
  earsNoseThroat: NormalOrAbnormal;
  eyes: NormalOrAbnormal;
  nodes: NormalOrAbnormal;
  heart: NormalOrAbnormal;
  lungs: NormalOrAbnormal;
  bowelSounds: NormalOrAbnormal;
  abdomen: NormalOrAbnormal;
  legs: NormalOrAbnormal;
  abnormalComments: string;
  skinExam: SkinExamValues[];
  thyroidExam: ThyroidExamValues[];
  breastExam: BreastExamValues[];
  speculumExam: SpeculumExamValues[];
  bimanualPelvicExam: BimanualPelvicExamValues[];
  generalNotes?: string;
}

export enum BimanualPelvicExamValues {
  TENDERNESS = 'TENDERNESS',
  NO_TENDERNESS = 'NO_TENDERNESS',
  RECTOVAGINAL_MASSES_OR_NODULARITY = 'RECTOVAGINAL_MASSES_OR_NODULARITY',
  NO_RECTOVAGINAL_MASSES_OR_NODULARITY = 'NO_RECTOVAGINAL_MASSES_OR_NODULARITY',
  UTERINE_MASSES = 'UTERINE_MASSES',
  NO_UTERINE_MASSES = 'NO_UTERINE_MASSES',
  OVARIAN_MASSES = 'OVARIAN_MASSES',
  NO_OVARIAN_MASSES = 'NO_OVARIAN_MASSES',
  NORMAL_BIMANUAL_PELVIC_EXAM = 'NORMAL_BIMANUAL_PELVIC_EXAM',
  ABNORMAL_BIMANUAL_PELVIC_EXAM = 'ABNORMAL_BIMANUAL_PELVIC_EXAM'
}

export enum SpeculumExamValues {
  DYSPAREUNIA = 'DYSPAREUNIA',
  NO_DYSPAREUNIA = 'NO_DYSPAREUNIA',
  POSTCOITAL_SPOTTING = 'POSTCOITAL_SPOTTING',
  NO_POSTCOITAL_SPOTTING = 'NO_POSTCOITAL_SPOTTING',
  VAGINAL_AND_CERVICAL_ABNORMALITIES = 'VAGINAL_AND_CERVICAL_ABNORMALITIES',
  NO_VAGINAL_AND_CERVICAL_ABNORMALITIES = 'NO_VAGINAL_AND_CERVICAL_ABNORMALITIES',
  LESIONS = 'LESIONS',
  NO_LESIONS = 'NO_LESIONS',
  CERVICAL_POLYPS = 'CERVICAL_POLYPS',
  NO_CERVICAL_POLYPS = 'NO_CERVICAL_POLYPS',
  NORMAL_SPECULUM_EXAM = 'NORMAL_SPECULUM_EXAM',
  ABNORMAL_SPECULUM_EXAM = 'ABNORMAL_SPECULUM_EXAM'
}

export enum CollectionLocation {
  AT_CLINIC = 'AT_CLINIC',
  AT_PARTNER_CLINIC = 'AT_PARTNER_CLINIC',
  AT_HOME = 'AT_HOME'
}

export const collectionLocationEntries = Object.entries(CollectionLocation);

export enum SpermType {
  FRESH = 'Fresh',
  THAWED = 'Thawed',
  MIX_OF_FRESH_AND_THAWED = 'Mix of fresh and thawed',
  UNKNOWN = 'Unknown'
}

export const spermTypeEntries = Object.entries(SpermType);

export enum SemenSource {
  OWN = 'OWN',
  DONOR = 'DONOR'
}

export const semenSourceEntries = Object.entries(SemenSource);

export enum SpermPrepMethod {
  DG = 'DG',
  MINI_DG = 'MINI_DG',
  SWIM_UP = 'SWIM_UP',
  WASH_ONLY = 'WASH_ONLY',
  MICROFLUIDICS_CHIP = 'MICROFLUIDICS_CHIP',
  MASCERATION = 'MASCERATION',
  COLLAGENASE = 'Collagenase',
  'N/A' = 'N/A',
  SWIM_OUT = 'Swim Out',
  ZYMOT = 'Zymot'
}

export const spermPrepMethodEntries = Object.entries(SpermPrepMethod);

export enum SpermPrepSuitability {
  IUI_SUITABLE = 'IUI_SUITABLE',
  IVF_SUITABLE = 'IVF_SUITABLE',
  ICSI_SUITABLE = 'ICSI_SUITABLE',
  CONSIDER_SECOND_COLLECTION = 'CONSIDER_SECOND_COLLECTION',
  NOT_SUITABLE_FOR_TREATMENT = 'NOT_SUITABLE_FOR_TREATMENT'
}

export const spermPrepSuitabilityEntries = Object.entries(SpermPrepSuitability);

export enum SampleFate {
  CRYO = 'CRYO',
  TREATMENT = 'TREATMENT',
  DISCARDED = 'DISCARDED'
}

export const sampleFateEntries = Object.entries(SampleFate);

export enum Appearance {
  GREY_OPALESCENT = 'GREY_OPALESCENT',
  RED_BROWN = 'RED_BROWN',
  YELLOW = 'YELLOW',
  CLEAR = 'CLEAR',
  CRYSTALS = 'CRYSTALS',
  GEL = 'GEL'
}

export const appearanceEntries = Object.entries(Appearance);

export enum TypeOfAgglutination {
  HEAD_TO_HEAD = 'HEAD_TO_HEAD',
  TAIL_TO_TAIL = 'TAIL_TO_TAIL',
  TAIL_TIP_TO_TAIL_TIP = 'TAIL_TIP_TO_TAIL_TIP',
  MIXED = 'MIXED',
  TANGLED = 'TANGLED',
  'N/A' = 'N/A'
}

export const typeOfAgglutinationEntries = Object.entries(TypeOfAgglutination);

export enum PurposeOfFreeze {
  OWN_USE = 'OWN_USE',
  TREATMENT_BACK_UP = 'TREATMENT_BACK_UP',
  FERTILITY_PRESERVATION = 'FERTILITY_PRESERVATION',
  SPERM_DONATION = 'SPERM_DONATION',
  SURROGACY = 'SURROGACY'
}

export enum TranferGeneralRemarks {
  ABNORMAL_DISCHARGE = 'ABNORMAL_DISCHARGE',
  BLEEDING = 'BLEEDING',
  PATIENT_REPORTED_PAIN = 'PATIENT_REPORTED_PAIN'
}

export enum VialType {
  STRAWS = 'Straw',
  AMPS = 'Amps',
  VIALS = 'Vials'
}

export enum Polarity {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative'
}

export const polarityEntries = Object.entries(Polarity);

export interface CryoDetailsAttributes {
  purposeOfFreeze: PurposeOfFreeze;
  numberOfVialsStored: number;
  vialType: VialType;
  consentExpiration: Date;
  freezeDate: Date;
  storageLocation: string;
  tankNo: number;
}
interface SemenAnalysisReport {
  dateProduced?: Date;
  timeReceived?: Date;
  timeProduced?: Date;
  timeOfAnalysis?: Date;
  collectionType?: CollectionMethod;
  spermType?: SpermType;
  dateOfInitialCryo?: Date;
  semenSource?: SemenSource;
  spermPrepMethod?: SpermPrepMethod[];
  spermPrepSuitability?: SpermPrepSuitability;
  sampleFate?: SampleFate;
  daysOfAbstinence?: number;
  volumeOfSpermSample?: number;
  volume?: number;
  ph?: number;
  appearance?: Appearance;
  concentration?: number;
  totalSpermCount?: number;
  normalMorphology?: number;
  progressiveMotileA?: number;
  progressiveMotileB?: number;
  progressiveMotileC?: number;
  generalComments?: string;
  motileSpermCount?: number;
  progressiveMotileSpermCount?: number;
  progressiveMotility?: number;
  totalMotile?: number;
  agglutination?: Agglutination;
  typeOfAgglutination?: TypeOfAgglutination;
  viscosity?: Viscosity;
  antibodyImmunoglobulinA?: Polarity;
  antibodyIgaResult?: number;
  antibodyImmunoglobulinG?: Polarity;
  antibodyIggResult?: number;
  roundCell?: number;
  liquefaction?: Liquefaction;
  movementComments?: string;
  cryoDetails?: CryoDetailsAttributes;
  spermPrepStartTime?: Date;
  spermPrepFinishTime?: Date;
}

export interface SemenAnalysis
  extends Omit<
    SemenAnalysisReport,
    | 'concentration'
    | 'totalSpermCount'
    | 'normalMorphology'
    | 'volume'
    | 'totalMotile'
    | 'movementComments'
  > {
  collectionLocation?: CollectionLocation;
  volume?: ValueAndTrend;
  concentration?: ValueAndTrend;
  totalSpermCount?: ValueAndTrend;
  normalMorphology?: ValueAndTrend;
  totalMotile?: ValueAndTrend;
  resultsGeneralComments?: string;
  purposeOfFreeze?: PurposeOfFreeze;
  freezeDate?: Date;
  numberOfVialsStored?: number;
  vialType?: VialType;
  consentExpiration?: Date;
  storageLocation?: string;
  tankNo?: number;
  spermPrepComments?: string;
  containerType?: ContainerTypes;
  completeSample?: boolean;
  spermPrepVolume?: number;
  spermPrepConcentration?: number;
  spermPrepTotalSpermCount?: number;
  spermPrepNormalMorphology?: number;
  spermPrepProgressiveMotileA?: number;
  spermPrepProgressiveMotileB?: number;
  spermPrepProgressiveMotileC?: number;
  spermPrepMotileSpermCount?: number;
  spermPrepProgressiveMotileSpermCount?: number;
  spermPrepTotalMotile?: number;
  spermPrepProgressiveMotility?: number;
  immotileD?: number;
  spermPrepImmotileD?: number;
  infectiousStatus?: YesOrNo;
  infectiousReason?: string;
}

export enum Liquefaction {
  PARTIAL = 'PARTIAL',
  NORMAL = 'NORMAL',
  NO_LIQUEFACTION = 'NO_LIQUEFACTION'
}

export const liquefactionEntries = Object.entries(Liquefaction);

export enum ContainerTypes {
  STERILE_CONTAINER = 'Sterile Container',
  STERILE_OVERNIGHT_COURIER = 'Sterile Overnight Courier',
  UNKNOWN = 'Unknown'
}

export const containerTypesEntries = Object.entries(ContainerTypes);

export enum Viscosity {
  VISCOUS = 'VISCOUS',
  NORMAL = 'NORMAL'
}

export const viscosityEntries = Object.entries(Viscosity);

interface ExaminationImage {
  id: string;
  key: string;
  name: string;
  type: string;
  url: string;
}

export enum Agglutination {
  NO_AGGLUTINATION = 'NONE',
  ISOLATED = 'ISOLATED',
  LARGE = 'LARGE',
  MODERATE = 'MODERATE',
  GROSS = 'GROSS',
  'N/A' = 'N/A'
}

export const agglutinationEntries = Object.entries(Agglutination);

export enum PositionOfUterus {
  RETROVERTED = 'retroverted',
  ANTEVERTED = 'anteverted'
}

export interface ExamReport {
  id?: string;
  cycleId?: string;
  patientId: string;
  patientName: string;
  dateOfExamination?: Date;
  dateOfRetrieval?: Date;
  type: ExaminationType;
  reason: ExamReason | SemenAnalysisExamReason;
  afcLeft?: number;
  afcRight?: number;
  endometrial?: number;
  leftFollicles?: number[];
  rightFollicles?: number[];
  requestingPhysicianId?: string;
  performedById?: string;
  signedOffById?: string;
  witnessedBy?: string;
  observations?: Observations[];
  orderDate?: Date;
  notes?: string;
  images?: ImageFile[];
  reportId?: string;
  swabCountCorrect?: YesOrNo;
  uncomplicatedProcedure?: YesOrNo;
  medicationsAdministered?: string[];
  generalRemarks?: string[];
  eggsInsimated?: number;
  eggsMature?: number;
  eggsRetrieved?: number;
  bladderStatus?: BladderStatus;
  catheter?: Catheter;
  easeOfTransfer?: Difficulty;
  easeOfIUI?: Difficulty;
  flashSeen?: YesOrNo;
  noOfEmbryosTransferred?: OneTwoThree;
  positionOfUterus?: PositionOfUterus;
  taScan?: YesOrNo;
  taScanFindings?: string[];
  embryosTransferred?: string[];
  embryosTransferredDeleted?: string[];
  cervixQuality?: string;
  imagesToDelete?: string[];
  fetalHeartbeat?: number;
  ctrl?: number;
  isNonCompliantET?: boolean;
  nonCompliantETReason?: string;
  semenAnalysis?: SemenAnalysisReport;
  physicalExamFemale?: PhysicalExamFemale;
}

export interface SemenAnalysisReportForm
  extends Omit<
    SemenAnalysis,
    | 'concentration'
    | 'totalSpermCount'
    | 'normalMorphology'
    | 'volume'
    | 'totalMotile'
    | 'collectionLocation'
  > {
  id?: string;
  cycleId: string;
  patientId: string;
  patientName: string;
  type: ExaminationType;
  reason: ExamReason;
  performedById: string;
  witnessedBy: string;
  signedOffById: string;
  requestingPhysicianId: string;
  collectionLocation: CollectionLocation;
  concentration?: number;
  totalSpermCount?: number;
  normalMorphology?: number;
  volume?: number;
  totalMotile?: number;
}

export interface PossiblePatient {
  id: string;
  name: string;
}
