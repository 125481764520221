import {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  AutocompleteChangeReason,
  InputAdornment,
  SxProps,
  TextField,
  css
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@mui/icons-material';
import { styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Box from 'src/components/layout/Box';
import IconButton from 'src/components/display/IconButton';
import { iconSizes, radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import Typography from 'src/components/display/Typography/Typography';
import i18n from 'src/i18n/i18n';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import Popover from 'src/components/display/Popover/Popover';
import Card from '../../display/Card';
import Chip from './Chip';
import Flex from 'src/components/layout/Flex/Flex';
import { INote } from 'src/types/cycle';
import Note from 'src/components/display/Note';
import Loader from 'src/components/display/Loader';
import { makeShouldForwardProps } from '../../utils';
import Autocomplete from '../Autocomplete';
import { Option, OptionExtended } from 'src/types/option';
import { isOptionExtended } from 'src/utils/general';
import { getOptionValue, isOptionEqualToValue } from './utils';

export enum ChipsVariants {
  DEFAULT = 'DEFAULT',
  EXPANDED = 'EXPANDED',
  NOTES = 'NOTES'
}

type ChipsBaseProps = {
  value: string[] | INote[];
  options?: Option[];
  title?: string;
  titleComponent?: ReactElement;
  error?: boolean;
  variant?: ChipsVariants;
  chipsInputLabel?: string;
  chipsInputPlaceholder?: string;
  onAddChip?: (value: string) => void;
  onRemoveChip?: (value: string, index: number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onSearch?: (searchTerm: string) => void;
  customAddButton?: ReactElement;
  renderSelectedOptionsOutside?: boolean;
  allowAddingNewOptions?: boolean;
  closeOnSelection?: boolean;
  helperText?: string;
  open?: boolean;
  chipsInputSx?: SxProps;
  isLoading?: boolean;
  id?: string;
  disabled?: boolean;
};

type ChipCustomOptionsProps = {
  customOptions: true;
  shouldSortOptions?: false;
  options?: Option[] | OptionExtended[];
};

type ChipSortOptionsProps = {
  customOptions?: false;
  shouldSortOptions: true;
  options: OptionExtended[];
};

type ChipDefaultOptionsProps = {
  customOptions?: boolean;
  options?: Option[] | OptionExtended[];
};

export type ChipsProps = ChipsBaseProps &
  (ChipCustomOptionsProps | ChipSortOptionsProps | ChipDefaultOptionsProps);

interface ChipsInputProps
  extends Omit<
    ChipsProps,
    'title' | 'renderSelectedOptionsOutside' | 'customAddButton'
  > {
  closeModal: () => void;
}

const StyledAddModalHeader = styled(Box)<{
  variant: ChipsVariants;
}>`
  display: ${({ variant }) =>
    variant === ChipsVariants.DEFAULT || variant === ChipsVariants.NOTES
      ? 'flex'
      : 'none'};
  align-items: center;
  justify-content: space-between;
  padding-inline: ${spacings.medium};
  padding-top: ${spacings.small};
  padding-bottom: ${spacings.medium};
`;

const StyledChipsContainer = styled(Box)<ChipsProps>`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
  flex-wrap: wrap;
  .MuiButtonBase-root {
    align-self: center;
  }
  ${({ variant }) =>
    variant === ChipsVariants.EXPANDED &&
    `
    .MuiBadge-root {
      display: none;
    }
  `}
`;

const ClickableBox = styled(Box)`
  cursor: pointer;
`;

const RelativeFlex = styled(Flex)`
  position: relative;
`;

const StyledAddButtonContainer = styled(Box, {
  shouldForwardProp: makeShouldForwardProps(['pointer', 'disabled'])
})<{ pointer: boolean; disabled?: boolean }>`
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const StyledChipsLabel = styled(InputLabel)`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
  margin-bottom: 0;
  padding-bottom: ${spacings.small};
`;

const StyledAddChipContainer = styled(Card)<{
  variant: ChipsVariants;
}>`
  border-radius: ${radii.medium};

  ${({ variant }) => {
    switch (variant) {
      case ChipsVariants.EXPANDED:
        return `border: 1px solid ${Colors.gray};`;

      case ChipsVariants.DEFAULT:
      default:
        return css`
          width: 250px;
          margin-top: ${spacings.small};
          height: 100%;
        `;
    }
  }}
`;

export const NotesList: FC<{
  notes: INote[];
  onRemoveNote?: (value: string, index: number) => void;
  fullWidth?: boolean;
}> = ({ notes, onRemoveNote, fullWidth }) => {
  return (
    <Flex
      marginTop={spacings.medium}
      gap={spacings.small}
      flexDirection={fullWidth ? 'row' : 'column'}
      width="100%"
      flexWrap={fullWidth ? 'wrap' : 'nowrap'}
    >
      {notes?.map((note: INote, index) => (
        <Note
          key={index}
          note={note}
          onRemoveNote={onRemoveNote}
          index={index}
        />
      ))}
    </Flex>
  );
};

const ChipsList: FC<{
  options: Option[];
  selectedValues: string[];
  customOptions?: boolean;
  onRemoveChip?: (value: string, index: number) => void;
  onAddChip?: (value: string) => void;
  disabled?: boolean;
}> = ({
  options,
  onRemoveChip,
  selectedValues,
  customOptions,
  onAddChip,
  disabled
}) => {
  const { t } = useTranslation();

  const selectedChipsToRender: Option[] = selectedValues.map((value) => {
    return {
      value,
      label: t(
        (options.find(({ value: currValue }) => value === currValue)
          ?.label as string) || value
      )
    };
  });

  return (
    <>
      {(customOptions ? options : selectedChipsToRender).map(
        ({ label, value }, index) =>
          typeof label === 'string' ? (
            <Chip
              key={`${label} - ${index}`}
              label={label}
              deleteIcon={onRemoveChip && <ClearIcon />}
              onDelete={() => {
                onRemoveChip?.(value, index);
              }}
              disabled={disabled}
            />
          ) : (
            <ClickableBox
              key={`${label} - ${index}`}
              width="100%"
              onClick={() => {
                onAddChip?.(value);
              }}
            >
              {label}
            </ClickableBox>
          )
      )}
    </>
  );
};

const ChipsInput: FC<ChipsInputProps> = ({
  value,
  chipsInputPlaceholder,
  chipsInputLabel,
  onAddChip,
  onRemoveChip,
  options = [],
  variant = ChipsVariants.DEFAULT,
  closeModal,
  customOptions,
  allowAddingNewOptions,
  onSearch,
  chipsInputSx = { height: '400px', overflow: 'auto' },
  id,
  ...props
}) => {
  const { t } = useTranslation();
  const { shouldSortOptions: shouldSortOptionsProp } =
    props as ChipSortOptionsProps;

  return (
    <StyledAddChipContainer
      variant={variant}
      shadow={variant === ChipsVariants.DEFAULT}
      sx={chipsInputSx}
    >
      <StyledAddModalHeader variant={variant}>
        <Typography>
          {chipsInputLabel || i18n.t('ADD_CHIPS_CONTAINER_TITLE')}
        </Typography>
        <MiniIconButton icon={<CloseIcon />} onClick={() => closeModal()} />
      </StyledAddModalHeader>
      <Autocomplete<Option | string>
        multiple
        id={id}
        freeSolo={allowAddingNewOptions || customOptions}
        value={value}
        disablePortal
        filterSelectedOptions
        getOptionLabel={(option: Option) => {
          const { label, value } = option;
          return (
            (typeof label === 'string' ? t(label) : (label as string)) || value
          );
        }}
        shouldSortOptions={shouldSortOptionsProp}
        renderTags={() => false}
        disableClearable
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(
          event,
          value: Option[],
          reason: AutocompleteChangeReason
        ) => {
          if (reason !== 'removeOption') {
            const optionToAdd = value?.pop();
            const optionValue = getOptionValue(optionToAdd);
            onAddChip?.(optionValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={chipsInputPlaceholder || ''}
            margin="normal"
            onChange={(ev) => {
              if (customOptions) {
                onSearch?.(ev.target.value);
              } else {
                params.inputProps.onChange(ev as ChangeEvent<HTMLInputElement>);
              }
            }}
            fullWidth
            inputProps={{
              ...params.inputProps,
              id: 'chips-text-input'
            }}
            InputProps={{
              ...params.InputProps,

              endAdornment: allowAddingNewOptions ? null : (
                <InputAdornment position="end">
                  <IconButton
                    bgColor="cupid"
                    iconSize="xsmall"
                    icon={<SearchOutlined />}
                  />
                </InputAdornment>
              )
            }}
          />
        )}
        options={customOptions ? [] : options}
      />
      <StyledChipsContainer
        padding={spacings.medium}
        value={value}
        variant={variant}
      >
        {variant === ChipsVariants.NOTES ? (
          <NotesList notes={value as INote[]} onRemoveNote={onRemoveChip} />
        ) : (
          <ChipsList
            options={options}
            selectedValues={value as string[]}
            onRemoveChip={onRemoveChip}
            customOptions={customOptions}
            onAddChip={onAddChip}
          />
        )}
      </StyledChipsContainer>
    </StyledAddChipContainer>
  );
};

const Chips: FC<ChipsProps> = ({
  value,
  title,
  titleComponent,
  chipsInputLabel,
  chipsInputPlaceholder,
  options,
  onAddChip,
  onRemoveChip,
  onOpen,
  onClose,
  variant = ChipsVariants.DEFAULT,
  customAddButton,
  customOptions,
  allowAddingNewOptions,
  renderSelectedOptionsOutside,
  onSearch,
  closeOnSelection,
  helperText,
  open,
  chipsInputSx,
  isLoading = false,
  id,
  disabled = false,
  ...props
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const openModalRef = useRef<HTMLElement>();

  const { shouldSortOptions } = props as ChipSortOptionsProps;

  const extendedOptions = useMemo(
    () => options?.filter(isOptionExtended),
    [options]
  );

  const sortedOptions = useMemo(
    () =>
      shouldSortOptions
        ? extendedOptions
            ?.map((item) => item)
            .sort((a, b) => a.labelText.localeCompare(b.labelText))
        : options,
    [extendedOptions, options, shouldSortOptions]
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (isPopoverOpen) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    if (open) {
      setIsPopoverOpen(true);
    }
  }, [open]);

  useEffect(() => {
    const handleEscape = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  //The popover is used to add chips, hence if the onAddChip is not provided, no need to toggle it
  const isPopoverToggleable = !!onAddChip;

  return (
    <RelativeFlex height="100%" flexDirection="column">
      <Flex alignItems="center" gap={spacings.medium}>
        {title ? (
          <StyledChipsLabel label={title} />
        ) : titleComponent ? (
          titleComponent
        ) : null}
        {isLoading ? (
          <Loader size={iconSizes.small} />
        ) : (
          (variant === ChipsVariants.DEFAULT ||
            variant === ChipsVariants.NOTES) &&
          (customAddButton ? (
            <StyledAddButtonContainer
              alignItems="center"
              ref={openModalRef}
              pointer={!!onAddChip}
              disabled={disabled}
              onClick={() => {
                if (isPopoverToggleable) {
                  setIsPopoverOpen(!isPopoverOpen);
                }
              }}
            >
              {customAddButton}
            </StyledAddButtonContainer>
          ) : (
            <Box ref={openModalRef}>
              <MiniIconButton
                disabled={disabled}
                icon={<AddIcon />}
                onClick={() => {
                  setIsPopoverOpen(!isPopoverOpen);
                }}
              />
            </Box>
          ))
        )}
      </Flex>
      <StyledChipsContainer value={value} variant={variant}>
        {renderSelectedOptionsOutside && (
          <ChipsList
            options={sortedOptions}
            selectedValues={value as string[]}
            onAddChip={onAddChip}
            onRemoveChip={onRemoveChip}
            disabled={disabled}
          />
        )}
      </StyledChipsContainer>
      {helperText && (
        <Typography
          id={id ? `${id}-chips-input` : undefined}
          fontSize={10}
          color={Colors.alizarinCrimson}
        >
          {helperText}
        </Typography>
      )}
      {variant === ChipsVariants.EXPANDED && (
        <ChipsInput
          {...props}
          id={id ? `${id}-chips-input` : undefined}
          closeModal={() => setIsPopoverOpen(false)}
          value={value}
          chipsInputSx={chipsInputSx}
          chipsInputLabel={chipsInputLabel}
          chipsInputPlaceholder={chipsInputPlaceholder}
          onAddChip={onAddChip}
          onRemoveChip={onRemoveChip}
          options={sortedOptions}
          variant={variant}
          customOptions={customOptions}
          allowAddingNewOptions={allowAddingNewOptions}
          onSearch={onSearch}
        />
      )}
      <Popover
        open={isPopoverOpen}
        anchorEl={openModalRef?.current}
        onClose={() => setIsPopoverOpen(false)}
        disableScrollLock
        hideToolbar
        PaperProps={{
          sx: {
            overflow: 'visible'
          }
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <ChipsInput
          {...props}
          closeModal={() => setIsPopoverOpen(false)}
          value={value}
          chipsInputSx={chipsInputSx}
          chipsInputLabel={chipsInputLabel}
          chipsInputPlaceholder={chipsInputPlaceholder}
          onAddChip={(value) => {
            onAddChip(value);

            if (closeOnSelection) {
              setIsPopoverOpen(false);
            }
          }}
          onRemoveChip={onRemoveChip}
          options={sortedOptions}
          variant={variant}
          customOptions={customOptions}
          allowAddingNewOptions={allowAddingNewOptions}
          onSearch={onSearch}
        />
      </Popover>
    </RelativeFlex>
  );
};

export default Chips;
