import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { fontWeights } from 'src/components/styles/fonts';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { spacings } from 'src/components/styles/constants';
import Icd10Selector from '../patients/common/Icd10Selector';
import { EncounterDiagnosis } from 'src/types/encounter';
import { Icd10Code } from 'src/types/codes';

const ASCII_CODE_FOR_A = 65;
const MAX_DIAGNOSES = 12;

interface DiagnosticsSelectorProps {
  onChange: (params: {
    diagnoses: Array<EncounterDiagnosis | null>;
    indexChanged?: number;
    isDeleting?: boolean;
  }) => void | Promise<void>;
  values: Array<EncounterDiagnosis | null>;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const encounterDiagnosisToICD10Code = (
  diagnosis: EncounterDiagnosis
): Icd10Code => {
  return {
    code: diagnosis.icd10Code,
    description: diagnosis.description,
    label: diagnosis.description
  };
};

interface DiagnosisBoxProps {
  index: number;
  value: Icd10Code | null;
  onChange: (diagnosis: Icd10Code | null) => void | Promise<void>;
  onDelete: () => void | Promise<void>;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const getNewLetterFromIndex = (index: number): string =>
  String.fromCharCode(ASCII_CODE_FOR_A + index);

const DiagnosisBox: FC<DiagnosisBoxProps> = ({
  index,
  value,
  onChange,
  onDelete,
  error,
  helperText,
  disabled = false
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap={spacings.small}
    >
      <Typography
        width={spacings.large}
        variant="h4"
        fontWeight={fontWeights.extraBold}
      >
        {getNewLetterFromIndex(index) + '.'}
      </Typography>
      <Icd10Selector
        disabled={disabled}
        error={error}
        helperText={helperText}
        onChange={onChange}
        value={value}
      />
      <Flex justifyContent="center" flexDirection="column">
        <MiniIconButton
          onClick={onDelete}
          icon={<RemoveIcon />}
          iconColor="emperor"
          bgColor="alto"
          disabled={index === 0 || disabled}
        />
      </Flex>
    </Flex>
  );
};

const DiagnosticsSelector: FC<DiagnosticsSelectorProps> = ({
  onChange,
  values,
  error,
  helperText,
  disabled
}) => {
  const { t } = useTranslation();

  const handleChange = (newDiagnosis: Icd10Code, index: number): void => {
    const newValues: EncounterDiagnosis[] = [...values];
    newValues[index] = newDiagnosis
      ? {
          icd10Code: newDiagnosis.code,
          description: newDiagnosis.description,
          label: getNewLetterFromIndex(index),
          index
        }
      : null;
    onChange({
      diagnoses: newValues,
      indexChanged: index
    });
  };

  const handleDelete = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange({
      diagnoses: newValues,
      indexChanged: index,
      isDeleting: true
    });
  };

  return (
    <Flex flexDirection="column">
      <Flex gap={spacings.large} alignItems="center">
        <Typography variant="h4" fontWeight={fontWeights.extraBold}>
          {t('DIAGNOSTICS').toLocaleUpperCase()}
        </Typography>
        <MiniIconButton
          onClick={() => {
            onChange({ diagnoses: [...values, null] });
          }}
          disabled={values.length >= MAX_DIAGNOSES || disabled}
          icon={<AddIcon />}
          iconColor="emperor"
          bgColor="alto"
        />
      </Flex>
      <Flex gap={spacings.medium} paddingTop={spacings.xlarge} flexWrap="wrap">
        {values.map((diagnosis, index) => {
          return (
            <DiagnosisBox
              key={`diagnosis-selector-${index}`}
              index={index}
              disabled={disabled}
              value={
                diagnosis ? encounterDiagnosisToICD10Code(diagnosis) : null
              }
              onChange={(newDiagnosis) => handleChange(newDiagnosis, index)}
              onDelete={() => handleDelete(index)}
              error={!diagnosis && error}
              helperText={!diagnosis && error ? helperText : null}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DiagnosticsSelector;
