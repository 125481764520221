import { FC } from 'react';
import Box from '../../components/layout/Box';
import Flex from '../../components/layout/Flex';
import Typography from '../../components/display/Typography';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DoctorChips } from '../patients/common/DoctorChips';
import { FlagActivityButton } from './FlagActivityButton';
import { spacings } from '../../components/styles/constants';
import { longDateTimeFormat } from '../../utils/dateAndTIme';
import { FeedActivity } from 'src/types/feed';

interface ActivityDetailsProps {
  activity: FeedActivity;
}

export const ActivityDetails: FC<ActivityDetailsProps> = ({ activity }) => {
  const { t } = useTranslation();
  const { eventType, name, updatedAt, updatedByStaff, id, isFlagged, summary } =
    activity;

  return (
    <Box flex={1} minWidth={500} maxWidth={800} minHeight={150}>
      <>
        <Flex gap={spacings.x3large}>
          <Box flex={4}>
            <Flex gap={spacings.medium} marginBottom={spacings.medium}>
              <Typography variant="h3">{t(eventType).toUpperCase()}</Typography>
              <Typography variant="h3">|</Typography>
              <Typography variant="h3">{t(name).toUpperCase()}</Typography>
            </Flex>
            <Typography>
              {dayjs(updatedAt).format(longDateTimeFormat)}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="h4" noWrap>
              {t('UPDATED_BY').toUpperCase()}
            </Typography>
            <DoctorChips
              id="activity-created-by-doctor"
              value={[updatedByStaff]}
              showSelectedValue
            />
          </Box>
          <Box flex={1}>
            <Typography variant="h4" noWrap>
              {t('FLAG').toUpperCase()}
            </Typography>
            <FlagActivityButton activityId={id} isFlagged={isFlagged} />
          </Box>
        </Flex>

        <Flex flexDirection="column" gap={spacings.medium}>
          <Typography variant="h3">{t('SUMMARY').toUpperCase()}</Typography>
          <Box width="100%" maxHeight={300} overflow="auto">
            <Typography whiteSpace="pre-wrap">{summary}</Typography>
          </Box>
        </Flex>
      </>
    </Box>
  );
};
