import { GridFilterModel } from '@mui/x-data-grid-premium';
import { AxiosResponse } from 'axios';

import { convertTaskServerToClient } from 'src/modules/patients/utils/conversions';
import { CycleTreatmentWitness } from 'src/types/cycle';
import {
  AddChecklistTemplateType,
  ChecklistTemplate,
  Task
} from 'src/types/task';
import { TaskServer } from 'src/types/patient-server';
import { PaginatedData } from 'src/types/pagination';
import axiosApiInstance from 'src/utils/axios';
import { dashSeparatedDateFormat } from 'src/utils/dateAndTIme';
import { withQueryParams } from 'src/utils/pagination';

export const updatePatientWitnessedTaskRequest = async ({
  patientId,
  witnessedTask
}: {
  patientId: string;
  witnessedTask: CycleTreatmentWitness;
}): Promise<{ witnessedTask: CycleTreatmentWitness; patientId: string }> => {
  return { patientId, witnessedTask };
};

export async function createTaskRequest(task: TaskServer): Promise<string> {
  const response = await axiosApiInstance.post<TaskServer>(
    '/tasks/staff',
    task
  );
  const { data } = response;
  return `${data.patientId}`;
}

export async function deleteTaskRequest(
  taskId: string,
  patientId: string
): Promise<string> {
  await axiosApiInstance.delete(`/tasks/${taskId}/patients/${patientId}`);
  return taskId;
}
export async function updateTaskRequest(task: TaskServer): Promise<string> {
  const response = await axiosApiInstance.patch<any>(
    `/tasks/${task.id}/staff`,
    task
  );
  const { data } = response;
  return data._id;
}

export const getTasksByStaffId = async (staffId: string): Promise<Task[]> => {
  const response = await axiosApiInstance.get<TaskServer[]>(
    `/tasks/staff/${staffId}`
  );
  const { data } = response;
  return data.map((task) => convertTaskServerToClient(task));
};

const getTasksByStaffPatientId = async (
  queryParams: URLSearchParams,
  staffId: string,
  patientId: string,
  todaysTasks = false,
  filters?: GridFilterModel
): Promise<PaginatedData<Task>> => {
  const paramsString = new URLSearchParams({
    todaysTasks: todaysTasks.toString()
  }).toString();
  const params = paramsString ? `?${paramsString}` : '';

  const response: AxiosResponse<PaginatedData<TaskServer>> =
    await axiosApiInstance.post<PaginatedData<TaskServer>>(
      `/tasks/staff/${staffId}/patient/${patientId}${params}`,
      { filters },
      {
        params: queryParams
      }
    );
  const data: PaginatedData<Task> = {
    ...response.data,
    rows: response.data.rows.map((task) => convertTaskServerToClient(task))
  };

  return data;
};

export const getTasksPaginatedByStaffPatientIdRequest = withQueryParams(
  getTasksByStaffPatientId
);

export const getChecklistTemplatesRequest = async (
  patientId: string
): Promise<ChecklistTemplate[]> => {
  const response = await axiosApiInstance.get<ChecklistTemplate[]>(
    `tasks/templates/patients/${patientId}`
  );
  return response.data;
};

export const addChecklistTemplateRequest = async ({
  dueDate,
  ...rest
}: AddChecklistTemplateType): Promise<Task[]> => {
  const response = await axiosApiInstance.post<TaskServer[]>(
    'tasks/staff/templates',
    {
      ...rest,
      dueDate: dueDate ? dueDate.format(dashSeparatedDateFormat) : null
    }
  );
  const { data } = response;
  return data.map((task) => convertTaskServerToClient(task));
};

const getTasksRequest = async (
  queryParams?: URLSearchParams,
  patientId?: string,
  filters?: GridFilterModel,
  showDone = false
): Promise<PaginatedData<Task>> => {
  const params = new URLSearchParams();

  if (patientId) {
    params.append('patientId', patientId.toString());
  }

  params.append('showDone', showDone.toString());

  const paramsString = params.toString();
  const queryString = paramsString ? `?${paramsString}` : '';

  const response: AxiosResponse<PaginatedData<TaskServer>> =
    await axiosApiInstance.post<PaginatedData<TaskServer>>(
      `/tasks${queryString}`,
      { filters },
      {
        params: queryParams
      }
    );

  const data: PaginatedData<Task> = {
    ...response.data,
    rows: response.data.rows.map((task) => convertTaskServerToClient(task))
  };

  return data;
};

export const getTasksPaginatedRequest = withQueryParams(getTasksRequest);
