import { ChangeEvent, FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';

import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import TextArea from 'src/components/data-entry/TextArea/TextArea';
import {
  agglutinationEntries,
  appearanceEntries,
  liquefactionEntries,
  polarityEntries,
  SemenAnalysisReportForm,
  typeOfAgglutinationEntries,
  viscosityEntries
} from 'src/types/exam';
import {
  calcMotileSpermCount,
  calcProgressiveMotileSpermCount,
  calcTotalMotile,
  calcProgressiveMotile,
  calcTotalSpermCount
} from './calcSpermMotility';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

export const ResultInitialSample: FC<{
  control: Control<SemenAnalysisReportForm, any, SemenAnalysisReportForm>;
  errors: FieldErrors<SemenAnalysisReportForm>;
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}> = ({ control, errors, getValues, setValue }) => {
  const { t } = useTranslation();

  const appearanceOptions = appearanceEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const liquefactionOptions = liquefactionEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const viscosityOptions = viscosityEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const agglutinationOptions = agglutinationEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const typeOfAgglutinationOptions = typeOfAgglutinationEntries.map(
    ([key, value]) => ({
      label: t(key),
      value
    })
  );

  const onChangeVolume = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(ev);
    calcTotalSpermCount({ getValues, setValue });
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
  };

  const onChangeConcentration = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(ev);
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalSpermCount({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  const onChangeProgressiveMotileA = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(ev);
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalMotile({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  const onChangeProgressiveMotileB = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(ev);
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalMotile({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  return (
    <>
      <Box marginBottom={spacings.medium}>
        <Typography
          marginBottom={spacings.large}
          variant="h2"
          fontWeight={fontWeights.extraBold}
        >
          {t('RESULTS_INITIAL_SAMPLE')}
        </Typography>
      </Box>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="appearance"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('APPEARANCE')}
                inputRef={ref}
                error={!!errors?.appearance}
                helperText={errors?.appearance?.message}
                defaultOption={t('APPEARANCE')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={appearanceOptions}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="liquefaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('LIQUEFACTION')}
                inputRef={ref}
                error={!!errors?.liquefaction}
                helperText={errors?.liquefaction?.message}
                defaultOption={t('LIQUEFACTION')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={liquefactionOptions}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="viscosity"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('VISCOSITY')}
                inputRef={ref}
                error={!!errors?.viscosity}
                helperText={errors?.viscosity?.message}
                defaultOption={t('VISCOSITY')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={viscosityOptions}
              />
            )}
          />
        </Box>
        <Box flex={1} />
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="volume"
            control={control}
            rules={{
              min: { value: 0, message: t('VOLUME_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('VOLUME').toUpperCase()} (${t('ML')})`}
                placeholder={t('VOLUME')}
                error={!!errors?.volume}
                helperText={errors?.volume?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('ML')}</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
                onChange={(ev) => onChangeVolume(ev, onChange)}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="concentration"
            control={control}
            rules={{
              min: { value: 0, message: t('CONCENTRATION_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => onChangeConcentration(ev, onChange)}
                label={`${t('CONCENTRATION').toUpperCase()} (${t('M/ML')})`}
                placeholder={t('CONCENTRATION')}
                error={!!errors?.concentration}
                helperText={errors?.concentration?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="totalSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('TOTAL_SPERM_COUNT').toUpperCase()} (${t(
                  'M/EJAC'
                )})`}
                placeholder={t('TOTAL_SPERM_COUNT')}
                error={!!errors?.totalSpermCount}
                helperText={errors?.totalSpermCount?.message}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="normalMorphology"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('NORMAL_MORPHOLOGY_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('NORMAL_MORPHOLOGY').toUpperCase()}`}
                placeholder={t('NORMAL_MORPHOLOGY')}
                error={!!errors?.normalMorphology}
                helperText={errors?.normalMorphology?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="progressiveMotileA"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('A_PROGRESSIVE_MOTILE_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => onChangeProgressiveMotileA(ev, onChange)}
                label={`${t('A_PROGRESSIVE_MOTILE').toUpperCase()}`}
                placeholder={t('A_PROGRESSIVE_MOTILE')}
                error={!!errors?.progressiveMotileA}
                helperText={errors?.progressiveMotileA?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="progressiveMotileB"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('B_NON_PROGRESSIVE_MOTILE_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => onChangeProgressiveMotileB(ev, onChange)}
                label={`${t('B_NON_PROGRESSIVE_MOTILE').toUpperCase()}`}
                placeholder={t('B_NON_PROGRESSIVE_MOTILE')}
                error={!!errors?.progressiveMotileB}
                helperText={errors?.progressiveMotileB?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="progressiveMotileC"
            control={control}
            rules={{
              min: { value: 0, message: t('C_NON_MOTILE_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => {
                  onChange(ev);
                  calcTotalMotile({ getValues, setValue });
                  calcMotileSpermCount({ getValues, setValue });
                }}
                label={`${t('C_NON_MOTILE').toUpperCase()}`}
                placeholder={t('C_NON_MOTILE')}
                error={!!errors?.progressiveMotileC}
                helperText={errors?.progressiveMotileC?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="immotileD"
            control={control}
            rules={{
              min: { value: 0, message: t('D_IMMOTILE_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => {
                  onChange(ev);
                  calcTotalMotile({ getValues, setValue });
                }}
                label={`${t('D_IMMOTILE').toUpperCase()}`}
                placeholder={t('D_IMMOTILE')}
                error={!!errors?.immotileD}
                helperText={errors?.immotileD?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="progressiveMotileSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PROGRESSIVE_MOTILE_SPERM_COUNT').toUpperCase()}
                placeholder={t('PROGRESSIVE_MOTILE_SPERM_COUNT')}
                error={!!errors?.progressiveMotileSpermCount}
                helperText={errors?.progressiveMotileSpermCount?.message}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="motileSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('MOTILE_SPERM_COUNT').toUpperCase()}`}
                placeholder={t('MOTILE_SPERM_COUNT')}
                error={!!errors?.motileSpermCount}
                helperText={errors?.motileSpermCount?.message}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="totalMotile"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('TOTAL_MOTILITY').toUpperCase()}
                placeholder={t('TOTAL_MOTILITY')}
                error={!!errors?.totalMotile}
                helperText={errors?.totalMotile?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="progressiveMotility"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PROGRESSIVE_MOTILITY').toUpperCase()}
                placeholder={t('PROGRESSIVE_MOTILITY')}
                error={!!errors?.progressiveMotility}
                helperText={errors?.progressiveMotility?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="ph"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PH').toUpperCase()}
                placeholder={t('PH')}
                error={!!errors?.ph}
                helperText={errors?.ph?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0, max: 14 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="roundCell"
            control={control}
            rules={{
              min: { value: 0, message: t('ROUND_CELL_VALIDATION_MSG') }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('ROUND_CELL').toUpperCase()}`}
                placeholder={t('ROUND_CELL')}
                error={!!errors?.roundCell}
                helperText={errors?.roundCell?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="agglutination"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('AGGLUTINATION')}
                inputRef={ref}
                error={!!errors?.agglutination}
                helperText={errors?.agglutination?.message}
                defaultOption={t('AGGLUTINATION')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={agglutinationOptions}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="typeOfAgglutination"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('TYPE_OF_AGGLUTINATION')}
                inputRef={ref}
                error={!!errors?.typeOfAgglutination}
                helperText={errors?.typeOfAgglutination?.message}
                defaultOption={t('TYPE_OF_AGGLUTINATION')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={typeOfAgglutinationOptions}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="antibodyImmunoglobulinA"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('ANTIBODY_IMMUNOGLOBULIN_A').toUpperCase()}
                inputRef={ref}
                error={!!errors?.antibodyImmunoglobulinA}
                helperText={errors?.antibodyImmunoglobulinA?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={polarityEntries.map(([key, value]) => ({
                  label: t(key),
                  value
                }))}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="antibodyIgaResult"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('ANTIBODY_IGA_RESULT_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('ANTIBODY_IGA_RESULT').toUpperCase()}`}
                placeholder={'%'}
                error={!!errors?.antibodyIgaResult}
                helperText={errors?.antibodyIgaResult?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="antibodyImmunoglobulinG"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('ANTIBODY_IMMUNOGLOBULIN_G').toUpperCase()}
                inputRef={ref}
                error={!!errors?.antibodyImmunoglobulinG}
                helperText={errors?.antibodyImmunoglobulinG?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={polarityEntries.map(([key, value]) => ({
                  label: t(key),
                  value
                }))}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="antibodyIggResult"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('ANTIBODY_IGG_RESULT_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('ANTIBODY_IGA_RESULT').toUpperCase()}`}
                placeholder={'%'}
                error={!!errors?.antibodyIggResult}
                helperText={errors?.antibodyIggResult?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
      </Flex>
      <Box marginBottom={spacings.large}>
        <Controller
          name="resultsGeneralComments"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              label={t('GENERAL_COMMENTS').toUpperCase()}
              placeholder={t('GENERAL_COMMENTS')}
              error={!!errors.resultsGeneralComments}
              helperText={errors?.resultsGeneralComments?.message}
              minRows={3}
              maxRows={3}
              resize="auto"
              fullWidth
            />
          )}
        />
      </Box>
    </>
  );
};
