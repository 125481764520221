import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import Card from '../../components/display/Card';
import Typography from '../../components/display/Typography';
import Flex from '../../components/layout/Flex';
import Box from '../../components/layout/Box';
import IconButton from '../../components/display/IconButton';
import { spacings } from '../../components/styles/constants';
import Avatar from '../../components/display/Avatar';
import { getFullAddress, getFullName } from '../../utils/general';
import { UserTypes } from '../../types/user';
import useBilling from '../../hooks/useBilling';
import Loader from '../../components/display/Loader';
import { fontWeights } from '../../components/styles/fonts';
import dayjs from 'dayjs';
import { getDateFormat } from '../../utils/dateAndTIme';
import { HealthProvider } from '../../types/billing';
import { AvatarSizes } from '../../components/display/Avatar/Avatar';
import { TooltipWrapper } from '../../components/display/Tooltip';
import { useNavigate } from 'react-router-dom';
import { patientRoutes } from '../../router/routes';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';

interface PatientBillingInfoCardProps {
  patientId: string;
}

const InsuranceInfo: FC<HealthProvider> = ({
  effectiveDate,
  groupNumber,
  memberNumber,
  name
}) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap={spacings.xsmall}>
      <Typography variant="h3" fontWeight={fontWeights.extraBold}>
        {t('INSURANCE_INFO').toUpperCase()}
      </Typography>
      <Flex gap={spacings.small}>
        <Typography fontWeight={fontWeights.bold}>
          {t('HEALTH_PROVIDER')}:
        </Typography>
        <Typography>{name}</Typography>
      </Flex>
      <Flex gap={spacings.small}>
        <Typography fontWeight={fontWeights.bold}>
          {t('MEMBER_NUMBER')}:
        </Typography>
        <Typography>{memberNumber}</Typography>
      </Flex>
      <Flex gap={spacings.small}>
        <Typography fontWeight={fontWeights.bold}>
          {t('GROUP_NUMBER')}:
        </Typography>
        <Typography>{groupNumber}</Typography>
      </Flex>
      <Flex gap={spacings.small}>
        <Typography fontWeight={fontWeights.bold}>
          {t('EFFECTIVE_DATE')}:
        </Typography>
        <Typography>{dayjs(effectiveDate).format(getDateFormat())}</Typography>
      </Flex>
    </Flex>
  );
};

export const PatientBillingInfoCard: FC<PatientBillingInfoCardProps> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getBillingProfile } = useBilling();
  const { getPatientById } = usePatientsApi();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: billingProfile, isLoading: isLoadingProfile } =
    getBillingProfile(patientId);

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  if (isLoadingProfile) {
    return <Loader />;
  }

  const {
    address,
    city,
    country,
    paymentProfile,
    currency,
    healthProvider,
    secondHealthProvider
  } = billingProfile || {};

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patient?.personalInfo?.pictureId
  });

  return (
    <Card shadow paddingX={spacings.xlarge} paddingY={spacings.medium}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={spacings.large}
      >
        <Typography variant="h1">{t('PATIENT_BILLING_PROFILE')}</Typography>
        <TooltipWrapper title={t('ADD_NEW_INVOICE')} arrow placement="top">
          <Box>
            <IconButton
              onClick={() =>
                navigate(patientRoutes.getAddInvoiceLink(patientId))
              }
              bgColor="mineShaft"
              iconSize="medium"
              icon={<AddIcon />}
            />
          </Box>
        </TooltipWrapper>
      </Flex>
      <Flex gap={spacings.xlarge}>
        {!isLoadingPatient && (
          <Avatar size={AvatarSizes.LARGE} image={avatar} />
        )}
        <Flex gap={spacings.x3large}>
          <Flex flexDirection="column" gap={spacings.xsmall}>
            <Typography variant="h3" fontWeight={fontWeights.extraBold}>
              {t('BILL_TO').toUpperCase()}
            </Typography>
            <Typography variant="h3" fontWeight={fontWeights.extraBold}>
              {getFullName(billingProfile).toUpperCase()}
            </Typography>
            <Typography>
              {getFullAddress({
                country,
                city,
                address
              })}
            </Typography>
            <Flex gap={spacings.small}>
              <Typography fontWeight={fontWeights.bold}>
                {t('PAYMENT_PROFILE')}
              </Typography>
              <Typography>{paymentProfile}</Typography>
            </Flex>
            <Flex gap={spacings.small}>
              <Typography fontWeight={fontWeights.bold}>
                {t('CURRENCY')}
              </Typography>
              <Typography>{currency}</Typography>
            </Flex>
          </Flex>
          <InsuranceInfo {...healthProvider} />
          {secondHealthProvider && <InsuranceInfo {...secondHealthProvider} />}
        </Flex>
      </Flex>
    </Card>
  );
};
