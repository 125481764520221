import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Checkbox from 'src/components/data-entry/Checkbox';
import Select from 'src/components/data-entry/Select';
import Card from 'src/components/display/Card';
import Table from 'src/components/display/Table';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import {
  StaffMemberFormValues,
  StaffMemberPermissions,
  StaffMemberRoles
} from 'src/types/staff';
import { presetRoles } from './presetRoles';
import Button from 'src/components/display/Button';
import useMeApi from 'src/hooks/useMeApi';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { ColumnHeights, RowHeights } from 'src/components/display/Table/Table';
import { GridColDef } from '@mui/x-data-grid-premium';

interface LocationState {
  staff: StaffMemberFormValues;
  isAdd: boolean;
  staffId?: string;
  staffExistingPermissions?: string[];
}

interface PermissionTableRow {
  id: StaffMemberPermissions;
  permission: string;
  description: string;
  isChecked: boolean;
}
const PermissionsPage: FC = () => {
  const { t } = useTranslation();
  const { getMe } = useMeApi();
  const { data: me } = getMe();
  const navigate = useNavigate();
  const { createStaffMember, updateStaffmember } = useStaffMembers();
  const { mutate: createStaff, isLoading: isCreatingStaff } =
    createStaffMember();

  const { mutate: updateStaffMutate, isLoading: isUpdatingStaff } =
    updateStaffmember();

  const isLoading = isCreatingStaff || isUpdatingStaff;

  const location = useLocation();
  const { staff, staffExistingPermissions, isAdd, staffId } =
    location.state as LocationState;

  const [selectedRole, setSelectedRole] = useState<string>(
    staffExistingPermissions ? undefined : StaffMemberRoles.SYSTEM_ADMIN
  );

  const [staffPermissions, setStaffPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (staffExistingPermissions) {
      setStaffPermissions(staffExistingPermissions);
    }
  }, [staffExistingPermissions]);

  useEffect(() => {
    if (selectedRole) {
      const permissions = Object.keys(presetRoles[selectedRole]).filter(
        (key) => presetRoles[selectedRole][key]
      );
      setStaffPermissions(permissions);
    }
  }, [selectedRole]);

  const handleCheckPermission = (
    isChecked: boolean,
    permission: string
  ): void => {
    if (!isChecked) {
      setStaffPermissions((staffPermissions) => [
        ...staffPermissions,
        permission
      ]);
    } else {
      setStaffPermissions((staffPermissions) =>
        staffPermissions.filter((p) => p !== permission)
      );
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'permission',
        headerName: 'Permission',
        flex: 1,
        sortable: false,
        filterable: false
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 4,
        sortable: false,
        filterable: false
      },
      {
        field: 'isChecked',
        headerName: '',
        flex: 1,
        renderCell: ({ row: { isChecked, id } }) => (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheckPermission(isChecked, id)}
          />
        ),
        sortable: false,
        filterable: false
      }
    ],
    [t, staffPermissions, selectedRole]
  );

  const rows: PermissionTableRow[] = useMemo(
    () => [
      {
        id: StaffMemberPermissions.SYSTEM_ADMIN,
        permission: t('SYSTEM_ADMIN'),
        description: t('SYSTEM_ADMIN_DESCRIPTION'),
        isChecked: staffPermissions?.includes(
          StaffMemberPermissions.SYSTEM_ADMIN
        )
      },
      {
        id: StaffMemberPermissions.BILLING,
        permission: t('BILLING'),
        description: t('BILLING_DESCRIPTION'),
        isChecked: staffPermissions?.includes(StaffMemberPermissions.BILLING)
      },
      {
        id: StaffMemberPermissions.ADD_NEW_PATIENT,
        permission: t('ADD_PATIENT'),
        description: t('ADD_PATIENT_DESCRIPTION'),
        isChecked: staffPermissions?.includes(
          StaffMemberPermissions.ADD_NEW_PATIENT
        )
      },
      {
        id: StaffMemberPermissions.CLINICAL_CARE,
        permission: t('CLINICAL_CARE'),
        description: t('CLINICAL_CARE_DESCRIPTION'),
        isChecked: staffPermissions?.includes(
          StaffMemberPermissions.CLINICAL_CARE
        )
      },
      {
        id: StaffMemberPermissions.COORDINATION,
        permission: t('COORDINATION'),
        description: t('COORDINATION_DESCRIPTION'),
        isChecked: staffPermissions?.includes(
          StaffMemberPermissions.COORDINATION
        )
      },
      {
        id: StaffMemberPermissions.ACTIVITY_FEED,
        permission: t('ACTIVITY_FEED'),
        description: t('ACTIVITY_FEED_DESCRIPTION'),
        isChecked: staffPermissions?.includes(
          StaffMemberPermissions.ACTIVITY_FEED
        )
      }
    ],
    [t, selectedRole, staffPermissions]
  );

  const handleChangeRole = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedRole(event.target.value);
  };

  const saveStaffMember = async () => {
    const staffInfo: StaffMemberFormValues = {
      ...staff,
      clinicId: me?.user?.clinicId,
      permissions: staffPermissions
    };
    if (isAdd) {
      await createStaff(staffInfo, {
        onSuccess: () => {
          navigate('/app/clinic-settings/staff');
        }
      });
    } else {
      await updateStaffMutate(
        { staffId, staff: staffInfo },
        {
          onSuccess: () => {
            navigate('/app/clinic-settings/staff');
          }
        }
      );
    }
  };

  return (
    <Box paddingBottom={spacings.medium}>
      <Flex paddingBottom={spacings.large}>
        <Typography variant="h1" marginBottom={spacings.medium}>
          {t('PERMISSIONS_PAGE')}
        </Typography>
      </Flex>
      <Card shadow padding={spacings.x2large} minWidth="700px">
        <Flex gap={spacings.x3large}>
          <Flex flexDirection="column">
            <Typography>{t('PERMISSIONS_PAGE_DESCRIPTION')}</Typography>
          </Flex>
          <Flex flexDirection="column" width="50vw">
            <Typography marginBottom={spacings.large}>
              {t('PRESET_ROLE_TITLE')}
            </Typography>
            <Select
              value={selectedRole}
              onChange={handleChangeRole}
              options={Object.values(StaffMemberRoles).map((value) => ({
                label: t(value),
                value: value
              }))}
            />
          </Flex>
        </Flex>
        <Table
          hideFilterToolbar
          autoHeight
          hideFooter
          getRowHeight={() => RowHeights.md}
          columns={columns}
          rows={rows}
          loading={isLoading}
          columnHeaderHeight={ColumnHeights.md}
          paginationModel={{ pageSize: 25, page: 0 }}
        />
        <Box mt={spacings.xlarge}>
          <Button onClick={saveStaffMember} disabled={isLoading || !staff}>
            {isAdd ? t('ADD_STAFF_CTA') : t('SAVE_STAFF_CTA')}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default PermissionsPage;
