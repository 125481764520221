import { Core } from '@pdftron/webviewer';
import dayjs from 'dayjs';
import { longDateTimeMsFormat } from 'src/utils/dateAndTIme';

export const getDocumentAsPdfFileFromEditor = async ({
  documentViewer,
  annotationManager,
  templateName,
  key,
  originalFileName,
  flatten
}: {
  documentViewer: Core.DocumentViewer;
  annotationManager: Core.AnnotationManager;
  templateName: string;
  key: string;
  originalFileName?: string;
  flatten?: boolean;
}): Promise<{ file: File; xfdfString: string }> => {
  const doc = documentViewer.getDocument();
  const xfdfString = await annotationManager.exportAnnotations();

  const data = await doc.getFileData({
    xfdfString,
    includeAnnotations: true,
    downloadType: 'pdf',
    flatten
  });
  // change the extension of the file to pdf
  const newName = originalFileName?.replace(/(\.[^.]+)?$/, '.pdf');

  const arr = new Uint8Array(data);
  const blob = new Blob([arr], { type: 'application/pdf' });
  const documentWithoutTemplateName = key.split('/')?.pop();

  const fileNameToSet =
    newName ||
    `${
      templateName || documentWithoutTemplateName || 'no_named_document'
    }-${dayjs().format(longDateTimeMsFormat)}.pdf`;

  const file = new File([blob], fileNameToSet, {
    type: 'application/pdf'
  });

  return { file, xfdfString };
};
