import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';

import { TasksTable } from 'src/modules/tasksTable/TasksTable';
import { SelectPatient } from 'src/modules/tasksTable/SelectPatient';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Card from 'src/components/display/Card/Card';
import { usePopover } from 'src/components/components-api/PopoverProvider';
import MiniIconButton from 'src/components/display/MiniIconButton';
import PatientNotesTable from './PatientNotesTable/PatientNotesTable';
import Typography from 'src/components/display/Typography';
import useTasks from 'src/hooks/useTasks';
import useDebounce from 'src/hooks/useDebounce';
import { paginationDefaultParams } from 'src/constants';
import { PatientCareTeam } from '../details/PatientCareTeam';
import { PatientLastLogin } from '../medicalHistory/PatientLastLogin';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import { AddChecklistMenu } from './AddChecklistMenu';
import { PatientDiagnosisDetails } from './PatientDiagnosis';
import { PatientOverviewCard } from './PatientOverviewCard';
import { PatientTreatmentHistoryTable } from './PatientTreatmentHistoryTable';
import { ScheduleCard } from './ScheduleCard';

export const PatientOverview: FC = () => {
  const [paginationModel, setPaginationModel] = useState(
    paginationDefaultParams
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And
  });

  const { t } = useTranslation();
  const { patientId } = useParams();
  const { openPopover } = usePopover();
  const { getPatientTasks } = useTasks();

  const debouncedFilterModel = useDebounce(filterModel);

  const {
    data: tasks,
    isFetching: isTasksFetching,
    isLoading: isTasksLoading
  } = getPatientTasks({
    patientId,
    params: {
      pagination: paginationModel
    },
    filters: debouncedFilterModel
  });

  const handleFilterChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel]
  );

  const rows = tasks?.rows ?? [];
  const totalRowCount = tasks?.count ?? 0;

  const isLoading = isTasksLoading || isTasksFetching;

  return (
    <Box>
      <Helmet>
        <title>{t('PATIENT_OVERVIEW_PAGE_TITLE')}</title>
      </Helmet>
      <Flex flexDirection="column" maxWidth="100%" gap={spacings.x2large}>
        <Flex
          flexDirection={{
            xs: 'column',
            lg: 'row'
          }}
          alignItems="stretch"
          gap={spacings.x2large}
          minWidth="100%"
        >
          <Box flex={1.5}>
            <Box marginBottom={spacings.large} width="100%">
              <PatientFlags patientId={patientId} />
            </Box>
            <PatientOverviewCard />
          </Box>

          <Flex
            flexDirection="column"
            flex="1"
            minWidth="450px"
            gap={spacings.large}
          >
            <PatientLastLogin patientId={patientId} />
            <PatientCareTeam patientId={patientId} />
            <ScheduleCard />
          </Flex>
        </Flex>
        <Flex
          flexDirection={{
            xs: 'column',
            md: 'row'
          }}
          alignItems="stretch"
          gap={spacings.x2large}
          minWidth="100%"
        >
          <Box flex={1.5} paddingBottom={spacings.x2large}>
            <Card padding={spacings.large} height="100%" shadow>
              <PatientDiagnosisDetails patientId={patientId} />
            </Card>
          </Box>
          <Box flex={1} paddingBottom={spacings.x2large} minWidth="450px">
            <Card padding={spacings.large} height="100%" shadow>
              <PatientTreatmentHistoryTable />
            </Card>
          </Box>
        </Flex>
        <Box width="100%">
          <PatientNotesTable patientId={patientId} />
        </Box>
        <Box width="100%">
          <TasksTable
            pageSize={10}
            tasks={rows || []}
            loading={isLoading}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            filterModel={filterModel}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            rowCount={totalRowCount}
            toolbar={
              <Flex
                gap={spacings.large}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Flex gap={spacings.large} alignItems="center">
                  <Typography variant="h2">{t('TASKS')}</Typography>
                  <MiniIconButton
                    id="add-tasks-button"
                    icon={<AddIcon />}
                    onClick={(ev) =>
                      openPopover({
                        children: (
                          <AddChecklistMenu
                            patientId={patientId}
                            anchorEl={ev.currentTarget}
                          />
                        ),
                        anchorEl: ev.currentTarget,
                        hideToolbar: true,
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'bottom'
                        },
                        transformOrigin: {
                          horizontal: 'right',
                          vertical: 'top'
                        }
                      })
                    }
                  />
                </Flex>
                <SelectPatient
                  patientId={patientId}
                  filterModel={filterModel}
                  onChange={handleFilterChange}
                />
              </Flex>
            }
          />
        </Box>
      </Flex>
    </Box>
  );
};
