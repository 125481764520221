import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import convert from 'convert-units';
import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Switch from 'src/components/data-entry/Switch/Switch';
import Button from 'src/components/display/Button/Button';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import Box, { BoxProps } from 'src/components/layout/Box/Box';
import Checkbox from 'src/components/data-entry/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { Basics, PersonSex } from 'src/types/patient';
import { getHeightCmFromFtIn, getHeightFtInFromCm } from 'src/utils/general';
import { IrregularMenstrual } from 'src/types/cycle';

interface BasicsFormProps extends BoxProps {
  basics: Basics;
  onSuccess?: () => void;
  invalidatePartnerId?: string;
  sex: string;
}

export const BasicsForm: FC<BasicsFormProps> = ({
  basics: {
    cycle,
    height: defaultHeightCm,
    ttcMonths,
    ttcYears,
    weight: defaultWeightKg,
    patientId
  },
  onSuccess,
  invalidatePartnerId,
  sex,
  ...otherProps
}) => {
  const { updatePatientProperties } = usePatientsApi();
  const [isMetric, setIsMetric] = useLocalStorage('isMetric', false);

  const { mutate: updateProperties, isLoading: isUpdating } =
    updatePatientProperties();

  const { heightFt: defaultHeightFt, heightIn: defaultHeightIn } =
    getHeightFtInFromCm(defaultHeightCm);
  const { t } = useTranslation();
  const { control, formState, handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      patientId,
      cycle,
      weight: defaultWeightKg,
      ttcMonths,
      ttcYears,
      heightFt: defaultHeightFt,
      heightIn: defaultHeightIn,
      height: defaultHeightCm,
      weightLb: +convert(defaultWeightKg).from('kg').to('lb').toFixed(1)
    }
  });
  const { errors } = formState;
  const {
    cycle: cycleLength,
    height,
    heightFt,
    heightIn,
    weightLb,
    weight
  } = watch();

  useEffect(() => {
    if (isMetric) {
      const { heightFt, heightIn } = getHeightFtInFromCm(height);

      setValue('heightFt', +heightFt.toFixed(0));
      setValue('heightIn', +(heightIn % 12).toFixed(0));
    }
  }, [height, isMetric]);

  useEffect(() => {
    if (!isMetric)
      setValue('height', +getHeightCmFromFtIn(heightFt, heightIn).toFixed(1));
  }, [heightFt, heightIn, isMetric]);

  useEffect(() => {
    if (isMetric) {
      setValue('weightLb', +convert(weight).from('kg').to('lb').toFixed(2));
    }
  }, [weight, isMetric]);

  useEffect(() => {
    if (!isMetric) {
      setValue('weight', +convert(weightLb).from('lb').to('kg').toFixed(2));
    }
  }, [weightLb, isMetric]);

  const onSubmit = async (details: Basics) => {
    await updateProperties({
      patientId,
      patientProperties: {
        ...details,
        weight: Math.round(details.weight),
        height: Math.round(details.height)
      },
      invalidatePartnerId
    });
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <Box {...otherProps}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={spacings.large}
      >
        <Typography>{t('CHOOSE_METRIC_SYSTEM')}</Typography>
        <Switch
          switchOnText={t('CM_KG')}
          switchOffText={t('FT_LB')}
          checked={isMetric}
          onChange={(_, checked) => setIsMetric(checked)}
        />
      </Flex>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          alignItems="flex-start"
          gap={spacings.large}
          marginBottom={spacings.large}
          flexDirection={isMetric ? 'row' : 'column'}
        >
          <Flex width="100%" gap={spacings.x2large}>
            {isMetric ? (
              <Controller
                name="height"
                key="height"
                control={control}
                rules={{
                  required: t('PLEASE_ENTER_A_HEIGHT')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    onBlur={(ev) => {
                      const value = ev.target.value;
                      field.onChange(Number(value).toFixed(1));
                    }}
                    label={t('HEIGHT').toUpperCase()}
                    type="number"
                    inputProps={{
                      step: 0.1,
                      min: 0
                    }}
                    error={!!errors?.height}
                    helperText={errors?.height?.message}
                    required
                    fullWidth
                  />
                )}
              />
            ) : (
              <Flex flex="1" gap={spacings.large}>
                <Controller
                  name="heightFt"
                  key="heightFt"
                  control={control}
                  rules={{
                    required: t('PLEASE_ENTER_A_HEIGHT')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('HEIGHT_FT').toUpperCase()}
                      type="number"
                      onBlur={(ev) => {
                        const value = ev.target.value;
                        field.onChange(Number(value).toFixed(0));
                      }}
                      inputProps={{
                        step: 1,
                        min: 0
                      }}
                      error={!!errors?.heightFt}
                      helperText={errors?.heightFt?.message}
                      required
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="heightIn"
                  key="heightIn"
                  control={control}
                  rules={{
                    required: t('PLEASE_ENTER_A_HEIGHT')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('HEIGHT_IN').toUpperCase()}
                      type="number"
                      onBlur={(ev) => {
                        const value = ev.target.value;
                        field.onChange(Number(value).toFixed(0));
                      }}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 11
                      }}
                      error={!!errors?.heightIn}
                      helperText={errors?.heightIn?.message}
                      required
                      fullWidth
                    />
                  )}
                />
              </Flex>
            )}
          </Flex>

          {isMetric ? (
            <Controller
              name="weight"
              key="weight"
              control={control}
              rules={{
                required: t('PLEASE_ENTER_A_WEIGHT')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('WEIGHT').toUpperCase()}
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0
                  }}
                  onBlur={(ev) => {
                    const value = ev.target.value;
                    field.onChange(Number(value).toFixed(2));
                  }}
                  error={!!errors?.weight}
                  helperText={errors?.weight?.message}
                  required
                  fullWidth
                />
              )}
            />
          ) : (
            <Controller
              name="weightLb"
              key="weightLb"
              control={control}
              rules={{
                required: t('PLEASE_ENTER_A_WEIGHT')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('WEIGHT').toUpperCase()}
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 0
                  }}
                  onBlur={(ev) => {
                    const value = ev.target.value;
                    field.onChange(Number(value).toFixed(2));
                  }}
                  error={!!errors?.weightLb}
                  helperText={errors?.weightLb?.message}
                  required
                  fullWidth
                />
              )}
            />
          )}
        </Flex>
        {sex === PersonSex.FEMALE && (
          <>
            <InputLabel label={t('HOW_LONG_HAVE_YOU_BEEN_TTC')} />
            <Flex
              alignItems="flex-end"
              gap={spacings.large}
              marginBottom={spacings.large}
            >
              <Controller
                name="ttcYears"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    error={!!errors.ttcYears}
                    helperText={errors?.ttcYears?.message}
                    defaultOption="Years"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    options={Object.entries(Array.from(Array(11))).map(
                      (_, index) => ({
                        label: index,
                        value: `${index}`
                      })
                    )}
                  />
                )}
              />
              <Controller
                name="ttcMonths"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    error={!!errors.ttcMonths}
                    helperText={errors?.ttcMonths?.message}
                    defaultOption="Months"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    options={Object.entries(Array.from(Array(12))).map(
                      (_, index) => ({
                        label: index,
                        value: `${index}`
                      })
                    )}
                  />
                )}
              />
            </Flex>
            <>
              <InputLabel label={t('AVERAGE_MENSTRUAL_CYCLE_LENGTH')} />
              <Flex
                alignItems="center"
                gap={spacings.x2large}
                marginBottom={spacings.large}
              >
                <Box flex={1}>
                  <Controller
                    name="cycle"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        disabled={
                          cycleLength === IrregularMenstrual.IRREGULAR_CYCLE
                        }
                        {...field}
                        inputRef={ref}
                        type="number"
                        placeholder="#"
                        error={!!errors.ttcMonths}
                        helperText={errors?.ttcMonths?.message}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Checkbox
                    checked={cycleLength === IrregularMenstrual.IRREGULAR_CYCLE}
                    onChange={(_, checked) => {
                      if (checked) {
                        setValue('cycle', IrregularMenstrual.IRREGULAR_CYCLE);
                      } else {
                        setValue('cycle', null);
                      }
                    }}
                    label="Irregular"
                  />
                </Box>
              </Flex>
            </>
          </>
        )}
        <Flex justifyContent="center">
          <Button disabled={isUpdating} type="submit">
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
