import dayjs from 'dayjs';
import {
  ExamReport,
  Examination,
  OtherExaminationTypes,
  OneTwoThree
} from 'src/types/exam';
import { validateIsNumberOrStringNumber } from './general';
import { formatDateToString } from 'src/modules/patients/utils/conversions';

enum ExaminationFields {
  CYCLE_ID = 'cycleId',
  PATIENT_ID = 'patientId',
  PERFORMEDBY_ID = 'performedById',
  EXAM_DATE = 'examDate',
  ENDOMETRIAL_BIOPSY = 'endometrialThickness',
  OBSERVATIONS = 'observations',
  LEFT_FOLLICLES = 'folliclesSizeLeft',
  RIGHT_FOLLICLES = 'folliclesSizeRight',
  AFC_LEFT = 'afcLeft',
  AFC_RIGHT = 'afcRight',
  IMAGES = 'images',
  NOTES = 'notes',
  REASON = 'reason',
  TYPE = 'type',
  GENERAL_REMARKS = 'generalRemarks',
  MEDICATIONS_ADMINISTERED = 'medicationsAdministered',
  SWAB_COUNT_CORRECT = 'correctSwabCount',
  UNCOMPLICATED_PROCEDURE = 'procedureUncompleted',
  WITNESSED_BY = 'witnessedBy',
  ORDER_DATE = 'orderDate',
  DATE_OF_RETRIEVAL = 'dateOfRetrieval',
  EGGS_INSIMATED = 'eggsInsimated',
  EGGS_MATURE = 'eggsMature',
  EGGS_RETRIEVED = 'eggsRetrieved',
  BLADDER_STATUS = 'bladderStatus',
  CATHETER = 'catheter',
  EASE_OF_TRANSFER = 'easeOfTransfer',
  FLASH_SEEN = 'flashSeen',
  NO_OF_EMBRYOS_TRANSFERRED = 'noOfEmbryosTransferred',
  POSITION_OF_UTERUS = 'positionOfUterus',
  TA_SCAN = 'taScan',
  TA_SCAN_FINDINGS = 'taScanFindings',
  CERVIX_QUALITY = 'cervixQuality',
  EASE_OF_IUI = 'easeOfIUI',
  IMAGES_TO_DELETE = 'imagesToDelete',
  CTRL = 'ctrl',
  FETAL_HEART_BEAT = 'fetalHeartbeat',
  SEMEN_ANALYSIS = 'semenAnalysis',
  PHYSICAL_EXAM_FEMALE = 'physicalExamFemale',
  EMBRYOS_TRANSFERRED = 'embryosTransferred',
  EMBRYOS_TRANSFERRED_DELETED = 'embryosTransferredDeleted',
  SIGNED_OFF_BY = 'signedOffBy',
  PERFORMED_BY = 'performedBy',
  REQUESTED_PHYSICIAN = 'requestedPhysician',
  MEDICATION_IDS = 'medicationIds',
  FINDINGS = 'findings',
  IS_NON_COMPLIANT_ET = 'isNonCompliantET',
  NON_COMPLIANT_ET_REASON = 'nonCompliantETReason'
}

export const getExaminationFormData = ({
  patientId,
  performedById,
  requestingPhysicianId,
  signedOffById,
  cycleId,
  dateOfExamination,
  endometrial,
  observations,
  leftFollicles,
  rightFollicles,
  afcLeft,
  afcRight,
  images,
  notes,
  reason,
  type,
  generalRemarks,
  medicationsAdministered,
  swabCountCorrect,
  uncomplicatedProcedure,
  witnessedBy,
  orderDate,
  dateOfRetrieval,
  eggsInsimated,
  eggsMature,
  eggsRetrieved,
  bladderStatus,
  catheter,
  easeOfTransfer,
  flashSeen,
  noOfEmbryosTransferred,
  positionOfUterus,
  taScan,
  taScanFindings,
  cervixQuality,
  easeOfIUI,
  imagesToDelete,
  embryosTransferred,
  ctrl,
  fetalHeartbeat,
  semenAnalysis: { ...semenAnalysis } = {},
  physicalExamFemale,
  embryosTransferredDeleted,
  nonCompliantETReason,
  isNonCompliantET
}: ExamReport): FormData => {
  const findings = [...(observations || []), ...(taScanFindings || [])];
  const formData = new FormData();

  if (cycleId) {
    formData.append(ExaminationFields.CYCLE_ID, cycleId);
  }
  if (patientId) {
    formData.append(ExaminationFields.PATIENT_ID, patientId);
  }
  if (dateOfExamination) {
    formData.append(
      ExaminationFields.EXAM_DATE,
      formatDateToString(dateOfExamination)
    );
  }
  if (orderDate) {
    formData.append(
      ExaminationFields.ORDER_DATE,
      formatDateToString(orderDate)
    );
  }
  if (reason) {
    formData.append(ExaminationFields.REASON, reason);
  }
  if (type) {
    formData.append(ExaminationFields.TYPE, type);
  }
  if (signedOffById) {
    formData.append(ExaminationFields.SIGNED_OFF_BY, signedOffById);
  }
  if (performedById) {
    formData.append(ExaminationFields.PERFORMED_BY, `${performedById}`);
  }
  if (requestingPhysicianId) {
    formData.append(
      ExaminationFields.REQUESTED_PHYSICIAN,
      `${requestingPhysicianId}`
    );
  }
  if (witnessedBy) {
    formData.append(ExaminationFields.WITNESSED_BY, `${witnessedBy}`);
  }
  if (medicationsAdministered) {
    medicationsAdministered.forEach((medication) =>
      formData.append(ExaminationFields.MEDICATION_IDS, medication)
    );
  }
  if (generalRemarks?.length) {
    generalRemarks.forEach((remark) =>
      formData.append(ExaminationFields.GENERAL_REMARKS, remark)
    );
  }

  // Ultrasound report
  if (validateIsNumberOrStringNumber(afcLeft)) {
    formData.append(ExaminationFields.AFC_LEFT, `${afcLeft}`);
  }
  if (validateIsNumberOrStringNumber(afcRight)) {
    formData.append(ExaminationFields.AFC_RIGHT, `${afcRight}`);
  }
  if (rightFollicles) {
    rightFollicles.map((size) =>
      formData.append(ExaminationFields.RIGHT_FOLLICLES, `${size}`)
    );
  }
  if (leftFollicles) {
    leftFollicles.map((size) =>
      formData.append(ExaminationFields.LEFT_FOLLICLES, `${size}`)
    );
  }
  if (validateIsNumberOrStringNumber(endometrial)) {
    formData.append(ExaminationFields.ENDOMETRIAL_BIOPSY, `${endometrial}`);
  }
  if (findings?.length) {
    findings?.forEach((finding) =>
      formData.append(ExaminationFields.FINDINGS, finding)
    );
  }
  if (notes) {
    formData.append(ExaminationFields.NOTES, notes);
  }
  if (fetalHeartbeat) {
    formData.append(ExaminationFields.FETAL_HEART_BEAT, `${fetalHeartbeat}`);
  }
  if (ctrl) {
    formData.append(ExaminationFields.CTRL, `${ctrl}`);
  }

  //Egg retrieval
  if (swabCountCorrect) {
    formData.append(ExaminationFields.SWAB_COUNT_CORRECT, swabCountCorrect);
  }
  if (uncomplicatedProcedure) {
    formData.append(
      ExaminationFields.UNCOMPLICATED_PROCEDURE,
      uncomplicatedProcedure
    );
  }
  if (images?.length) {
    images?.forEach((image) =>
      formData.append(ExaminationFields.IMAGES, image)
    );
  }
  if (imagesToDelete?.length) {
    imagesToDelete?.forEach((imageId) =>
      formData.append(ExaminationFields.IMAGES_TO_DELETE, imageId)
    );
  }

  //Embryology
  if (dateOfRetrieval) {
    formData.append(
      ExaminationFields.DATE_OF_RETRIEVAL,
      dateOfRetrieval?.toDateString()
    );
  }
  if (validateIsNumberOrStringNumber(eggsInsimated)) {
    formData.append(ExaminationFields.EGGS_INSIMATED, `${eggsInsimated}`);
  }
  if (validateIsNumberOrStringNumber(eggsMature)) {
    formData.append(ExaminationFields.EGGS_MATURE, `${eggsMature}`);
  }
  if (validateIsNumberOrStringNumber(eggsRetrieved)) {
    formData.append(ExaminationFields.EGGS_RETRIEVED, `${eggsRetrieved}`);
  }

  //Embryo transfer
  if (bladderStatus) {
    formData.append(ExaminationFields.BLADDER_STATUS, bladderStatus);
  }
  if (catheter) {
    formData.append(ExaminationFields.CATHETER, catheter);
  }
  if (easeOfTransfer) {
    formData.append(ExaminationFields.EASE_OF_TRANSFER, easeOfTransfer);
  }
  if (flashSeen) {
    formData.append(ExaminationFields.FLASH_SEEN, flashSeen);
  }
  if (noOfEmbryosTransferred) {
    let numberOfEmbryos: number;
    switch (noOfEmbryosTransferred) {
      case OneTwoThree.THREE:
        numberOfEmbryos = 3;
        break;
      case OneTwoThree.TWO:
        numberOfEmbryos = 2;
        break;
      case OneTwoThree.ONE:
      default:
        numberOfEmbryos = 1;
        break;
    }
    formData.append(
      ExaminationFields.NO_OF_EMBRYOS_TRANSFERRED,
      `${numberOfEmbryos}`
    );
  }
  if (embryosTransferred?.length) {
    embryosTransferred.map((eggId) =>
      formData.append(ExaminationFields.EMBRYOS_TRANSFERRED, eggId)
    );
  }
  if (embryosTransferredDeleted?.length) {
    embryosTransferredDeleted.map((eggId) =>
      formData.append(ExaminationFields.EMBRYOS_TRANSFERRED_DELETED, eggId)
    );
  }
  if (positionOfUterus) {
    formData.append(ExaminationFields.POSITION_OF_UTERUS, positionOfUterus);
  }
  if (taScan) {
    formData.append(ExaminationFields.TA_SCAN, taScan);
  }
  if (isNonCompliantET === true || isNonCompliantET === false) {
    formData.append(
      ExaminationFields.IS_NON_COMPLIANT_ET,
      `${isNonCompliantET}`
    );
  }
  if (nonCompliantETReason) {
    formData.append(
      ExaminationFields.NON_COMPLIANT_ET_REASON,
      nonCompliantETReason
    );
  }

  // IUI
  if (easeOfIUI) {
    formData.append(ExaminationFields.EASE_OF_IUI, easeOfIUI);
  }
  if (cervixQuality) {
    formData.append(ExaminationFields.CERVIX_QUALITY, cervixQuality);
  }

  // SEMEN ANALYSIS
  if (semenAnalysis) {
    formData.append(
      ExaminationFields.SEMEN_ANALYSIS,
      JSON.stringify(semenAnalysis)
    );
  }

  // PHYSICAL ANALYSIS FEMALE
  if (physicalExamFemale) {
    formData.append(
      ExaminationFields.PHYSICAL_EXAM_FEMALE,
      JSON.stringify(physicalExamFemale)
    );
  }

  return formData;
};

export const getExaminationDate = (examination: Examination): Date => {
  switch (examination.type) {
    case OtherExaminationTypes.EMBRYOLOGY:
      return examination.dateOfRetrieval
        ? dayjs(examination.dateOfRetrieval).toDate()
        : null;
    case OtherExaminationTypes.SPERM_ANALYSIS:
    case OtherExaminationTypes.SPERM_ANALYSIS_DIAGNOSTIC:
    case OtherExaminationTypes.SPERM_ANALYSIS_IN_CYCLE:
      return examination.semenAnalysis?.dateProduced
        ? dayjs(examination.semenAnalysis?.dateProduced).toDate()
        : null;
    default:
      return examination.examDate ? dayjs(examination.examDate).toDate() : null;
  }
};
