import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { SemenAnalysisReportForm } from 'src/types/exam';

export const calcMotileSpermCount = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}) => {
  const {
    progressiveMotileA,
    progressiveMotileB,
    progressiveMotileC,
    concentration,
    volume,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB,
    spermPrepProgressiveMotileC,
    spermPrepConcentration,
    spermPrepVolume
  } = getValues();

  if (
    !isNaN(progressiveMotileA) &&
    !isNaN(progressiveMotileB) &&
    !isNaN(progressiveMotileC) &&
    !isNaN(concentration) &&
    !isNaN(volume)
  ) {
    setValue(
      'motileSpermCount',
      parseFloat(
        (
          ((progressiveMotileA + progressiveMotileB + progressiveMotileC) *
            concentration *
            volume) /
          100
        ).toFixed(2)
      )
    );
  } else {
    setValue('motileSpermCount', 0);
  }

  if (
    !isNaN(spermPrepProgressiveMotileA) &&
    !isNaN(spermPrepProgressiveMotileB) &&
    !isNaN(spermPrepProgressiveMotileC) &&
    !isNaN(spermPrepConcentration) &&
    !isNaN(spermPrepVolume)
  ) {
    setValue(
      'spermPrepMotileSpermCount',
      parseFloat(
        (
          ((spermPrepProgressiveMotileA +
            spermPrepProgressiveMotileB +
            spermPrepProgressiveMotileC) *
            spermPrepConcentration *
            spermPrepVolume) /
          100
        ).toFixed(2)
      )
    );
  } else {
    setValue('spermPrepMotileSpermCount', 0);
  }
};

export const calcProgressiveMotileSpermCount = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}) => {
  const {
    progressiveMotileA,
    progressiveMotileB,
    concentration,
    volume,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB,
    spermPrepConcentration,
    spermPrepVolume
  } = getValues();

  if (
    !isNaN(progressiveMotileA) &&
    !isNaN(progressiveMotileB) &&
    !isNaN(concentration) &&
    !isNaN(volume)
  ) {
    setValue(
      'progressiveMotileSpermCount',
      parseFloat(
        (
          ((progressiveMotileA + progressiveMotileB) * concentration * volume) /
          100
        ).toFixed(2)
      )
    );
  } else {
    setValue('progressiveMotileSpermCount', 0);
  }

  if (
    !isNaN(spermPrepProgressiveMotileA) &&
    !isNaN(spermPrepProgressiveMotileB) &&
    !isNaN(spermPrepConcentration) &&
    !isNaN(spermPrepVolume)
  ) {
    setValue(
      'spermPrepProgressiveMotileSpermCount',
      parseFloat(
        (
          ((spermPrepProgressiveMotileA + spermPrepProgressiveMotileB) *
            spermPrepConcentration *
            spermPrepVolume) /
          100
        ).toFixed(2)
      )
    );
  } else {
    setValue('spermPrepProgressiveMotileSpermCount', 0);
  }
};

export const calcTotalMotile = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}) => {
  const {
    progressiveMotileA,
    progressiveMotileB,
    progressiveMotileC,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB,
    spermPrepProgressiveMotileC
  } = getValues();

  if (
    !isNaN(progressiveMotileA) &&
    !isNaN(progressiveMotileB) &&
    !isNaN(progressiveMotileC)
  ) {
    setValue(
      'totalMotile',
      progressiveMotileA + progressiveMotileB + progressiveMotileC
    );
  } else {
    setValue('totalMotile', 0);
  }

  if (
    !isNaN(spermPrepProgressiveMotileA) &&
    !isNaN(spermPrepProgressiveMotileB) &&
    !isNaN(spermPrepProgressiveMotileC)
  ) {
    setValue(
      'spermPrepTotalMotile',
      spermPrepProgressiveMotileA +
        spermPrepProgressiveMotileB +
        spermPrepProgressiveMotileC
    );
  } else {
    setValue('spermPrepTotalMotile', 0);
  }
};

export const calcProgressiveMotile = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}) => {
  const {
    progressiveMotileA,
    progressiveMotileB,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB
  } = getValues();

  if (!isNaN(progressiveMotileA) && !isNaN(progressiveMotileB)) {
    setValue('progressiveMotility', progressiveMotileA + progressiveMotileB);
  } else {
    setValue('progressiveMotility', 0);
  }

  if (
    !isNaN(spermPrepProgressiveMotileA) &&
    !isNaN(spermPrepProgressiveMotileB)
  ) {
    setValue(
      'spermPrepProgressiveMotility',
      spermPrepProgressiveMotileA + spermPrepProgressiveMotileB
    );
  } else {
    setValue('spermPrepProgressiveMotility', 0);
  }
};

export const calcTotalSpermCount = ({
  getValues,
  setValue
}: {
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}) => {
  const { volume, concentration, spermPrepVolume, spermPrepConcentration } =
    getValues();

  if (!isNaN(volume) && !isNaN(concentration)) {
    setValue(
      'totalSpermCount',
      parseFloat((volume * concentration).toFixed(2))
    );
  } else {
    setValue('totalSpermCount', 0);
  }

  if (!isNaN(spermPrepVolume) && !isNaN(spermPrepConcentration)) {
    setValue(
      'spermPrepTotalSpermCount',
      parseFloat((spermPrepVolume * spermPrepConcentration).toFixed(2))
    );
  } else {
    setValue('spermPrepTotalSpermCount', 0);
  }
};
