import { AppError } from 'src/types/global';
import {
  Encounter,
  EncounterCodeRow,
  EncounterInsuranceInfo,
  EncounterLockStatus,
  UpdateEncounterCodeRowsRequestDto
} from './../types/encounter';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/components-api/ToastProvider';
import {
  generateEncounterActionsTakenRequest,
  getEncounterByAppointmentIdRequest,
  getEncounterCodeRowsByAppointmentIdRequest,
  getEncounterInsuranceInfoRequest,
  updateEncounterLockStatusRequest,
  updateEncounterCodeRowsRequest,
  updateEncounterRequest,
  generateEncounterResultsRequest
} from 'src/api/encounters.api';
import { queryKeys, querySubKeys } from './queryKeys';

function useEncounters() {
  const { handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();

  return {
    getEncounterByAppointmentId: (
      appointmentId: string,
      options?: UseQueryOptions<Encounter, AppError>
    ) =>
      useQuery<Encounter, AppError>(
        [
          queryKeys.ENCOUNTERS,
          querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
          appointmentId
        ],
        () => getEncounterByAppointmentIdRequest(appointmentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_ENCOUNTER')
            }),
          ...options
        }
      ),
    getEncounterCodeRowsByAppointmentId: (appointmentId: string) =>
      useQuery<EncounterCodeRow[], AppError>(
        [
          queryKeys.ENCOUNTERS,
          querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
          appointmentId,
          querySubKeys[queryKeys.ENCOUNTERS].CODE_ROWS
        ],
        () => getEncounterCodeRowsByAppointmentIdRequest(appointmentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CODE_ROWS')
            })
        }
      ),
    updateEncounterRows: () =>
      useMutation<void, AppError, UpdateEncounterCodeRowsRequestDto>(
        (updateEncounterDto: UpdateEncounterCodeRowsRequestDto) =>
          updateEncounterCodeRowsRequest(updateEncounterDto),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_ENCOUNTER_CODE_ROWS')
            }),
          onSuccess: (_, variables: UpdateEncounterCodeRowsRequestDto) => {
            queryClient.invalidateQueries([
              queryKeys.ENCOUNTERS,
              querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
              variables.appointmentId
            ]);
            closeDialog();
          }
        }
      ),
    updateEncounter: () =>
      useMutation<
        void,
        AppError,
        Partial<Encounter> & { isLockingAfterSave?: boolean }
      >(
        ({ isLockingAfterSave, ...encounter }) =>
          updateEncounterRequest(encounter, isLockingAfterSave),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_ENCOUNTER')
            }),
          onSuccess: (_, variables: Partial<Encounter>) => {
            queryClient.invalidateQueries([
              queryKeys.ENCOUNTERS,
              querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
              variables.appointmentId
            ]);
          }
        }
      ),
    getEncounterInsuranceInfo: (appointmentId: string) =>
      useQuery<EncounterInsuranceInfo, AppError>(
        [
          queryKeys.ENCOUNTERS,
          querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
          appointmentId,
          querySubKeys[queryKeys.ENCOUNTERS].INSURANCE
        ],
        () => getEncounterInsuranceInfoRequest(appointmentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_ENCOUNTER_INSURANCE')
            })
        }
      ),
    generateEncounterActionsTaken: (encounterId: string, patientId: string) =>
      useQuery<string, AppError>(
        [
          queryKeys.ENCOUNTERS,
          encounterId,
          querySubKeys[queryKeys.ENCOUNTERS].PATIENTS,
          patientId,
          querySubKeys[queryKeys.ENCOUNTERS].ACTIONS_TAKEN
        ],
        () => generateEncounterActionsTakenRequest(encounterId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GENERATE_ENCOUNTER_ACTIONS_TAKEN')
            }),
          enabled: false
        }
      ),
      generateEncounterResultsRequest: (encounterId: string, patientId: string) =>
      useQuery<string, AppError>(
        [
          queryKeys.ENCOUNTERS,
          encounterId,
          querySubKeys[queryKeys.ENCOUNTERS].PATIENTS,
          patientId,
          querySubKeys[queryKeys.ENCOUNTERS].RESULTS
        ],
        () => generateEncounterResultsRequest(encounterId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GENERATE_ENCOUNTER_RESULTS')
            }),
          enabled: false
        }
      ),
    updateEncounterLockStatus: () =>
      useMutation<
        Encounter,
        AppError,
        {
          encounterId: string;
          newStatus: EncounterLockStatus;
          patientId: string;
        }
      >((data) => updateEncounterLockStatusRequest(data), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_TOGGLE_ENCOUNTER_LOCK_STATUS')
          }),
        onSuccess: (variables: Partial<Encounter>) => {
          queryClient.invalidateQueries([
            queryKeys.ENCOUNTERS,
            querySubKeys[queryKeys.ENCOUNTERS].APPOINTMENT,
            variables.appointmentId
          ]);
          queryClient.invalidateQueries([
            queryKeys.FEED,
            variables.patientId,
            querySubKeys[queryKeys.FEED].ACTIVITIES
          ]);
        }
      })
  };
}

export default useEncounters;
