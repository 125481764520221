import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { LabReport, TestNameTypes } from 'src/types/appointment';
import Loader from 'src/components/display/Loader/Loader';
import { getFullName } from 'src/utils/general';
import dayjs from 'dayjs';
import usePatientsApi from '../hooks/usePatientsApi';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import useLabResults from 'src/hooks/useLabResults';

export const AddEditLabsReportForm: FC<{
  patientId: string;
  cycleId: string;
}> = ({ patientId, cycleId }) => {
  const { getPatientById } = usePatientsApi();
  const { createLabsReport } = useLabResults();
  const { closeDialog } = useDialog();

  const { t } = useTranslation();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { mutate: createReport, isLoading: isCreatingReport } =
    createLabsReport();

  const defaultValues: LabReport = {
    patientId,
    cycleId,
    patientName: '',
    date: dayjs().toDate(),
    results: [
      {
        testId: '13',
        testName: TestNameTypes.ESTRADIOL,
        value: null,
        measurementType: ''
      },
      {
        testId: '31',
        testName: TestNameTypes.PROGESTERONE,
        value: null,
        measurementType: ''
      },
      {
        testId: '27',
        testName: TestNameTypes.LH,
        value: null,
        measurementType: ''
      },
      {
        testId: '18',
        testName: TestNameTypes.FSH,
        value: null,
        measurementType: ''
      }
    ],
    comment: ''
  };

  const { control, formState, handleSubmit, setValue } = useForm<LabReport>({
    mode: 'onChange',
    defaultValues
  });

  const { fields: results } = useFieldArray({
    control,
    name: 'results'
  });

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const { errors } = formState;

  const onSubmit = async (report: LabReport) => {
    await createReport(report, {
      onSettled: (data, error) => {
        if (!error) {
          closeDialog();
        }
      }
    });
  };

  if (isLoadingPatient) return <Loader />;

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Box marginBottom={spacings.medium}>
          <Controller
            name="patientName"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                disabled
                label={t('PATIENT_NAME')}
                placeholder={t('PATIENT_NAME')}
                error={!!errors.patientName}
                helperText={errors?.patientName?.message}
                required
                fullWidth
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            name="date"
            control={control}
            rules={{
              required: t('DATE_REQUIRED'),
              validate: (value) => {
                const date = dayjs(value);
                if (date.isAfter(dayjs())) {
                  return t('DATE_CANT_BE_IN_THE_FUTURE');
                }
                return true;
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <DatePicker
                {...field}
                label={t('DATE_TAKEN')}
                inputRef={ref}
                error={!!errors?.date}
                helperText={errors.date?.message}
                fullWidth
              />
            )}
          />
        </Box>
        {results.map((result, index) => (
          <Flex
            key={`result-${index}`}
            gap={spacings.medium}
            marginBottom={spacings.medium}
          >
            <Controller
              name={`results.${index}.testName`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  disabled
                  value={t(field.value)}
                  inputRef={ref}
                  label={index === 0 ? t('TEST_NAME').toUpperCase() : ''}
                  placeholder={t('TEST_NAME')}
                  error={!!errors.results?.[index]?.testName}
                  helperText={errors.results?.[index]?.testName?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name={`results.${index}.value`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={index === 0 ? t('RESULTS').toUpperCase() : ''}
                  placeholder={t('RESULTS')}
                  error={!!errors.results?.[index]?.value}
                  helperText={errors.results?.[index]?.value?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name={`results.${index}.measurementType`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={index === 0 ? t('MEASUREMENT_TYPE').toUpperCase() : ''}
                  placeholder={t('MEASUREMENT_TYPE')}
                  error={!!errors.results?.[index]?.measurementType}
                  helperText={errors.results?.[index]?.measurementType?.message}
                  fullWidth
                />
              )}
            />
          </Flex>
        ))}
        <Flex
          justifyContent="center"
          paddingX={spacings.x2large}
          marginTop={spacings.large}
          width="50%"
          marginX="auto"
        >
          <Button
            disabled={isCreatingReport}
            onClick={handleSubmit(onSubmit)}
            fullWidth
            type="button"
          >
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
