import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { StaffMemberFormValues } from 'src/types/staff';
import Flex from 'src/components/layout/Flex';
import InputField from 'src/components/data-entry/InputField';
import Button from 'src/components/display/Button';
import { StyledDatePicker } from '../patients/editPatient/EditPatient';
import { PERMISSIONS } from 'src/router/routes';

const defaultValues: StaffMemberFormValues = {
  firstName: '',
  lastName: '',
  role: null,
  email: '',
  clinicId: '',
  jobTitle: '',
  address: '',
  phone: '',
  dateOfBirth: null,
  city: '',
  state: '',
  zip: null,
  country: '',
  licenseNumber: '',
  displayName: '',
  connectedToCareTeam: null,
  permissions: [],
  npi: null
};

const AddEditStaffMemberForm: FC = () => {
  const { t } = useTranslation();
  const { staffId } = useParams();
  const navigate = useNavigate();

  const { handleSubmit, control, formState, reset, watch } =
    useForm<StaffMemberFormValues>({
      mode: 'onChange',
      defaultValues
    });

  const formValues = watch();
  const { getStaffMemberById } = useStaffMembers();

  const isAdd = !staffId;

  const { data: staff } = getStaffMemberById(staffId, {
    enabled: !isAdd
  });

  useEffect(() => {
    if (staff) {
      reset({
        firstName: staff.firstName || '',
        lastName: staff.lastName || '',
        role: staff.role || null,
        email: staff.email || '',
        jobTitle: staff.jobTitle || '',
        address: staff.address || null,
        phone: staff.phone || null,
        dateOfBirth: staff.dateOfBirth || null,
        city: staff.city || null,
        state: staff.state || null,
        zip: staff.zip || null,
        country: staff.country || null,
        licenseNumber: staff.licenseNumber || null,
        displayName: staff.displayName || null,
        npi: staff.npi || null
      });
    }
  }, [staff]);

  const onSubmit = () => {
    navigate(`/app/clinic-settings/staff/${PERMISSIONS}`, {
      state: {
        staff: formValues,
        staffExistingPermissions: staff?.permissions,
        isAdd,
        staffId
      }
    });
  };

  const { errors } = formState;

  return (
    <Box width="60%" paddingBottom="10px">
      <Typography variant="h1" marginBottom={spacings.large} display="flex">
        {t(isAdd ? 'ADD_NEW_STAFF' : 'EDIT_STAFF')}
      </Typography>
      <Card shadow padding="48px" sx={{ minWidth: '700px' }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              paddingInlineEnd: spacings.medium,
              marginBottom: spacings.x2large
            }}
          >
            <Typography variant="h2">{t('STAFF_DETAILS')}</Typography>
            <Flex
              sx={{ gap: spacings.xlarge, marginBottom: spacings.medium }}
              marginTop={spacings.xlarge}
            >
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: t('STAFF_NAME_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('FIRST_NAME_LABEL')}
                    placeholder={t('FIRST_NAME_PLACEHOLDER')}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: t('STAFF_NAME_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    label={t('LAST_NAME_LABEL')}
                    placeholder={t('LAST_NAME_PLACEHOLDER')}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    required
                    fullWidth
                    inputRef={ref}
                  />
                )}
              />
            </Flex>
            <Flex
              sx={{ gap: spacings.xlarge, marginBottom: spacings.medium }}
              marginTop={spacings.xlarge}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t('STAFF_EMAIL_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('EMAIL_LABEL')}
                    placeholder={t('EMAIL_PLACEHOLDER')}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                    fullWidth
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('PHONE_NUMBER_LABEL')}
                    placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
            <Flex sx={{ gap: spacings.xlarge, marginBottom: spacings.medium }}>
              <Controller
                name="address"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('ADDRESS').toUpperCase()}
                    sx={{ flex: 1 }}
                    placeholder={t('ADDRESS_PLACEHOLDER')}
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    fullWidth
                  />
                )}
              />
              <Flex gap={spacings.small} sx={{ flex: 1 }}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <InputField
                      {...field}
                      value={value || ''}
                      inputRef={ref}
                      sx={{ flex: 1.5 }}
                      label={t('CITY_LABEL')}
                      placeholder={t('CITY_PLACEHOLDER')}
                      error={!!errors.city}
                      helperText={errors?.city?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <InputField
                      {...field}
                      value={value || ''}
                      inputRef={ref}
                      sx={{ flex: 1 }}
                      label={t('STATE_LABEL')}
                      placeholder={t('STATE_PLACEHOLDER')}
                      error={!!errors.state}
                      helperText={errors?.state?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <InputField
                      {...field}
                      value={value || ''}
                      inputRef={ref}
                      sx={{ flex: 1 }}
                      label={t('ZIP_LABEL')}
                      placeholder={t('ZIP_PLACEHOLDER')}
                      error={!!errors.zip}
                      helperText={errors?.zip?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <InputField
                      {...field}
                      value={value || ''}
                      inputRef={ref}
                      sx={{ flex: 1 }}
                      label={t('COUNTRY_LABEL')}
                      placeholder={t('COUNTRY_PLACEHOLDER')}
                      error={!!errors.country}
                      helperText={errors?.country?.message}
                      fullWidth
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Flex sx={{ gap: spacings.xlarge, marginBottom: spacings.medium }}>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <StyledDatePicker
                    {...field}
                    inputRef={ref}
                    label={t('DATE_OF_BIRTH_LABEL').toUpperCase()}
                    error={!!errors.dateOfBirth}
                    helperText={errors?.dateOfBirth?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
          </Box>

          <Box
            sx={{
              paddingInlineEnd: spacings.medium,
              marginBottom: spacings.x2large
            }}
          >
            <Typography variant="h2">{t('LICENSE_INFO')}</Typography>
            <Flex
              sx={{
                gap: spacings.xlarge,
                marginBottom: spacings.medium,
                marginTop: spacings.medium
              }}
            >
              <Controller
                name="licenseNumber"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('LICENSE_NUMBER_LABEL')}
                    error={!!errors.licenseNumber}
                    placeholder={t('LICENSE_NUMBER_PLACEHOLDER')}
                    helperText={errors?.licenseNumber?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="npi"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('NPI_LABEL')}
                    error={!!errors.npi}
                    helperText={errors?.npi?.message}
                    placeholder={t('NPI_PLACEHOLDER')}
                    fullWidth
                  />
                )}
              />
            </Flex>
          </Box>

          <Box
            sx={{
              paddingInlineEnd: spacings.medium,
              marginBottom: spacings.x2large
            }}
          >
            <Typography variant="h2">
              {t('STAFF_DASHBOARD_SETTINGS')}
            </Typography>
            <Flex
              sx={{
                gap: spacings.xlarge,
                marginBottom: spacings.medium,
                marginTop: spacings.medium
              }}
            >
              <Controller
                name="jobTitle"
                control={control}
                rules={{
                  required: t('STAFF_TITLE_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('JOB_TITLE_LABEL')}
                    placeholder={t('JOB_TITLE_PLACEHOLDER')}
                    error={!!errors.jobTitle}
                    helperText={errors?.jobTitle?.message}
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="displayName"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <InputField
                    {...field}
                    value={value || ''}
                    inputRef={ref}
                    label={t('DISPLAY_NAME_LABEL')}
                    placeholder={t('DISPLAY_NAME_PLACEHOLDER')}
                    error={!!errors.displayName}
                    helperText={errors?.displayName?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
          </Box>
          <Box mt={spacings.xlarge}>
            <Button type="submit">{t('NEXT')}</Button>
          </Box>
        </form>
      </Card>
    </Box>
  );
};

export default AddEditStaffMemberForm;
