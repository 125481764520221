import { getNumericValue } from 'src/utils/general';
import {
  CreateExternalOrderResponse,
  RequestRange
} from '../types/appointment';
import { handleCriticalAppError } from 'src/utils/handleCriticalAppError';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  EstrodialHistory,
  Patient,
  PatientKpisResponse,
  MedicationTypes,
  PatientOverview,
  DashboardSummary,
  PatientPersonalInfo,
  PatientHistory,
  PatientAllergies,
  Basics,
  PatientIdentificationInfo,
  PatientDiagnosis,
  NextOfKin,
  GeneralPhysician,
  PatientDiagnosisResult
} from 'src/types/patient';
import {
  createPatientRequest,
  getPatientById,
  getPatientsRequest,
  getPatientKpisRequest,
  getPatientGameteReportRequest,
  getPatientEstrodialHistoryRequest,
  updatePatientFlagsRequest,
  updatePatientMedicationTreatmentRequest,
  getPatientOverviewRequest,
  getDashboardSummaryRequest,
  updatePatientRequest,
  getMedicationsRequest,
  getPatientHistoryRequest,
  updatePatientAllergiesInfoRequest,
  getTelehealthLinkRequest,
  updatePatientPropertiesRequest,
  updatePatientIdentificationInfoRequest,
  getLabTestsRequest,
  resendPatientConfirmationEmailRequest,
  createDiagnosisRequest,
  getPatientDiagnosisRequest,
  updatePatientNextOfKinInfoRequest,
  createOrderRequest,
  updatePatientGeneralPhysicianInfoRequest,
  getStaffMemberByIdRequest,
  getLabOrderRequest,
  getMedicationsRxRequest,
  resendWelcomeEmailRequest
} from '../api/patients.api';
import {
  ExternalOrderFormServer,
  PatientServer
} from 'src/types/patient-server';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import { AppError } from 'src/types/global';
import { StaffMember } from 'src/types/staff';
import { updatePatientWitnessedTaskRequest } from 'src/api/tasks.api';
import {
  CycleGraphData,
  CycleTreatmentWitness,
  GameteReport
} from 'src/types/cycle';
import {
  getCycleAvailableGameteRequest,
  getPatientTreatmentsDataRequest
} from 'src/api/cycle.api';
import { Medication, MedicationRx } from 'src/types/prescription';
import { LabOrder, LabTest, Panel } from 'src/types/appointment';
import { getPanelsRequest } from 'src/api/appointment.api';
import { AvailableGamete } from 'src/types/eggAndEmbryo';
import { useContext } from 'react';
import { CriticalAppErrorContext } from 'src/contexts/CriticalAppErrorContext';
import OpenExternalOrderDocuments from 'src/modules/patients/actionMenu/OpenExternalOrderDocuments';
import { dateRangeQuerySubkey, queryKeys, querySubKeys } from './queryKeys';

function usePatientsApi() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { openToast, handleQueryResultToast } = useToast();
  const { closeDialog, openDialog } = useDialog();
  const { setCriticalAppError } = useContext(CriticalAppErrorContext);

  return {
    getPatients: (options?: UseQueryOptions<PatientPersonalInfo[], AppError>) =>
      useQuery<PatientPersonalInfo[], AppError>(
        [queryKeys.PATIENTS],
        getPatientsRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENTS')
            }),

          ...options
        }
      ),
    getPatientById: (
      patientId: string,
      options?: UseQueryOptions<Patient, AppError>
    ) =>
      useQuery<Patient, AppError>(
        [queryKeys.PATIENTS, patientId],
        () => getPatientById(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT')
            }),

          enabled: options?.enabled || !!patientId,
          ...options
        }
      ),
    getPatientOverview: (
      patientId: string,
      options?: UseQueryOptions<PatientOverview, AppError>
    ) =>
      useQuery<PatientOverview, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].OVERVIEW
        ],
        () => getPatientOverviewRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_OVERVIEW')
            }),
          onError: (error) => {
            handleCriticalAppError(error, setCriticalAppError);
          },
          ...options
        }
      ),
    createPatient: () =>
      useMutation<string, AppError, PatientServer>(createPatientRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_PATIENT')
          }),
        onSuccess: () => {
          openToast({
            title: t(
              'SAVED_PATIENT_AND_REDIRECT_TO_CONFIRM_EMAIL_SUCCESS_TOAST_TITLE'
            ),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([queryKeys.PATIENTS]);
        }
      }),
    updatePatient: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; patient: PatientServer }
      >(({ patientId, patient }) => updatePatientRequest(patientId, patient), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_PATIENT')
          }),
        onSuccess: () => {
          openToast({
            title: t('SAVED_PATIENT_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([queryKeys.PATIENTS]);
        }
      }),
    updatePatientProperties: () =>
      useMutation<
        string,
        AppError,
        {
          patientId: string;
          patientProperties: Basics;
          invalidatePartnerId?: string;
        }
      >(
        ({ patientId, patientProperties }) =>
          updatePatientPropertiesRequest(patientId, patientProperties),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_PROPERTIES')
            }),

          onSuccess: (_, { patientId, invalidatePartnerId }) => {
            openToast({
              title: t('SAVED_PATIENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              getNumericValue(invalidatePartnerId)
                ? invalidatePartnerId
                : patientId,
              'overview'
            ]);
          }
        }
      ),
    updatePatientIdentificationInfo: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; identificationInfo: PatientIdentificationInfo }
      >(
        ({ patientId, identificationInfo }) =>
          updatePatientIdentificationInfoRequest(patientId, identificationInfo),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_ID_INFO')
            }),

          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('SAVED_PATIENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
          }
        }
      ),
    getPatientKPIStatuses: (
      patientId: string,
      options?: UseQueryOptions<PatientKpisResponse, AppError>
    ) =>
      useQuery<PatientKpisResponse, AppError>(
        [queryKeys.PATIENTS, patientId, 'kpis'],
        () => getPatientKpisRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_KPI_STATUS')
            }),

          ...options
        }
      ),
    getPatientGameteReport: (
      patientId: string,
      options?: UseQueryOptions<GameteReport, AppError>
    ) =>
      useQuery<GameteReport, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].GAMETE
        ],
        () => getPatientGameteReportRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_GAMETE_REPORT')
            }),

          ...options
        }
      ),
    getPatientHistory: (
      patientId: string,
      options?: UseQueryOptions<PatientHistory, AppError>
    ) =>
      useQuery<PatientHistory, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].PATIENT_HISTORY
        ],
        () => getPatientHistoryRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_HISTORY')
            }),

          ...options
        }
      ),
    getPatientTreatmentsData: (
      cycleId: string,
      patientId: string,
      options?: UseQueryOptions<CycleGraphData, AppError>
    ) =>
      useQuery<CycleGraphData, AppError>(
        [queryKeys.CYCLES, cycleId, querySubKeys[queryKeys.CYCLES].GRAPH_DATA],
        () => getPatientTreatmentsDataRequest(cycleId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_TREATMENT_DATA')
            }),

          ...options
        }
      ),
    getPatientEstrodialHistory: (
      patientId: string,
      options?: UseQueryOptions<EstrodialHistory, AppError>
    ) =>
      useQuery<EstrodialHistory, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].TREATMENT_DATA
        ],
        () => getPatientEstrodialHistoryRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_ESTRODIAL_HISTORY')
            }),

          ...options
        }
      ),
    updatePatientFlags: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; patientFlags: string }
      >(updatePatientFlagsRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_PATIENT_FLAGS')
          }),

        onSuccess: (_, { patientId }) => {
          openToast({
            title: t('UPDATE_PATIENT_FLAGS_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
        }
      }),
    getStaffMemberById: (
      staffId: string,
      options?: UseQueryOptions<StaffMember, AppError>
    ) =>
      useQuery<StaffMember, AppError>(
        [queryKeys.DOCTORS, staffId],
        () => getStaffMemberByIdRequest(staffId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_STAFF_MEMBER')
            }),

          ...options
        }
      ),
    updatePatientMedicationTreatment: () =>
      useMutation<
        { medication: Medication; patientId: string },
        AppError,
        {
          patientId: string;
          name: MedicationTypes;
          patientMedication: Medication;
        }
      >(updatePatientMedicationTreatmentRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_PATIENT_MEDICATION_TREATMENT')
          }),

        onSuccess: ({ patientId }) => {
          queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
        }
      }),
    updatePatientWitnessedTask: () =>
      useMutation<
        { witnessedTask: CycleTreatmentWitness; patientId: string },
        AppError,
        {
          patientId: string;
          witnessedTask: CycleTreatmentWitness;
        }
      >(updatePatientWitnessedTaskRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_PATIENT_WITNESSED_TASK')
          }),

        onSuccess: ({ patientId }) => {
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].TREATMENT_DATA
          ]);
        }
      }),
    getDashboardSummary: (
      { minDate, maxDate }: RequestRange,
      options?: UseQueryOptions<DashboardSummary, AppError>
    ) =>
      useQuery<DashboardSummary, AppError>(
        [
          queryKeys.CLINICS,
          querySubKeys[queryKeys.CLINICS].DASHBOARD_SUMMARY,
          dateRangeQuerySubkey(minDate, maxDate)
        ],
        () => getDashboardSummaryRequest({ minDate, maxDate }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_DASHBOARD_SUMMARY')
            }),

          ...options
        }
      ),
    getMedications: (options?: UseQueryOptions<Medication[], AppError>) =>
      useQuery<Medication[], AppError>(
        [queryKeys.MEDICATIONS],
        getMedicationsRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_MEDICATIONS')
            }),

          ...options
        }
      ),
    getMedicationsRx: (options?: UseQueryOptions<MedicationRx[], AppError>) =>
      useQuery<MedicationRx[], AppError>(
        [queryKeys.MEDICATIONS, querySubKeys[queryKeys.MEDICATIONS].RX],
        getMedicationsRxRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_MEDICATIONS_RX')
            }),

          ...options
        }
      ),
    getPatientDiagnosis: (
      patientId: string,
      options?: UseQueryOptions<PatientDiagnosisResult[], AppError>
    ) =>
      useQuery<PatientDiagnosisResult[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].DIAGNOSIS
        ],
        () => getPatientDiagnosisRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_DIAGNOSIS')
            }),

          ...options
        }
      ),
    getTelehealthLink: (patientId) =>
      useQuery<{ id: string; token: string }, AppError>(
        [queryKeys.TELEHEALTH_LINK],
        () => getTelehealthLinkRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_TELEHEALTH_LINK')
            })
        }
      ),
    updatePatientAllergiesInfo: () =>
      useMutation<
        { patientAllergiesInfo: PatientAllergies; patientId: string },
        AppError,
        {
          patientId: string;
          patientAllergiesInfo: PatientAllergies;
        }
      >(updatePatientAllergiesInfoRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_PATIENTS_ALLERGIES')
          }),

        onSuccess: ({ patientId }) => {
          queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
        }
      }),
    getLabTests: (options?: UseQueryOptions<LabTest[], AppError>) =>
      useQuery<LabTest[], AppError>(
        [queryKeys.LAB, querySubKeys[queryKeys.LAB].TESTS],
        getLabTestsRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_LAB_TESTS')
            }),

          ...options
        }
      ),
    resendPatientConfirmationEmail: () =>
      useMutation<void, AppError, string>(
        resendPatientConfirmationEmailRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_RESEND_PATIENT_CONFIRMATION_EMAIL')
            }),

          onSuccess: () => {
            openToast({
              title: t('SEND_CONFIRMATION_EMAIL_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    createDiagnosis: () =>
      useMutation<void, AppError, PatientDiagnosis>(
        (diagnosis: PatientDiagnosis) => {
          return createDiagnosisRequest(diagnosis);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_DIAGNOSIS')
            }),

          onSuccess: (_, diagnosis) => {
            openToast({
              title: t('CREATE_DIAGNOSIS_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              diagnosis.patientId,
              querySubKeys[queryKeys.PATIENTS].DIAGNOSIS
            ]);
          }
        }
      ),
    updatePatientNextOfKinInfo: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; nextOfKinInfo: NextOfKin }
      >(
        ({ patientId, nextOfKinInfo }) =>
          updatePatientNextOfKinInfoRequest(patientId, nextOfKinInfo),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_NEXT_OF_KIN')
            }),

          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('SAVED_PATIENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
          }
        }
      ),
    updatePatientGeneralPhysicianInfo: () =>
      useMutation<
        string,
        AppError,
        { patientId: string; generalPhysicianInfo: GeneralPhysician }
      >(
        ({ patientId, generalPhysicianInfo }) =>
          updatePatientGeneralPhysicianInfoRequest(
            patientId,
            generalPhysicianInfo
          ),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_GENERAL_PHYSICIAN')
            }),

          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('SAVED_PRIMARY_CARE_PROVIDER_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
          }
        }
      ),

    createOrder: () =>
      useMutation<
        CreateExternalOrderResponse,
        AppError,
        ExternalOrderFormServer
      >((form) => createOrderRequest(form), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_CREATE_ORDER')
          }),

        onSuccess: (data, { patientId }) => {
          openToast({
            title: t('CREATE_ORDER_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].DOCUMENTS
          ]);
          closeDialog();
          openDialog({
            fullWidth: true,
            maxWidth: 'xs',
            children: (
              <OpenExternalOrderDocuments
                labsDocumentId={data?.lab_order}
                proceduresDocumentId={data?.procedure_order}
                patientId={patientId}
              />
            )
          });
        }
      }),
    getLabOrder: (
      patientId: string,
      labOrderId: string,
      options?: UseQueryOptions<LabOrder, AppError>
    ) =>
      useQuery<LabOrder, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].LAB_ORDER,
          labOrderId
        ],
        () => getLabOrderRequest(labOrderId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_LAB_ORDER')
            }),

          ...options
        }
      ),

    getCycleAvailableGamete: (
      patientId: string,
      options?: UseQueryOptions<AvailableGamete, AppError>
    ) =>
      useQuery<AvailableGamete, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].CRYO_GAMETE
        ],
        () => getCycleAvailableGameteRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CYCLE_AVAILABLE_GAMETE')
            }),

          ...options
        }
      ),
    getPanels: (options?: UseQueryOptions<Panel[], AppError>) =>
      useQuery<Panel[], AppError>(['panels'], getPanelsRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_GET_PANELS')
          }),

        ...options
      }),
    resendWelcomeEmail: () =>
      useMutation<void, AppError, string>(resendWelcomeEmailRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_RESENT_WELCOME_EMAIL')
          }),

        onSuccess: () => {
          openToast({
            title: t('RESEND_PATIENT_WELCOME_EMAIL_SUCCESS_TOAST_TITLE'),
            type: ToastType.SUCCESS
          });
        }
      })
  };
}

export default usePatientsApi;
