import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  Calendar as BigCalendar,
  dayjsLocalizer,
  NavigateAction,
  View
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  ALL_ROOMS_OPTION,
  AppointmentPurposeFilterOptions,
  BusyTime,
  DefaultClinicRoomOptions
} from 'src/types/appointment';
import useMeApi from 'src/hooks/useMeApi';
import { Colors } from '../../styles/colors';
import CustomToolbar from './CustomToolbar';
import useAppointments from 'src/hooks/useAppointments';
import { getFilteredAppointments, getUnifiedBusyTimes } from './utils';
import { BigCalendarWrapper, DayHeader, EventRenderer } from './components';
import { iconSizes, zIndices } from 'src/components/styles/constants';
import Loader from '../Loader';
import { styled } from '@mui/material';
import Box from 'src/components/layout/Box/Box';

const localizer = dayjsLocalizer(dayjs);

const defaultRoomOptions = Object.values(DefaultClinicRoomOptions).map(
  (value: string) => value
);

const StyledLoaderBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.defaultMuiGridOverlay};
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: ${zIndices.high};
  top: 0;
  left: 0;
`;

const Calendar = ({
  date,
  onNavigate,
  dayView: dayViewProp,
  smallHeader,
  filterByStaff,
  filterByAppointment,
  filterByRoom
}: {
  date?: Dayjs;
  onNavigate?: (newDate: Dayjs, view: View, action: NavigateAction) => void;
  dayView?: boolean;
  smallHeader?: boolean;
  filterByStaff?: Array<string>;
  filterByAppointment?: AppointmentPurposeFilterOptions;
  filterByRoom?: string;
}) => {
  const calendarRef = useRef<any | null>(null);
  const { t } = useTranslation();
  const [dayView, setDayView] = useState(dayViewProp ?? false);
  const { getMe } = useMeApi();
  const {
    getClinicRoomBusyTimes,
    getStaffBusyTimes,
    getAggregatedAppointments
  } = useAppointments();

  const { data: me } = getMe();

  const [startOfWeek, endOfWeek] = useMemo(
    () => [date.startOf('week'), date.endOf('week')],
    [date]
  );

  const {
    data: appointments,
    isLoading: isLoadingAppointments,
    isFetching: isFetchingAppointments
  } = getAggregatedAppointments({
    minDate: startOfWeek,
    maxDate: endOfWeek
  });

  const filteredAppointments = useMemo(() => {
    return getFilteredAppointments(
      appointments,
      filterByAppointment,
      filterByStaff,
      filterByRoom
    );
  }, [appointments, filterByAppointment, filterByStaff, filterByRoom]);

  const enableGetRoomBusyTimes = useMemo(() => {
    if (!filterByRoom) return false;

    if (filterByRoom === ALL_ROOMS_OPTION) return false;

    if (defaultRoomOptions.includes(filterByRoom)) return false;

    return true;
  }, [filterByRoom]);

  const enableGetStaffBusyTimes = filterByStaff?.length > 0;

  const {
    data: roomBusyTimes,
    isLoading: isLoadingRoomBusyTimes,
    isFetching: isFetchingRoomBusyTimes
  } = getClinicRoomBusyTimes(
    { roomId: filterByRoom, minDate: startOfWeek, maxDate: endOfWeek },
    {
      enabled: enableGetRoomBusyTimes
    }
  );

  const {
    data: staffBusyTimes,
    isLoading: isLoadingStaffBusyTimes,
    isFetching: isFetchingStaffBusyTimes
  } = getStaffBusyTimes(
    { staffIds: filterByStaff, minDate: startOfWeek, maxDate: endOfWeek },
    { enabled: enableGetStaffBusyTimes }
  );

  const appointmentElements = useMemo(() => {
    return filteredAppointments?.map((appointment) => ({
      ...appointment,
      end: dayjs(appointment.endTime).toDate(),
      start: dayjs(appointment.date).toDate(),
      allDay: false,
      color: appointment.staffIds?.includes(me?.user.id)
        ? Colors.cupid
        : Colors.whiteIce
    }));
  }, [filteredAppointments]);

  const busyTimeElements = useMemo(() => {
    const unifiedBusyTimes = getUnifiedBusyTimes([
      ...((enableGetRoomBusyTimes && roomBusyTimes) || []),
      ...((enableGetStaffBusyTimes && staffBusyTimes) || [])
    ]);
    return unifiedBusyTimes?.map(
      ({ startTime, endTime }: Partial<BusyTime>) => ({
        isBusyTime: true,
        end: endTime.toDate(),
        start: startTime.toDate(),
        allDay: false,
        color: null,
        title: t('BUSY')
      })
    );
  }, [
    roomBusyTimes,
    staffBusyTimes,
    enableGetRoomBusyTimes,
    enableGetStaffBusyTimes
  ]);

  const isLoading =
    isLoadingAppointments ||
    isFetchingAppointments ||
    isLoadingRoomBusyTimes ||
    isFetchingRoomBusyTimes ||
    isLoadingStaffBusyTimes ||
    isFetchingStaffBusyTimes;

  return (
    <BigCalendarWrapper dayView={dayView}>
      {isLoading && (
        <StyledLoaderBox>
          <Loader size={iconSizes.large} />
        </StyledLoaderBox>
      )}

      <BigCalendar
        components={{
          toolbar: (toolbar) =>
            CustomToolbar({
              toolbar,
              dayView,
              currDate: date,
              setDayView,
              smallHeader
            }),
          week: {
            header: (props) => <DayHeader {...props} />,
            event: (props) => <EventRenderer {...props} />
          },
          day: {
            event: (props) => <EventRenderer {...props} />
          }
        }}
        localizer={localizer}
        defaultView={dayView ? 'day' : 'week'}
        events={appointmentElements}
        onSelecting={() => false} // prevents dragging
        ref={calendarRef}
        date={date.toDate()}
        onView={(view) => {
          setDayView(view === 'day');
        }}
        onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
          onNavigate?.(dayjs(newDate).startOf('day'), view, action);
        }}
        selectable
        backgroundEvents={busyTimeElements}
        scrollToTime={dayjs().hour(4).minute(55).toDate()}
      />
    </BigCalendarWrapper>
  );
};

export default Calendar;
