import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import { Cycle, CycleStatus } from 'src/types/cycle';
import { TreatmentChartActions } from '../TreatmentChartActions';
import { useRectMeasure } from 'src/hooks/useRectMeasure';
import { CycleGraph } from '../../graph/CycleGraph';
import { FC, useEffect, useState } from 'react';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { useParams } from 'react-router-dom';
import { GraphTable } from '../GraphTable';
import { EmbryoDevelopmentCard } from '../EmbryoDevelopmentCard';
import { styled } from '@mui/material';

const StyledBox = styled(Box)`
  overflow-x: auto;
`;

interface Props {
  cycleToDisplay: Cycle;
}
const chartLeftMargin = 50;
const yAxisWidth = 80;
const maxColumnWidth = 60;
const StandardView: FC<Props> = ({ cycleToDisplay }: Props) => {
  const { t } = useTranslation();
  const { getPatientTreatmentsData } = usePatientsApi();
  const { callbackRef, rect: chartContainer } = useRectMeasure();
  const { patientId } = useParams();

  const [columnWidth, setColumnWidth] = useState(50);

  const isCurrentCyclePending = cycleToDisplay?.status === CycleStatus.PENDING;

  const {
    data: treatmentsData,
    isLoading: isTreatmentsLoading,
    isFetching: isTreatmentsFetching
  } = getPatientTreatmentsData(cycleToDisplay?.id, patientId, {
    enabled: !!cycleToDisplay?.id
  });

  const isLoading = isTreatmentsLoading || isTreatmentsFetching;

  useEffect(() => {
    setColumnWidth(
      Math.max(
        (chartContainer?.getBoundingClientRect().width -
          yAxisWidth -
          chartLeftMargin) /
          treatmentsData?.data?.length,
        maxColumnWidth
      )
    );
  }, [chartContainer, cycleToDisplay, treatmentsData]);
  return (
    <>
      <Card padding={spacings.x2large}>
        {cycleToDisplay && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom={spacings.medium}
          >
            <Typography fontWeight={fontWeights.black}>
              {`${t(
                'CURRENT_TREATMENT'
              )} #${cycleToDisplay?.displayId?.toUpperCase()} ${t('CHART')}`}
            </Typography>
            {!isCurrentCyclePending && (
              <Box>
                <TreatmentChartActions cycleId={cycleToDisplay?.id} />
              </Box>
            )}
          </Flex>
        )}
        <StyledBox>
          <Box ref={callbackRef} height="700px">
            <CycleGraph
              chartContainer={chartContainer}
              chartLeftMargin={chartLeftMargin}
              columnWidth={columnWidth}
              yAxisWidth={yAxisWidth}
            />
          </Box>

          {!!treatmentsData?.data?.length && (
            <Box>
              <GraphTable
                chartLeftMargin={chartLeftMargin}
                yAxisWidth={yAxisWidth}
                data={treatmentsData}
                columnWidth={columnWidth}
                loading={isLoading}
              />
            </Box>
          )}
        </StyledBox>
      </Card>
      <Flex minHeight={300} marginY={spacings.x2large} gap={spacings.xlarge}>
        <Box flex={2}>
          <EmbryoDevelopmentCard fullWidth cycle={cycleToDisplay} />
        </Box>
      </Flex>
    </>
  );
};

export default StandardView;
