import { ChangeEvent, FC, useState } from 'react';
import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';

import usePatientsApi from 'src/hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import Select from 'src/components/data-entry/Select';
import { OptionExtended } from 'src/types/option';

const viewAll = 'view-all';
const filterId = 'select-patient';
const filterField = 'patientId';
const filterOperator = 'equals';

export const SelectPatient: FC<{
  patientId: string;
  filterModel: GridFilterModel;
  onChange: (newFilterModel: GridFilterModel) => void;
}> = ({ patientId, filterModel, onChange }) => {
  const [selectedPatient, setSelectedPatient] = useState<string>(viewAll);

  const { t } = useTranslation();
  const { getPatientOverview } = usePatientsApi();

  const { data: patientOverview, isLoading: isLoadingOverview } =
    getPatientOverview(patientId);

  const { partnerInfo } = patientOverview || {};
  const partnerBasicInfo = partnerInfo?.basicInfo;
  const patientBasicInfo = patientOverview?.patientBasicInfo;
  const patientName = getFullName(patientBasicInfo);
  const partnerName = getFullName(partnerBasicInfo);

  const options: OptionExtended[] = [
    { value: viewAll, label: t('VIEW_ALL'), labelText: '0' },
    { value: patientId, label: patientName, labelText: patientName },
    { value: partnerInfo?.id, label: partnerName, labelText: partnerName }
  ];

  const updateOrAddObject = (array, newObject) => {
    const isSelectAll = newObject.value === viewAll;

    const index = array.findIndex((item) => item.id === newObject.id);

    if (isSelectAll) {
      array = [];
    } else {
      if (index !== -1) {
        array[index] = newObject;
      } else {
        array.push(newObject);
      }
    }

    return array;
  };

  const handleSelectPatient = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const selectedPatientValue: GridFilterItem = {
      id: filterId,
      field: filterField,
      operator: filterOperator,
      value
    };

    const updatedFilters = updateOrAddObject(
      filterModel.items,
      selectedPatientValue
    );

    setSelectedPatient(value);
    onChange({ ...filterModel, items: updatedFilters });
  };

  return (
    <Select
      width="200px"
      value={selectedPatient}
      options={options}
      disabled={isLoadingOverview}
      onChange={(e) => handleSelectPatient(e)}
      shouldSortOptions
    />
  );
};
