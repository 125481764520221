const enClinicSettingsTranslations = {
  DATA_EXPORT: 'Data Export',
  STAFF: 'Staff',
  DATA_EXPORT_HEADER: 'Export Patient Medical Record',
  DATA_EXPORT_DESCRIPTION_1:
    'This feature allows you to export a single patient medical data file in a CSV format. Documentation on how to read the file is included in the zip file after download.',
  DATA_EXPORT_DESCRIPTION_2:
    'Please do not click off this page, or refresh while the export is ongoing.',
  DATA_EXPORT_SUB_DESCRIPTION:
    'To request a download of the patient population, please contact Embie at support@embieclinic.com',
  GENERATE_EXPORT_BUTTON: 'Generate Export File',
  EXPORTING_DATA: 'Exporting Data',
  EXPORT_NAME: 'Export Name'
};

export default enClinicSettingsTranslations;
