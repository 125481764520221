import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';

import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box/Box';
import Typography from 'src/components/display/Typography/Typography';
import useTasks from 'src/hooks/useTasks';
import useDebounce from 'src/hooks/useDebounce';
import { paginationDefaultParams } from 'src/constants';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { TasksTable } from '../../tasksTable/TasksTable';
import { SelectPatient } from '../../tasksTable/SelectPatient';
import { PatientMiniCard } from '../common/PatientMiniCard';
import { PatientFlags } from '../medicalHistory/PatientFlags';

export const PatientTasks: FC = () => {
  const [paginationModelUndoneTasks, setPaginationModelUndoneTasks] = useState(
    paginationDefaultParams
  );
  const [paginationModelDoneTasks, setPaginationModelDoneTasks] = useState(
    paginationDefaultParams
  );
  const [filterModelUndoneTasks, setFilterModelUndoneTasks] =
    useState<GridFilterModel>({
      items: [],
      logicOperator: GridLogicOperator.And
    });
  const [filterModelDoneTasks, setFilterModelDoneTasks] =
    useState<GridFilterModel>({
      items: [],
      logicOperator: GridLogicOperator.And
    });

  const { t } = useTranslation();
  const { patientId } = useParams();
  const { getPatientOverview } = usePatientsApi();
  const { getPatientTasks } = useTasks();

  const debouncedFilterModelUndoneTasks = useDebounce(filterModelUndoneTasks);
  const debouncedFilterModelDoneTasks = useDebounce(filterModelDoneTasks);

  const { data: patientOverview } = getPatientOverview(patientId);

  const {
    data: tasksUndone,
    isFetching: isUndoneTasksFetching,
    isLoading: isUndoneTasksLoading
  } = getPatientTasks({
    patientId,
    showDone: false,
    params: {
      pagination: paginationModelUndoneTasks
    },
    filters: debouncedFilterModelUndoneTasks
  });

  const {
    data: tasksDone,
    isFetching: isTasksDoneFetching,
    isLoading: isTasksDoneLoading
  } = getPatientTasks({
    patientId,
    showDone: true,
    params: {
      pagination: paginationModelDoneTasks
    },
    filters: debouncedFilterModelDoneTasks
  });

  const undoneTasks = tasksUndone?.rows ?? [];
  const doneTasks = tasksDone?.rows ?? [];
  const totalUndoneTasksRowCount = tasksUndone?.count ?? 0;
  const totalDoneTasksCount = tasksDone?.count ?? 0;

  const isLoading = isUndoneTasksFetching || isUndoneTasksLoading;
  const isDoneTasksLoading = isTasksDoneFetching || isTasksDoneLoading;

  const handleFilterChangeUndoneTasks = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModelUndoneTasks(newFilterModel);
    },
    [setFilterModelUndoneTasks]
  );

  const handleFilterChangeDoneTasks = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModelDoneTasks(newFilterModel);
    },
    [setFilterModelDoneTasks]
  );

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_TASKS_PAGE_TITLE')}</title>
      </Helmet>
      <Box>
        <Box marginBottom={spacings.large}>
          <PatientFlags patientId={patientId} />
        </Box>

        <Flex gap={spacings.large} marginBottom={spacings.large} width="100%">
          <PatientMiniCard
            flex={1}
            patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
            patientId={patientId}
            properties={patientOverview?.patientProperties}
            basicInfo={patientOverview?.patientBasicInfo}
            header={t('PATIENT_CHECKLIST')}
          />
          {patientOverview?.partnerInfo && (
            <PatientMiniCard
              flex={1}
              isPartner
              patientId={patientOverview?.partnerInfo?.id}
              patientDisplayId={
                patientOverview?.partnerInfo?.basicInfo?.displayId
              }
              properties={patientOverview?.partnerInfo?.properties}
              basicInfo={patientOverview?.partnerInfo?.basicInfo}
              header={t('PARTNER_CHECKLIST')}
            />
          )}
        </Flex>
        <Flex
          gap={spacings.large}
          marginBottom={spacings.large}
          flexDirection="column"
          width="100%"
        >
          <TasksTable
            tasks={undoneTasks || []}
            loading={isLoading}
            paginationModel={paginationModelUndoneTasks}
            paginationMode="server"
            onPaginationModelChange={setPaginationModelUndoneTasks}
            filterModel={filterModelUndoneTasks}
            filterMode="server"
            onFilterModelChange={handleFilterChangeUndoneTasks}
            rowCount={totalUndoneTasksRowCount}
            toolbar={
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2">{t('OPEN_TASKS')}</Typography>
                <SelectPatient
                  patientId={patientId}
                  filterModel={filterModelUndoneTasks}
                  onChange={handleFilterChangeUndoneTasks}
                />
              </Flex>
            }
          />
          <TasksTable
            historyTasks
            tasks={doneTasks}
            loading={isDoneTasksLoading}
            paginationModel={paginationModelDoneTasks}
            paginationMode="server"
            onPaginationModelChange={setPaginationModelDoneTasks}
            filterModel={filterModelDoneTasks}
            filterMode="server"
            onFilterModelChange={handleFilterChangeDoneTasks}
            rowCount={totalDoneTasksCount}
            toolbar={
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h2">{t('CLOSED_TASKS')}</Typography>
                <SelectPatient
                  patientId={patientId}
                  filterModel={filterModelDoneTasks}
                  onChange={handleFilterChangeDoneTasks}
                />
              </Flex>
            }
          />
        </Flex>
      </Box>
    </>
  );
};
