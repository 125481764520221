import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import Flex from 'src/components/layout/Flex/Flex';
import Button from 'src/components/display/Button/Button';
import { spacings, zIndices } from 'src/components/styles/constants';
import Loader from 'src/components/display/Loader';
import { useToast } from 'src/components/components-api/ToastProvider';
import { ToastType } from 'src/components/display/Toast/Toast';
import Center from 'src/components/layout/Center';
import { ServerStatus } from '../../types/global';
import useMeApi from 'src/hooks/useMeApi';

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacings.large};
  flex: 1;
`;

const StyledFlexMainContainer = styled(Flex)`
  width: 500px;
  z-index: ${zIndices.highest};
`;

export const VerifyDocumentSignee: FC<{
  openElements: (dataElements: string[]) => void;
  closeElements: (dataElements: string[]) => void;
  setToolbarGroup: (groupDataElement: string, pickTool?: boolean) => void;
  ToolbarGroup: {
    VIEW: string;
    ANNOTATE: string;
    SHAPES: string;
    INSERT: string;
    MEASURE: string;
    EDIT: string;
    FILL_AND_SIGN: string;
    FORMS: string;
  };
}> = ({ openElements, closeElements, setToolbarGroup, ToolbarGroup }) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { sendOtpCode, checkPendingOtp, getMe, verifyOtpCode } = useMeApi();

  const [otp, setOtp] = useState('');
  const [haveOtpCode, setHaveOtpCode] = useState<boolean>(false);

  const { data: me } = getMe() || {};

  const openSignatureToolbar = () => {
    openElements(['toolsHeader']);
    openElements(['toolsOverlay']);
  };

  const closeSignatureToolbar = () => {
    closeElements(['toolsHeader']);
    closeElements(['toolsOverlay']);
    setToolbarGroup(ToolbarGroup.VIEW);
  };

  const { refetch: reftechPendingOtp } = checkPendingOtp(openSignatureToolbar);

  const { mutate: sendOtpCodeMutate, isLoading: isLoadingSendOtp } =
    sendOtpCode();

  const { mutate: verifyOtpCodeMutate, isLoading: isLoadingVerifyOtp } =
    verifyOtpCode();

  const onReftechPendingOtp = async () => {
    const { data: hasPendingOtp } = (await reftechPendingOtp()) || {};

    const { pendingOtp, verified } = hasPendingOtp || {};

    if (pendingOtp && verified) {
      openSignatureToolbar();
      return;
    }

    if (pendingOtp && !verified) {
      openToast({
        title: t('HAVE_A_PENDING_OTP_CODE_TOAST_TITLE'),
        children: t('REDIRECTING_TO_OTP_CODE_VERIFY'),
        type: ToastType.WARNING
      });
      setHaveOtpCode(true);
    }

    closeSignatureToolbar();
  };

  useEffect(() => {
    onReftechPendingOtp();
  }, []);

  return (
    <StyledFlexMainContainer>
      <StyledFlex>
        <Typography variant="h2">{t('VERIFY_YOUR_ACCOUNT')}</Typography>
        <Typography textAlign="center">
          {!haveOtpCode
            ? t('VERIFY_YOUR_ACCOUNT_DESCRIPTION')
            : t('ENTER_TOP_CODE_DESCRIPTION')}
        </Typography>

        {haveOtpCode && (
          <OTPInput
            inputStyle={{
              width: spacings.x2large,
              height: spacings.x2large
            }}
            value={otp || ''}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span style={{ width: spacings.medium }} />}
            renderInput={(props) => <input {...props} />}
          />
        )}

        <Center>
          <Button
            onClick={async () => {
              if (!haveOtpCode) {
                await onReftechPendingOtp();
                await sendOtpCodeMutate(null, {
                  onSuccess: ({ status }) => {
                    if (status === ServerStatus.SUCCESS) {
                      setHaveOtpCode(true);
                    }
                  }
                });
              } else {
                if (otp?.length === 6) {
                  await verifyOtpCodeMutate(
                    {
                      staffId: me.user.id,
                      otpCode: otp
                    },
                    {
                      onSuccess: ({ status }) => {
                        if (status === ServerStatus.SUCCESS) {
                          openSignatureToolbar();
                        }
                      }
                    }
                  );
                }
              }
            }}
          >
            {isLoadingSendOtp || isLoadingVerifyOtp ? (
              <Loader />
            ) : !haveOtpCode ? (
              t('SEND_CODE')
            ) : (
              t('SUBMIT')
            )}
          </Button>
        </Center>

        {!isLoadingSendOtp && (
          <StyledTypography
            onClick={async () => {
              if (!haveOtpCode) {
                setHaveOtpCode(true);
              } else {
                setHaveOtpCode(false);
                await sendOtpCodeMutate(null, {
                  onSuccess: ({ status }) => {
                    if (status === ServerStatus.SUCCESS) {
                      setHaveOtpCode(true);
                    }
                  }
                });
              }
            }}
          >
            {!haveOtpCode ? t('ALREADY_HAVE_A_CODE') : t('DONT_HAVE_A_CODE')}
          </StyledTypography>
        )}
      </StyledFlex>
    </StyledFlexMainContainer>
  );
};
