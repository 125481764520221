import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings, spacingsPercentage } from 'src/components/styles/constants';
import Loader from 'src/components/display/Loader/Loader';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import FormActionsContainer from 'src/components/display/FormActionsContainer/FormActionsContainer';
import {
  collectionLocationEntries,
  collectionMethodEntries,
  containerTypesEntries,
  CryoDetailsAttributes,
  ExamReason,
  OtherExaminationTypes,
  PossiblePatient,
  PurposeOfFreeze,
  SampleFate,
  sampleFateEntries,
  SemenAnalysisExamReason,
  semenAnalysisExamReasonEntries,
  SemenAnalysisReportForm,
  semenSourceEntries,
  SpermType,
  spermTypeEntries,
  VialType
} from 'src/types/exam';
import { Patient, PersonSex } from 'src/types/patient';
import { YesOrNo, yesOrNoEntries } from 'src/types/global';
import { getFullName, getNumericValue } from 'src/utils/general';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import usePatientsApi from '../../../hooks/usePatientsApi';
import useExams from 'src/hooks/useExams';
import { DoctorChips } from '../common/DoctorChips';
import { ResultInitialSample } from './ResultInitialSample';
import { SpermPrep } from './SpermPrep';
import { CryoDetails } from './CryoDetails';

const getPossiblePatients = (patient: Patient): PossiblePatient[] => {
  const possiblePatients: PossiblePatient[] = [];

  if (patient?.personalInfo?.sexAtBirth === PersonSex.MALE) {
    possiblePatients.push({
      id: patient.personalInfo.id,
      name: getFullName(patient.personalInfo)
    });
  }

  if (patient?.partnerInfo?.sexAtBirth === PersonSex.MALE) {
    possiblePatients.push({
      id: patient.partnerInfo.id,
      name: getFullName(patient.partnerInfo)
    });
  }

  return possiblePatients;
};

export const AddEditSemenAnalysisReportForm: FC<{
  patientId: string;
  cycleId?: string;
  examinationId?: string;
}> = ({ patientId, cycleId, examinationId }) => {
  const isEdit = !!examinationId;
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport, getExaminationById } = useExams();
  const { t } = useTranslation();
  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  const { data: examination } = getExaminationById(examinationId, {
    enabled: !!examinationId
  });

  const isInCycle = cycleId || examination?.cycleId;

  const YesNoOptions = yesOrNoEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const containerOptions = containerTypesEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const collectionLocationOptions = collectionLocationEntries.map(
    ([key, value]) => ({
      label: t(key),
      value
    })
  );

  const collectionMethodOptions = collectionMethodEntries.map(
    ([key, value]) => ({
      label: t(key),
      value
    })
  );

  const semenSourceOptions = semenSourceEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const spermTypeOptions = spermTypeEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const sampleFateOptions = sampleFateEntries.map(([key, value]) => ({
    label: t(key),
    value
  }));

  const examReasonOptions = useMemo(
    () =>
      semenAnalysisExamReasonEntries
        .filter(([_, value]) =>
          isInCycle ? true : value !== SemenAnalysisExamReason.IN_CYCLE
        )
        .map(([key, value]) => ({
          label: t(key),
          value: value
        })),
    [isInCycle, examination]
  );

  const possiblePatients = getPossiblePatients(patient);

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultCryoDetails: CryoDetailsAttributes = {
    purposeOfFreeze:
      examination?.semenAnalysis?.purposeOfFreeze ??
      PurposeOfFreeze.FERTILITY_PRESERVATION,
    freezeDate: examination?.semenAnalysis?.freezeDate ?? dayjs().toDate(),
    numberOfVialsStored: examination?.semenAnalysis?.numberOfVialsStored ?? 0,
    vialType: examination?.semenAnalysis?.vialType ?? VialType.AMPS,
    consentExpiration:
      examination?.semenAnalysis?.consentExpiration ?? dayjs().toDate(),
    storageLocation: examination?.semenAnalysis?.storageLocation ?? '',
    tankNo: examination?.semenAnalysis?.tankNo ?? null
  };

  const defaultValues: SemenAnalysisReportForm = {
    id: examination?.id ?? null,
    patientId: examination?.patientId ?? patientId,
    cycleId: examination?.cycleId ?? cycleId,
    patientName: '',
    requestingPhysicianId: examination?.requestedPhysician ?? '',
    type: examination?.type ?? OtherExaminationTypes.SPERM_ANALYSIS_IN_CYCLE,
    performedById: examination?.performedBy ?? '',
    witnessedBy: examination?.witnessedBy ?? '',
    signedOffById: examination?.signedOffBy ?? '',
    reason: null,
    dateProduced: examination?.semenAnalysis?.dateProduced ?? dayjs().toDate(),
    timeReceived: examination?.semenAnalysis?.timeReceived
      ? new Date(examination?.semenAnalysis?.timeReceived)
      : new Date(),
    timeProduced: examination?.semenAnalysis?.timeProduced
      ? new Date(examination?.semenAnalysis?.timeProduced)
      : new Date(),
    timeOfAnalysis: examination?.semenAnalysis?.timeOfAnalysis
      ? new Date(examination?.semenAnalysis?.timeOfAnalysis)
      : new Date(),
    collectionType: examination?.semenAnalysis?.collectionType ?? null,
    collectionLocation: examination?.semenAnalysis?.collectionLocation ?? null,
    spermType: examination?.semenAnalysis?.spermType ?? null,
    dateOfInitialCryo:
      examination?.semenAnalysis?.dateOfInitialCryo ?? dayjs().toDate(),
    infectiousStatus: examination?.semenAnalysis?.infectiousStatus ?? null,
    infectiousReason: examination?.semenAnalysis?.infectiousReason ?? '',
    semenSource: examination?.semenAnalysis?.semenSource ?? null,
    spermPrepMethod: examination?.semenAnalysis?.spermPrepMethod ?? [],
    spermPrepSuitability:
      examination?.semenAnalysis?.spermPrepSuitability ?? null,
    sampleFate: examination?.semenAnalysis?.sampleFate ?? null,
    daysOfAbstinence: examination?.semenAnalysis?.daysOfAbstinence ?? null,
    volumeOfSpermSample:
      examination?.semenAnalysis?.volumeOfSpermSample ?? null,
    volume: examination?.semenAnalysis?.volume.value ?? null,
    ph: examination?.semenAnalysis?.ph ?? null,
    appearance: examination?.semenAnalysis?.appearance ?? null,
    concentration: examination?.semenAnalysis?.concentration.value ?? null,
    totalSpermCount: examination?.semenAnalysis?.totalSpermCount.value ?? null,
    normalMorphology:
      examination?.semenAnalysis?.normalMorphology.value ?? null,
    progressiveMotileA: examination?.semenAnalysis?.progressiveMotileA ?? null,
    progressiveMotileB: examination?.semenAnalysis?.progressiveMotileB ?? null,
    progressiveMotileC: examination?.semenAnalysis?.progressiveMotileC ?? null,
    generalComments: examination?.notes ?? '',
    motileSpermCount: examination?.semenAnalysis?.motileSpermCount ?? null,
    progressiveMotileSpermCount:
      examination?.semenAnalysis?.progressiveMotileSpermCount ?? null,
    totalMotile: examination?.semenAnalysis?.totalMotile.value ?? null,
    progressiveMotility:
      examination?.semenAnalysis?.progressiveMotility ?? null,
    agglutination: examination?.semenAnalysis?.agglutination ?? null,
    typeOfAgglutination:
      examination?.semenAnalysis?.typeOfAgglutination ?? null,
    viscosity: examination?.semenAnalysis?.viscosity ?? null,
    antibodyImmunoglobulinA:
      examination?.semenAnalysis?.antibodyImmunoglobulinA ?? null,
    antibodyIgaResult: examination?.semenAnalysis?.antibodyIgaResult ?? null,
    antibodyImmunoglobulinG:
      examination?.semenAnalysis?.antibodyImmunoglobulinG ?? null,
    antibodyIggResult: examination?.semenAnalysis?.antibodyIggResult ?? null,
    roundCell: examination?.semenAnalysis?.roundCell ?? null,
    liquefaction: examination?.semenAnalysis?.liquefaction ?? null,
    resultsGeneralComments:
      examination?.semenAnalysis?.resultsGeneralComments ?? '',
    spermPrepComments: examination?.semenAnalysis?.spermPrepComments ?? '',
    spermPrepStartTime: examination?.semenAnalysis?.spermPrepStartTime
      ? new Date(examination?.semenAnalysis?.spermPrepStartTime)
      : new Date(),
    spermPrepFinishTime: examination?.semenAnalysis?.spermPrepFinishTime
      ? new Date(examination?.semenAnalysis?.spermPrepFinishTime)
      : new Date(),
    containerType: examination?.semenAnalysis?.containerType ?? null,
    completeSample: !!examination?.semenAnalysis?.completeSample,
    spermPrepVolume: examination?.semenAnalysis?.spermPrepVolume ?? null,
    spermPrepConcentration:
      examination?.semenAnalysis?.spermPrepConcentration ?? null,
    spermPrepTotalSpermCount:
      examination?.semenAnalysis?.spermPrepTotalSpermCount ?? null,
    spermPrepNormalMorphology:
      examination?.semenAnalysis?.spermPrepNormalMorphology ?? null,
    spermPrepProgressiveMotileA:
      examination?.semenAnalysis?.spermPrepProgressiveMotileA ?? null,
    spermPrepProgressiveMotileB:
      examination?.semenAnalysis?.spermPrepProgressiveMotileB ?? null,
    spermPrepProgressiveMotileC:
      examination?.semenAnalysis?.spermPrepProgressiveMotileC ?? null,
    spermPrepMotileSpermCount:
      examination?.semenAnalysis?.spermPrepMotileSpermCount ?? null,
    spermPrepProgressiveMotileSpermCount:
      examination?.semenAnalysis?.spermPrepProgressiveMotileSpermCount ?? null,
    spermPrepTotalMotile:
      examination?.semenAnalysis?.spermPrepTotalMotile ?? null,
    spermPrepProgressiveMotility:
      examination?.semenAnalysis?.spermPrepProgressiveMotility ?? null,
    immotileD: examination?.semenAnalysis?.immotileD ?? null,
    spermPrepImmotileD: examination?.semenAnalysis?.spermPrepImmotileD ?? null,
    cryoDetails: defaultCryoDetails
  };

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset
  } = useForm<SemenAnalysisReportForm>({
    mode: 'onChange',
    defaultValues
  });
  const { sampleFate, reason: examReason } = watch();

  const { errors } = formState;

  const onSubmit = async ({
    id,
    requestingPhysicianId,
    type,
    performedById,
    witnessedBy,
    signedOffById,
    reason,
    patientId,
    patientName,
    cycleId,
    volume,
    concentration,
    normalMorphology,
    progressiveMotileA,
    progressiveMotileB,
    progressiveMotileC,
    volumeOfSpermSample,
    daysOfAbstinence,
    immotileD,
    ph,
    roundCell,
    antibodyImmunoglobulinA,
    antibodyIgaResult,
    antibodyImmunoglobulinG,
    antibodyIggResult,
    spermPrepVolume,
    spermPrepConcentration,
    spermPrepNormalMorphology,
    spermPrepProgressiveMotileA,
    spermPrepProgressiveMotileB,
    spermPrepProgressiveMotileC,
    spermPrepImmotileD,
    cryoDetails,
    sampleFate,
    ...restReport
  }: SemenAnalysisReportForm) => {
    const examToSend = {
      requestingPhysicianId,
      type,
      cycleId,
      performedById,
      witnessedBy,
      signedOffById,
      reason,
      patientId,
      patientName,
      semenAnalysis: {
        volume: getNumericValue(volume),
        concentration: getNumericValue(concentration),
        normalMorphology: getNumericValue(normalMorphology),
        progressiveMotileA: getNumericValue(progressiveMotileA),
        progressiveMotileB: getNumericValue(progressiveMotileB),
        progressiveMotileC: getNumericValue(progressiveMotileC),
        volumeOfSpermSample: getNumericValue(volumeOfSpermSample),
        daysOfAbstinence: getNumericValue(daysOfAbstinence),
        immotileD: getNumericValue(immotileD),
        ph: getNumericValue(ph),
        roundCell: getNumericValue(roundCell),
        antibodyImmunoglobulinA,
        antibodyIgaResult: getNumericValue(antibodyIgaResult),
        antibodyImmunoglobulinG,
        antibodyIggResult: getNumericValue(antibodyIggResult),
        spermPrepVolume: getNumericValue(spermPrepVolume),
        spermPrepConcentration: getNumericValue(spermPrepConcentration),
        spermPrepNormalMorphology: getNumericValue(spermPrepNormalMorphology),
        spermPrepProgressiveMotileA: getNumericValue(
          spermPrepProgressiveMotileA
        ),
        spermPrepProgressiveMotileB: getNumericValue(
          spermPrepProgressiveMotileB
        ),
        spermPrepProgressiveMotileC: getNumericValue(
          spermPrepProgressiveMotileC
        ),
        spermPrepImmotileD: getNumericValue(spermPrepImmotileD),
        sampleFate,
        cryoDetails:
          sampleFate === SampleFate.CRYO
            ? {
                numberOfVialsStored: cryoDetails.numberOfVialsStored || null,
                storageLocation: cryoDetails.storageLocation,
                tankNo: cryoDetails.tankNo || null,
                vialType: cryoDetails.vialType,
                consentExpiration: cryoDetails.consentExpiration,
                purposeOfFreeze: cryoDetails.purposeOfFreeze,
                freezeDate: cryoDetails.freezeDate
              }
            : null,
        ...restReport
      }
    };

    if (isEdit) {
      await updateReport({
        id,
        ...examToSend
      });
    } else {
      await createReport(examToSend);
    }
  };

  useEffect(() => {
    reset(defaultValues);
    const isExamInCycle = isInCycle ? ExamReason.IN_CYCLE : null;
    const reason = examination?.reason ?? isExamInCycle;
    setValue('reason', reason);
  }, [patient, examination, isInCycle]);

  useEffect(() => {
    const examType =
      examReason === ExamReason.IN_CYCLE
        ? OtherExaminationTypes.SPERM_ANALYSIS_IN_CYCLE
        : OtherExaminationTypes.SPERM_ANALYSIS_DIAGNOSTIC;

    setValue('type', examType);
    setValue('patientName', getFullName(patient?.personalInfo));
  }, [patient, examReason]);

  if (isLoadingPatient) return <Loader />;

  const formActions = [
    <Button
      key="action-save"
      width={spacingsPercentage.large}
      disabled={isCreatingReport || isUpdatingReport}
      onClick={handleSubmit(onSubmit)}
    >
      {t('SAVE')}
    </Button>
  ];

  return (
    <Flex
      flexDirection="column"
      marginTop={spacings.large}
      gap={spacings.x2large}
    >
      <Typography fontWeight={fontWeights.bold}>
        {t('SEMEN_ANALYSIS_REPORT_NOTE')}
      </Typography>
      <form noValidate>
        <Flex marginBottom={spacings.medium} gap={spacings.large}>
          <Box flex={2}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('PATIENT_LABEL')}
                  value={getValues('patientId')}
                  defaultOption={t('PLEASE_CHOOSE_PATIENT')}
                  options={possiblePatients.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                  onChange={(ev) => {
                    const patientId = ev.target.value;
                    const chosenPatient = possiblePatients.find(
                      ({ id }) => id === patientId
                    );
                    onChange(chosenPatient?.name);
                    setValue('patientId', chosenPatient?.id);
                  }}
                />
              )}
            />
          </Box>
          <Box flex={2}>
            <Controller
              name="reason"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('EXAM_REASON')}
                  inputRef={ref}
                  disabled={!!cycleId || !!examination?.cycleId}
                  error={!!errors?.reason}
                  helperText={errors?.reason?.message}
                  defaultOption={t('EXAM_REASON')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={examReasonOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="requestingPhysicianId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-requesting-physician"
                  showSelectedValue
                  label={t('REQUESTING_PROVIDER').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="signedOffById"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DoctorChips
                  id="semen-analysis-signed-off-by"
                  showSelectedValue
                  label={t('SIGNED_OFF_BY').toUpperCase()}
                  value={[value]}
                  onAddChip={(selectedDoctorId) => onChange(selectedDoctorId)}
                />
              )}
            />
          </Box>
          <Box flex={1} />
          <Box flex={1} />
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="dateProduced"
              control={control}
              rules={{
                validate: (value) => {
                  const date = dayjs(value);
                  if (date.isAfter(dayjs())) {
                    return t('DATE_CANT_BE_IN_THE_FUTURE');
                  }
                  return true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  label={t('DATE_PRODUCED').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.dateProduced}
                  helperText={errors.dateProduced?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="timeReceived"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TimePicker
                  {...field}
                  format={getTimeFormat({ isShort: true })}
                  label={t('TIME_RECEIVED').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.timeReceived}
                  helperText={errors.timeReceived?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="timeProduced"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TimePicker
                  {...field}
                  label={t('TIME_PRODUCED').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.timeProduced}
                  helperText={errors.timeProduced?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="timeOfAnalysis"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TimePicker
                  {...field}
                  label={t('TIME_OF_ANALYSIS').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.timeOfAnalysis}
                  helperText={errors.timeOfAnalysis?.message}
                  fullWidth
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="collectionLocation"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('COLLECTION_LOCATION')}
                  inputRef={ref}
                  error={!!errors?.collectionLocation}
                  helperText={errors?.collectionLocation?.message}
                  defaultOption={t('COLLECTION_LOCATION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={collectionLocationOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="collectionType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('COLLECTION_METHOD')}
                  inputRef={ref}
                  error={!!errors?.collectionType}
                  helperText={errors?.collectionType?.message}
                  defaultOption={t('COLLECTION_METHOD')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={collectionMethodOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="semenSource"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SEMEN_SOURCE')}
                  inputRef={ref}
                  error={!!errors?.semenSource}
                  helperText={errors?.semenSource?.message}
                  defaultOption={t('SEMEN_SOURCE')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={semenSourceOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="spermType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SPERM_TYPE')}
                  inputRef={ref}
                  error={!!errors?.spermType}
                  helperText={errors?.spermType?.message}
                  defaultOption={t('SPERM_TYPE')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={spermTypeOptions}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.large}>
          <Box flex={1}>
            <Controller
              name="containerType"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
                  {...field}
                  label={t('CONTAINER_TYPE')}
                  inputRef={ref}
                  error={!!errors?.containerType}
                  helperText={errors?.containerType?.message}
                  defaultOption={t('CONTAINER_TYPE')}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                  options={containerOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="daysOfAbstinence"
              control={control}
              rules={{
                min: {
                  value: 0,
                  message: t('DAYS_OF_ABSTINENCE_VALIDATION_MSG')
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('DAYS_OF_ABSTINENCE').toUpperCase()}
                  placeholder={t('DAYS_OF_ABSTINENCE')}
                  error={!!errors?.daysOfAbstinence}
                  helperText={errors?.daysOfAbstinence?.message}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="completeSample"
              control={control}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('COMPLETE_SAMPLE')}
                  inputRef={ref}
                  error={!!errors?.completeSample}
                  helperText={errors?.completeSample?.message}
                  defaultOption={t('COMPLETE_SAMPLE')}
                  value={value ? YesOrNo.YES : YesOrNo.NO}
                  onChange={(event) => {
                    onChange(event.target.value === YesOrNo.YES);
                  }}
                  options={YesNoOptions}
                />
              )}
            />
          </Box>
          <Box flex={1} />
        </Flex>
        <Flex gap={spacings.large} marginBottom={spacings.x2large}>
          {(getValues('spermType') === SpermType.THAWED ||
            getValues('spermType') === SpermType.MIX_OF_FRESH_AND_THAWED) && (
            <Box flex={1}>
              <Controller
                name="dateOfInitialCryo"
                control={control}
                rules={{
                  validate: (value) => {
                    const date = dayjs(value);
                    if (date.isAfter(dayjs())) {
                      return t('DATE_CANT_BE_IN_THE_FUTURE');
                    }
                    return true;
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('DATE_OF_INITIAL_CRYO').toUpperCase()}
                    inputRef={ref}
                    error={!!errors?.dateOfInitialCryo}
                    helperText={errors.dateOfInitialCryo?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          )}
          <Box flex={1}>
            <Controller
              name="infectiousStatus"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('INFECTIOUS_STATUS').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.infectiousStatus}
                  helperText={errors?.infectiousStatus?.message}
                  options={YesNoOptions}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="infectiousReason"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('INFECTIOUS_REASON').toUpperCase()}
                  placeholder={t('INFECTIOUS_REASON')}
                  error={!!errors.infectiousReason}
                  helperText={errors?.infectiousReason?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="sampleFate"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('SAMPLE_FATE')}
                  inputRef={ref}
                  error={!!errors?.sampleFate}
                  helperText={errors?.sampleFate?.message}
                  defaultOption={t('SAMPLE_FATE')}
                  options={sampleFateOptions}
                />
              )}
            />
          </Box>
          <Box flex={1} />
        </Flex>
        <ResultInitialSample
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
        <SpermPrep
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
        {sampleFate === SampleFate.CRYO && (
          <CryoDetails errors={errors} control={control} />
        )}
        <FormActionsContainer actions={formActions} />
      </form>
    </Flex>
  );
};
