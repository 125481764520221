import { FC, useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { css, styled } from '@mui/material';
import Card, { CardProps } from 'src/components/display/Card/Card';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { InputFieldProps } from '../InputField';
import InputField from '../InputField/InputField';
import { makeShouldForwardProps } from 'src/components/utils';

interface EditableTitleProps
  extends Omit<CardProps & InputFieldProps, 'onChange'> {
  value: string;
  onChange?: (newValue: string) => void;
}

interface EditableTextProps extends InputFieldProps {
  isEditing?: boolean;
}

const StyledEditableTitleContainer = styled(Card)<CardProps>`
  padding: ${spacings.small};
  padding-inline-end: ${spacings.xlarge};
`;

const shouldForwardProp = makeShouldForwardProps(['isEditing']);
const StyledEditableText = styled(InputField, {
  shouldForwardProp
})<EditableTextProps>`
  &:hover .MuiInputBase-root.Mui-disabled {
    ${({ isEditing }) =>
      isEditing &&
      css`
        border-color: ${Colors.transparent};
      `}
  }
  .MuiInputBase-root {
    height: 30px;
    color: ${Colors.emperor};
    border: ${Colors.transparent};
    .MuiInputBase-input.Mui-disabled {
      color: ${Colors.emperor};
      -webkit-text-fill-color: ${Colors.emperor};
    }
    ${({ isEditing }) =>
      isEditing &&
      css`
        &.hover,
        &:hover,
        & {
          border-color: ${Colors.transparent};
        }
      `}
  }
`;

const EditableTitle: FC<EditableTitleProps> = ({
  value,
  onChange,
  flex,
  ...otherProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const [oldValue, setOldValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setInputValue(value);
    setOldValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing) inputRef?.current?.focus();
  }, [isEditing]);

  return (
    <StyledEditableTitleContainer flex={flex} shadow>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={spacings.x2large}
      >
        <StyledEditableText
          {...otherProps}
          value={inputValue}
          onChange={(ev) => setInputValue(ev.target.value)}
          disabled={!isEditing}
          isEditing={isEditing}
          inputRef={inputRef}
          onClick={() => setIsEditing(true)}
        />
        <Box>
          {isEditing ? (
            <Flex alignItems="center" gap={spacings.medium}>
              <MiniIconButton
                icon={<CheckIcon />}
                onClick={() => {
                  setIsEditing(false);
                  setOldValue(inputValue);
                  onChange(inputValue);
                }}
              />
              <MiniIconButton
                icon={<ClearIcon />}
                onClick={() => {
                  setIsEditing(false);
                  setInputValue(oldValue);
                }}
              />
            </Flex>
          ) : (
            <MiniIconButton
              icon={<EditIcon style={{ fill: 'black' }} />}
              onClick={() => setIsEditing(true)}
            />
          )}
        </Box>
      </Flex>
    </StyledEditableTitleContainer>
  );
};

export default EditableTitle;
