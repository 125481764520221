import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import {
  StyledBoxWithBottomBorder,
  StyledBoxWithMarginLeft,
  StyledBoxWithMarginRight,
  StyledFlexboxWithBasicNumbers
} from '../../../modules/patients/overview/PatientOverviewCard';
import MiniIconButton from 'src/components/display/MiniIconButton';
import EditIcon from '@mui/icons-material/Edit';
import NumberPreview from 'src/components/display/NumberPreview';
import { PersonSex } from 'src/types/patient';
import SkeletonLoader from './Skeleton';

interface PatientOverviewCardSkeletonProps {
  isRandom?: boolean;
}

const HormonalSliderSkeleton: FC<{ isRandom?: boolean }> = ({ isRandom }) => (
  <Box>
    <SkeletonLoader isRandom={isRandom} variant="text-small" width="30px" />
    <SkeletonLoader isRandom={isRandom} variant="slider" />
  </Box>
);
const UserSpecsSkeleton: FC<{ sex: PersonSex }> = ({ sex }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        gap={spacings.xlarge}
        marginBottom={spacings.x2large}
      >
        <Box flex="0 1 auto">
          <SkeletonLoader variant="avatar-big" />
        </Box>
        <Box flex={1.5}>
          <SkeletonLoader variant="text-small" width="100px" height="20px" />

          <Flex marginTop={spacings.large} gap={spacings.small}>
            <Typography flex={1}>{t('AGE')}</Typography>
            <Box flex={2}>
              <SkeletonLoader variant="text-small" width="30%" />
            </Box>
          </Flex>
          <Flex gap={spacings.small}>
            <Typography flex={1}>{t('STATUS')}</Typography>
            <Box flex={2}>
              <SkeletonLoader variant="text-small" width="50%" />
            </Box>
          </Flex>
          <Flex gap={spacings.small}>
            <Typography flex={1}>{t('SEX')}</Typography>
            <Box flex={2}>
              <SkeletonLoader variant="text-small" width="50%" />
            </Box>
          </Flex>
          <Flex gap={spacings.small} justifyContent="space-between">
            <Typography flex={1}>{t('PATIENT_ID')}</Typography>
            <Box flex={2}>
              <SkeletonLoader variant="text-small" width="10%" />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <StyledBoxWithBottomBorder>
        <Flex gap={spacings.small} alignItems="center">
          <Typography variant="body1">{t('THE_BASICS')}</Typography>
          <MiniIconButton icon={<EditIcon style={{ fill: 'black' }} />} />
        </Flex>
      </StyledBoxWithBottomBorder>
      <StyledFlexboxWithBasicNumbers
        paddingTop={spacings.large}
        alignItems="flex-start"
      >
        <NumberPreview
          height="100%"
          value={null}
          topText={t('BMI')}
          paddingX={spacings.x2large}
        />
        {sex === PersonSex.FEMALE && (
          <>
            <NumberPreview
              value={null}
              topText={t('TTC')}
              bottomText={t('YEARS')}
            />
            <NumberPreview value={null} topText={t('CYCLE').toUpperCase()} />
          </>
        )}
      </StyledFlexboxWithBasicNumbers>
    </Box>
  );
};

export const PatientOverviewCardSkeleton: FC<
  PatientOverviewCardSkeletonProps
> = ({ isRandom }) => {
  const { t } = useTranslation();
  return (
    <Card shadow padding={spacings.xlarge}>
      <Flex flexDirection="column" gap={spacings.xlarge}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom={spacings.large}
        >
          <Typography variant="h1">{t('PATIENT_OVERVIEW')}</Typography>
          <SkeletonLoader variant="text-big" width="30%" height="40px" />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          gap={spacings.x2large}
        >
          <Box flex={1}>
            <UserSpecsSkeleton sex={PersonSex.FEMALE} />
          </Box>

          <Box flex={1}>
            <UserSpecsSkeleton sex={PersonSex.MALE} />
          </Box>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          gap={spacings.x2large}
          marginBottom={spacings.medium}
        >
          <Box flex={1}>
            {/* Mock hormonal panel */}
            <StyledBoxWithBottomBorder marginBottom={spacings.xlarge}>
              <Typography variant="body1">{t('HORMONAL_PANEL')}</Typography>
            </StyledBoxWithBottomBorder>
            <Flex gap={spacings.x2large}>
              <StyledBoxWithMarginRight>
                <HormonalSliderSkeleton isRandom={isRandom} />
                <HormonalSliderSkeleton isRandom={isRandom} />
                <HormonalSliderSkeleton isRandom={isRandom} />
              </StyledBoxWithMarginRight>
              <StyledBoxWithMarginLeft>
                <HormonalSliderSkeleton isRandom={isRandom} />
                <HormonalSliderSkeleton isRandom={isRandom} />
              </StyledBoxWithMarginLeft>
            </Flex>
          </Box>
          {/* Mock sperm analysis */}
          <Box flex={1}>
            <StyledBoxWithBottomBorder>
              <Typography variant="body1">
                {t('SPERM_ANALYSIS').toUpperCase()}
              </Typography>
            </StyledBoxWithBottomBorder>
            <Flex>
              <NumberPreview
                value={null}
                topText={t('VOLUME')}
                bottomText={t('ML')}
              />
              <NumberPreview
                value={null}
                topText={t('CONCENTRATION')}
                bottomText={t('M/ML')}
              />
              <NumberPreview
                value={null}
                topText={t('MOTILITY')}
                bottomText={t('PRECENT')}
              />
              <NumberPreview
                value={null}
                topText={t('MORPH')}
                bottomText={t('PERCENT')}
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};
