import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';

import Flex from '../../components/layout/Flex/Flex';
import Typography from '../../components/display/Typography/Typography';
import Button from '../../components/display/Button/Button';
import { Colors } from '../../components/styles/colors';
import MiniCalendar from '../../components/display/MiniCalendar';
import BigCalendar from '../../components/display/Calendar';
import { radii, spacings } from '../../components/styles/constants';
import AddEditAppointment from './AddEditAppointment';
import { useDialog } from 'src/components/components-api/GlobalProvider/GlobalProvider';
import Select from 'src/components/data-entry/Select';
import { Box, styled } from '@mui/material';
import { DoctorChips } from '../patients/common/DoctorChips';
import useAppointments from 'src/hooks/useAppointments';
import {
  AllPurposesOption,
  ALL_ROOMS_OPTION,
  AppointmentPurposeFilterOptions,
  AppointmentPurposes,
  DefaultClinicRoomOptions
} from 'src/types/appointment';
import { fonts } from 'src/components/styles/fonts';
import dayjs, { Dayjs } from 'dayjs';

const Calendar: FC = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Dayjs>(dayjs());
  const { getClinicRooms } = useAppointments();
  const { openDialog } = useDialog();
  const [filteringStaffs, setFilteringStaffs] = useState<Array<string>>([]);
  const [filteringAppointmentPurpose, setFilteringAppointmentPurpose] =
    useState<AppointmentPurposeFilterOptions>(AllPurposesOption.ALL_TYPES);
  const [filteringRoom, setFilteringRoom] = useState<string>(ALL_ROOMS_OPTION);

  const { data: clinicRooms, isLoading: isLoadingClinicRooms } =
    getClinicRooms();

  const defaultRoomOptions = useMemo(
    () =>
      Object.values(DefaultClinicRoomOptions).map((value) => ({
        value,
        label: t(value)
      })),
    [t]
  );

  const filterByRoomOptions = useMemo(() => {
    if (isLoadingClinicRooms) return [];
    const options = [];
    options.push({ value: ALL_ROOMS_OPTION, label: t('ALL_ROOMS') });

    if (clinicRooms?.length <= 0) {
      options.push(...defaultRoomOptions);
    } else {
      options.push(
        ...clinicRooms?.map((room) => {
          return { value: room.id, label: room.name };
        })
      );
    }

    return options;
  }, [clinicRooms]);

  const filterByAppointmentOptions = useMemo(() => {
    return [
      {
        value: AllPurposesOption.ALL_TYPES,
        label: t(AllPurposesOption.ALL_TYPES)
      },
      ...Object.entries(AppointmentPurposes).map(([_, label]) => ({
        value: label,
        label: t(label)
      }))
    ];
  }, []);

  const handleDateChange = (date: Dayjs) => {
    setDate(date);
  };

  const StyledFilterTitle = styled(Typography)`
    font: ${fonts.calendarSidebarTitle};
  `;

  return (
    <>
      <Helmet>
        <title>{t('CALENDAR_PAGE_HEADER_TITLE')}</title>
      </Helmet>
      <Flex flexDirection="column" gap={spacings.xlarge}>
        <Flex
          borderRadius={radii.xlarge}
          bgcolor={Colors.dawnPink}
          gap={spacings.large}
          height="calc(100vh - 100px)"
          overflow="hidden"
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            gap={spacings.x2large}
            padding={`${spacings.x2large} ${spacings.none} ${spacings.xlarge} ${spacings.xlarge}`}
          >
            <Flex
              alignItems="center"
              gap={spacings.xlarge}
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h1" color={Colors.emperor}>
                {t('CALENDAR_PAGE_TITLE')}
              </Typography>

              <Button
                id="add-new-appointment-button"
                onClick={() =>
                  openDialog({
                    header: t('ADD_APPOINTMENT'),
                    children: <AddEditAppointment />,
                    fullWidth: true,
                    maxWidth: 'lg',
                    closeButtonId: 'close-add-appointment-dialog'
                  })
                }
                startIcon={<AddIcon />}
              >
                {t('CALENDAR_APPOINTMENT')}
              </Button>
            </Flex>

            <MiniCalendar onChange={(date) => handleDateChange(date)} />
            <Flex
              gap={spacings.xlarge}
              flexDirection="column"
              width="100%"
              alignItems="start"
            >
              <StyledFilterTitle>
                {t('FITLER_BY_APPOINTMENT').toUpperCase()}
              </StyledFilterTitle>
              <Select
                value={filteringAppointmentPurpose}
                onChange={(evt) => {
                  setFilteringAppointmentPurpose(
                    evt.target.value as AppointmentPurposes
                  );
                }}
                options={filterByAppointmentOptions}
              />
            </Flex>
            <Flex
              gap={spacings.xlarge}
              flexDirection="column"
              width="100%"
              alignItems="start"
            >
              <StyledFilterTitle>
                {t('FILTER_BY_ROOM').toUpperCase()}
              </StyledFilterTitle>
              <Select
                value={filteringRoom}
                onChange={(evt) => {
                  setFilteringRoom(evt.target.value);
                }}
                options={filterByRoomOptions}
              />
            </Flex>
            <Flex
              gap={spacings.xlarge}
              flexDirection="column"
              width="100%"
              alignItems="start"
            >
              <StyledFilterTitle>
                {t('FILTER_BY_STAFF').toUpperCase()}
              </StyledFilterTitle>
              <Box>
                <DoctorChips
                  id="calendar-doctor-chips"
                  showSelectedValue
                  value={filteringStaffs}
                  onAddChip={(newSelectedDoctorId) => {
                    if (filteringStaffs.includes(newSelectedDoctorId)) {
                      setFilteringStaffs(
                        filteringStaffs.filter(
                          (staff) => staff !== newSelectedDoctorId
                        )
                      );
                    } else
                      setFilteringStaffs([
                        ...filteringStaffs,
                        newSelectedDoctorId
                      ]);
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex
            justifyContent="start"
            flex={1}
            flexDirection="column"
            gap={spacings.xlarge}
          >
            <BigCalendar
              date={date}
              onNavigate={(date) => handleDateChange(date)}
              filterByStaff={filteringStaffs}
              filterByAppointment={filteringAppointmentPurpose}
              filterByRoom={filteringRoom}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Calendar;
