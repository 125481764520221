import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/data-entry/InputField';
import Select from 'src/components/data-entry/Select';
import TimePicker from 'src/components/data-entry/TimePicker';
import Button from 'src/components/display/Button';

import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import useEncounters from 'src/hooks/useEncounters';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { Degrees } from 'src/types/global';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import { getFullName } from 'src/utils/general';
import EncounterDetailsSection from '../EncounterDetailsSection';
import VitalsDisplayTable from '../VitalsDisplayTable';

export interface VitalsFormProps {
  timePerformed: Date;
  heartRate?: number;
  bloodPressure?: number;
  pulseOximeter?: number;
  respiratoryRate?: number;
  temperature?: number;
  doneBy?: string;
  degrees: Degrees;
}

const VitalsTab: FC<{
  appointmentId: string;
  patientId: string;
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
}> = ({ appointmentId, patientId, onDirtyFormChange }) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { getEncounterByAppointmentId } = useEncounters();
  const { data: encounter, isLoading: isLoadingEncounter } =
    getEncounterByAppointmentId(appointmentId);
  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);
  // TODO - create request to grab vitals from the DB

  // TODO - edit this whenever real data becomes available
  const defaultValues: VitalsFormProps = {
    timePerformed: new Date(),
    heartRate: null,
    bloodPressure: null,
    pulseOximeter: null,
    respiratoryRate: null,
    temperature: null,
    doneBy: null,
    degrees: Degrees.CELSIUS
  };

  const { control, formState, handleSubmit } = useForm<VitalsFormProps>({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = (data: VitalsFormProps) => {
    // TODO - create request to save vitals to the DB
    console.log(data);
  };

  const { errors } = formState;

  useEffect(() => {
    if (formState.dirtyFields) {
      onDirtyFormChange(Object.keys(formState.dirtyFields).length > 0);
    }
  }, [formState]);

  if (isLoadingEncounter || isLoadingPatient) {
    return <Loader />;
  }

  return (
    <form>
      <Flex
        flexDirection="column"
        gap={spacings.x2large}
        paddingBottom={spacings.large}
      >
        <Flex gap={spacings.xlarge}>
          <Controller
            name="doneBy"
            control={control}
            render={({ field: { onChange, value } }) => (
              <EncounterDetailsSection
                patientFullName={getFullName(patient?.personalInfo)}
                encounterDate={encounter?.encounterDate}
                encounterId={encounter?.displayId}
                performedBy={value}
                onPerformedByChange={onChange}
              />
            )}
          />
        </Flex>
        <Flex width="100%" gap={spacings.x3large}>
          <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
            <Typography fontWeight={fontWeights.extraBold} variant="h2">
              {t('VITALS')}
            </Typography>
            <Flex gap={spacings.medium}>
              <Box flex={1}>
                <Controller
                  name="timePerformed"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TimePicker
                      {...field}
                      error={!!errors.timePerformed}
                      helperText={errors.timePerformed?.message}
                      format={getTimeFormat({ isShort: true })}
                      inputRef={ref}
                      label={t('TIME_PERFORMED').toUpperCase()}
                    />
                  )}
                />
              </Box>
              <Box flex={1} />
              <Box flex={1} />
            </Flex>
            <Flex gap={spacings.medium}>
              <Box flex={1}>
                <Controller
                  name="heartRate"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      label={t('HEART_RATE').toUpperCase()}
                      type="number"
                      placeholder="#"
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="bloodPressure"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      label={t('BLOOD_PRESSURE').toUpperCase()}
                      type="number"
                      placeholder="#"
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="pulseOximeter"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      label={t('PULSE_OXIMETER').toUpperCase()}
                      type="number"
                      placeholder="#"
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex gap={spacings.medium}>
              <Box flex={1}>
                <Controller
                  name="respiratoryRate"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      label={t('RESPIRATORY_RATE').toUpperCase()}
                      type="number"
                      placeholder="#"
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="temperature"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      label={t('TEMPERATURE').toUpperCase()}
                      type="number"
                      placeholder="#"
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="degrees"
                  control={control}
                  render={({ field: { ref: _ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('DEGREES').toUpperCase()}
                      options={Object.entries(Degrees).map(([_, value]) => ({
                        value: value,
                        label: t(value)
                      }))}
                    />
                  )}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
            <Typography fontWeight={fontWeights.extraBold} variant="h2">
              {t('VITALS_SAVED')}
            </Typography>
            <VitalsDisplayTable {...defaultValues} loading={false} />
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {t('SUBMIT')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default VitalsTab;
